import React from "react";
import { useTranslation } from "react-i18next";
import Links from "./Links";

const SecondaryContent = ({ film }) => {
  let { t } = useTranslation();

  //Links
  const renderLinks = () => {
    if (film.filesLinks) {
      return film.filesLinks.map((link) => {
        return <Links link={link} key={link.id} />;
      });
    }
  };

  return (
    <div className="row pb-5">
      <div className="col-md-7 col-sm-12 pb-5">
        <div>
          <h5 className="card-title h4">{t("filmPage.synopsis")}</h5>
        </div>
        <div>{film.synopsis}</div>
      </div>
      <div className="col-md-5 col-sm-12 pb-5">
        <div>
          <h5 className="card-title h4">{t("filmPage.details")}</h5>
        </div>
        <div>
          <b className="text-underline--dashed">{t("filmPage.releaseDate")}:</b>{" "}
          {film.releaseDateFormated}
        </div>
        <div>
          <b className="text-underline--dashed">
            {t("filmPage.productionCompany")}:
          </b>{" "}
          {film.productionCompany}
        </div>
        <div>
          <b className="text-underline--dashed">{t("filmPage.distributor")}:</b>{" "}
          {film.distributor}
        </div>
        <div>
          <b className="text-underline--dashed">{t("filmPage.rating")}:</b>{" "}
          {film.rating}
        </div>

        <hr />
        {renderLinks()}
      </div>
    </div>
  );
};

export default SecondaryContent;
