import React from "react";

const IndividualReleaseSkeleton = () => {
  return (
    <>
      <section className="slice slice-lg pt-17 pb-0 bg-secondary">
        <div className="container placeholder-glow">
          <div className="row justify-content-center">
            <div className="col-xl-9 col-lg-10">
              <div className="card mb-n7 position-relative zindex-100">
                <div className="card-body p-md-5">
                  <div className=" text-center">
                    <span>
                      <span className="placeholder col-3"></span>
                    </span>

                    <h1 className="h2 lh-150 mt-3 mb-0">
                      <span className="placeholder col-12 placeholder-lg"></span>
                      <span className="placeholder col-10 placeholder-lg"></span>
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="slice slice-lg pt-10 pb-5">
        <div className="container pb-6 placeholder-glow">
          <div className="row row-grid align-items-center">
            <div className="col-xl-8 col-lg-10 offset-xl-2 offset-lg-1">
              <article>
                <p>
                  <span className="placeholder col-10 placeholder-lg"></span>
                  <span className="placeholder col-8 placeholder-lg"></span>
                </p>
                <br />
                <p>
                  <span className="placeholder col-12"></span>
                  <span className="placeholder col-11"></span>
                  <span className="placeholder col-12"></span>
                  <span className="placeholder col-12"></span>
                  <span className="placeholder col-11"></span>
                  <span className="placeholder col-8"></span>
                </p>
                <p>
                  <span className="placeholder col-11"></span>
                  <span className="placeholder col-12"></span>
                  <span className="placeholder col-12"></span>
                  <span className="placeholder col-11"></span>
                  <span className="placeholder col-10"></span>
                  <span className="placeholder col-4"></span>
                </p>
              </article>
            </div>
          </div>
        </div>
      </section>

      <section className="slice slice-lg bg-section-secondary">
        <div className="container">
          <div className="row align-items-center mb-5">
            <div className="col-12 col-md">
              <h3 className="h4 mb-0">
                <span className="placeholder col-5 placeholder-lg"></span>
              </h3>
              <p className="mb-0 text-muted">
                <span className="placeholder col-4"></span>
              </p>
              <p className="mb-0 text-muted">
                <span className="placeholder col-3"></span>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default IndividualReleaseSkeleton;
