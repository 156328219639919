const validate = (formValues) => {
  const errors = {};

  if (!formValues.type) {
    //Image type is empty
    errors.type = "The image type can't be empty!";
  }

  if (!formValues.images) {
    //Image is empty
    errors.images = "You need to upload a file!";
  }

  if (!formValues.nameEn) {
    //Name English is empty
    errors.nameEn = "The english name can't be empty!";
  }

  if (!formValues.nameEs) {
    //Name Spanish is empty
    errors.nameEs = "The spanish name can't be empty!";
  }

  if (!formValues.copyrightEn) {
    //Copyright English is empty
    errors.copyrightEn = "The english copyright can't be empty!";
  }

  if (!formValues.copyrightEs) {
    //Copyright Spanish is empty
    errors.copyrightEs = "The spanish copyright can't be empty!";
  }

  return errors;
};

export default validate;
