import React, { useState, createRef } from "react";
import RenderError from "./RenderError";
import { Upload } from "react-feather";

const RenderUpload = ({
  input,
  label,
  placeholder,
  customClass,
  accept,
  meta,
  notes,
  multiple,
}) => {
  const [fileValue] = useState();
  const [fileName, setFileName] = useState();

  const fileInput = createRef();
  const fileInputMultiple = createRef();

  const onChange = (e) => {
    // setSelectedFile(e.target.files[0]);
    const newFileName = e.target.value.replace(/C:\\fakepath\\/g, "");
    setFileName(newFileName);
  };

  // const onChangeMultiple = (e) => {
  //   console.log("File changed");
  //   // console.log(fileInput);
  //   // console.log(fileValue);
  //   console.log(e);
  //   // // const newFileName = e.target.value.replace("C:/fakepath/", "");
  //   // setFileName(e.target.value);
  // };

  const inputRender = () => {
    if (multiple) {
      return (
        <>
          <input
            {...input}
            type="file"
            id={input.name}
            className="custom-input-file"
            accept={accept}
            ref={fileInputMultiple}
            value={fileValue}
            // onChange={(el) => onChangeMultiple(el)}
            multiple
          />
        </>
      );
    } else
      return (
        <>
          <input
            {...input}
            type="file"
            id={input.name}
            className="custom-input-file"
            accept={accept}
            ref={fileInput}
            value={fileValue}
            onChange={(el) => onChange(el)}
          />{" "}
        </>
      );
  };

  return (
    <>
      {RenderError(meta)}

      <div className={`form-group${customClass}`}>
        <label className="form-control-label">{label}</label>
        {inputRender()}
        <label htmlFor={input.name}>
          <Upload size="18px" />
          <span> {fileName ? fileName : placeholder}</span>
        </label>
        <small className="form-text text-muted mt-2">{notes}</small>
      </div>
    </>
  );
};

export default RenderUpload;
