import React from "react";

const ShowReleasesSkeleton = () => {
  return (
    <>
      <tr key="row_placeholder_01 placeholder-glow">
        <th scope="row">
          <div className="media align-items-center">
            <div></div>
            <div className="media-body">
              <span className="name h6 mb-0 text-sm">
                <span className="placeholder col-12 placeholder-lg"></span>
              </span>
              <small className="d-block">
                <span className="placeholder col-6"></span>
              </small>
            </div>
          </div>
        </th>
        <td>
          <div>
            <span className="btn btn-sm btn-secondary placeholder w-100"></span>
          </div>
        </td>
        <td className="text-right">
          <div className="actions ml-3">
            <span className="action-item mr-2">
              <span className="placeholder col-6 card-img-top card-img-bottom"></span>
            </span>
            <span className="action-item text-warning mr-2">
              <span className="placeholder col-6 card-img-top card-img-bottom"></span>
            </span>
            <span className="action-item text-danger mr-2">
              <span className="placeholder col-6 card-img-top card-img-bottom"></span>
            </span>
          </div>
        </td>
      </tr>
      <tr key="row_placeholder_02 placeholder-glow">
        <th scope="row">
          <div className="media align-items-center">
            <div></div>
            <div className="media-body ml-4">
              <span className="name h6 mb-0 text-sm">
                <span className="placeholder col-10 placeholder-lg"></span>
              </span>
              <small className="d-block">
                <span className="placeholder col-5"></span>
              </small>
            </div>
          </div>
        </th>
        <td>
          <div>
            <span className="btn btn-sm btn-secondary placeholder w-100"></span>
          </div>
        </td>
        <td className="text-right">
          <div className="actions ml-3">
            <span className="action-item mr-2">
              <span className="placeholder col-6 card-img-top card-img-bottom"></span>
            </span>
            <span className="action-item text-warning mr-2">
              <span className="placeholder col-6 card-img-top card-img-bottom"></span>
            </span>
            <span className="action-item text-danger mr-2">
              <span className="placeholder col-6 card-img-top card-img-bottom"></span>
            </span>
          </div>
        </td>
      </tr>
      <tr key="row_placeholder_03 placeholder-glow">
        <th scope="row">
          <div className="media align-items-center">
            <div></div>
            <div className="media-body ml-4">
              <span className="name h6 mb-0 text-sm">
                <span className="placeholder col-11 placeholder-lg"></span>
              </span>
              <small className="d-block">
                <span className="placeholder col-8"></span>
              </small>
            </div>
          </div>
        </th>
        <td>
          <div>
            <span className="btn btn-sm btn-secondary placeholder w-100"></span>
          </div>
        </td>
        <td className="text-right">
          <div className="actions ml-3">
            <span className="action-item mr-2">
              <span className="placeholder col-6 card-img-top card-img-bottom"></span>
            </span>
            <span className="action-item text-warning mr-2">
              <span className="placeholder col-6 card-img-top card-img-bottom"></span>
            </span>
            <span className="action-item text-danger mr-2">
              <span className="placeholder col-6 card-img-top card-img-bottom"></span>
            </span>
          </div>
        </td>
      </tr>
      <tr key="row_placeholder_04 placeholder-glow">
        <th scope="row">
          <div className="media align-items-center">
            <div></div>
            <div className="media-body ml-4">
              <span className="name h6 mb-0 text-sm">
                <span className="placeholder col-12 placeholder-lg"></span>
              </span>
              <small className="d-block">
                <span className="placeholder col-8"></span>
              </small>
            </div>
          </div>
        </th>
        <td>
          <div>
            <span className="btn btn-sm btn-dark placeholder w-100"></span>
          </div>
        </td>
        <td className="text-right">
          <div className="actions ml-3">
            <span className="action-item mr-2">
              <span className="placeholder col-6 card-img-top card-img-bottom"></span>
            </span>
            <span className="action-item text-warning mr-2">
              <span className="placeholder col-6 card-img-top card-img-bottom"></span>
            </span>
            <span className="action-item text-danger mr-2">
              <span className="placeholder col-6 card-img-top card-img-bottom"></span>
            </span>
          </div>
        </td>
      </tr>
    </>
  );
};

export default ShowReleasesSkeleton;
