import React, { useState } from "react";
import ImageComponentSkeleton from "./ImageComponentSkeleton";

const ImageComponent = (props) => {
  const imageProps = { ...props };
  delete imageProps["placeholderHeight"];
  delete imageProps["isPoster"];
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const image = new Image();
  image.src = props.src;

  image.onload = () => setIsImageLoaded(true);

  // useEffect(() => {
  //   const image = new Image();
  //   image.src = props.src;

  //   image.onload = () => setIsImageLoaded(true);

  //   // return () => {
  //   //   image.onload = null;
  //   // };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  if (!isImageLoaded) {
    return <ImageComponentSkeleton key={props.id} props={props} />;
  }

  return (
    <>
      <img {...imageProps} alt={imageProps.alt} />
    </>
  );
};

export default ImageComponent;
