import React from "react";
import { Link } from "react-router-dom";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import ImageComponent from "./individualFilm/ImageComponent";

const IndividualFilmSnippet = ({ film }) => {
  let { t } = useTranslation();

  if (i18next.language === "en") {
    film.name = film.nameEn;
  } else if (i18next.language === "es") {
    film.name = film.nameEs;
  }
  // console.log(film);
  return (
    <>
      <div className="col-md-4 col-sm-6 col-xs-12" key={film.id}>
        <div className="card hover-translate-y-n3 hover-shadow-lg overflow-hidden">
          <div className="position-relative overflow-hidden">
            <OverlayTrigger
              key={`download-ot-${film.id}`}
              placement="top"
              overlay={
                <Tooltip id={`download-tooltip-${film.id}`}>
                  {t("filmPage.download")}
                </Tooltip>
              }
            >
              <Link to={`/films/${film.slug}`} className="d-block">
                <ImageComponent
                  alt={`${film.name} (${film.year})`}
                  src={film.oneSheet}
                  className="card-img-top"
                  isPoster={true}
                  placeholderHeight={480}
                />
              </Link>
            </OverlayTrigger>
          </div>
          <div className="card-body py-4 text-center">
            <Link
              to={`/films/${film.slug}`}
              className="h5 stretched-link lh-150"
            >
              {film.name}
            </Link>
            <small className="d-block text-sm mb-2">({film.year})</small>
          </div>
        </div>
      </div>
    </>
  );
};

export default IndividualFilmSnippet;
