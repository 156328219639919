import React from "react";

import AdminPageTitle from "../AdminPageTitle";
import useDocumentTitle from "../../public/useDocumentTitle";

const AdminUserCreate = () => {
  useDocumentTitle("Add User - Black Letter Press");
  return (
    <>
      <AdminPageTitle>Add New User</AdminPageTitle>
      <section className="slice slice-sm bg-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div>
                {/* <!-- Content --> */}
                <div className="row">
                  <div className="col-lg-12">
                    <div>
                      {/* <!-- General information --> */}
                      <form method="POST" action="?engine=users&action=add">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label">
                                First Name*
                              </label>
                              <input
                                className="form-control"
                                name="userFirst"
                                type="text"
                                placeholder="John..."
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label">
                                Last Name*
                              </label>
                              <input
                                className="form-control"
                                name="userLast"
                                type="text"
                                placeholder="Doe..."
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label">
                                Phone*
                              </label>
                              <input
                                type="tel"
                                name="userPhone"
                                className="form-control"
                                placeholder="(123) 345-6789"
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                Password*
                              </label>
                              <input
                                className="form-control"
                                name="userPassword"
                                type="password"
                                placeholder="Password..."
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                Repeat Password*
                              </label>
                              <input
                                className="form-control"
                                name="userPasswordRepeat"
                                type="password"
                                placeholder="Repeat Password..."
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row align-items-center">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                Email*
                              </label>
                              <input
                                type="email"
                                name="userEmail"
                                className="form-control"
                                placeholder="johndoe@email.com"
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                Role*
                              </label>
                              <select
                                className="form-control"
                                name="userRole"
                                required
                              >
                                <option disabled selected>
                                  Select...
                                </option>
                                <option value="admin">Admin</option>
                                <option disabled>---</option>
                                <option value="user">Press Member</option>
                                <option value="inactive">Inactive</option>
                                <option value="pending">
                                  Pending for Activation
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row align-items-center">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                Job Title
                              </label>
                              <input
                                type="text"
                                name="userJobTitle"
                                className="form-control"
                                placeholder="Journalist, Editor..."
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                Outlet
                              </label>
                              <input
                                type="text"
                                name="userOutlet"
                                className="form-control"
                                placeholder="Acme News..."
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="form-control-label">
                                Comments
                              </label>
                              <textarea
                                className="form-control"
                                name="userComments"
                                placeholder="Write some optional comments here..."
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        {/* <!-- Buttons --> */}
                        <div className="mt-3">
                          <button
                            type="submit"
                            name="userAddButton"
                            className="btn btn-sm btn-primary"
                          >
                            Add
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AdminUserCreate;
