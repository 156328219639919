import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, reset } from "redux-form";
import Swal from "sweetalert2";

import VideosTableElement from "./VideosTableElement";
import TableComponent from "../_utils/TableComponent";
import RenderInput from "../../../_utils/RenderInput";
import RenderDropdown from "../../../_utils/RenderDropdown";
import { updateVideo, createVideo, deleteVideo } from "../../../../../actions";
import validate from "./validate";

const VideosTable = ({
  trailers,
  clips,
  bts,
  updateVideo,
  filmId,
  submitting,
  handleSubmit,
  createVideo,
  deleteVideo,
}) => {
  const addVideo = async (data, dispatch) => {
    data.filmId = filmId;
    await createVideo(data);
    dispatch(reset("createLinkForm"));
  };

  const editVideoScript = async (data) => {
    data.filmId = filmId;
    await updateVideo(data);
  };

  const deleteVideoScript = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `Deleting this laurel is an irreversible action!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        triggerDelete(id);
      }
    });
  };

  const triggerDelete = async (id) => {
    const data = {
      id,
      filmId,
    };
    try {
      await deleteVideo(data);
    } catch (error) {
      console.log(error);
    }
  };

  const loadVideos = (array, type) => {
    if (array && array.length > 0) {
      return array.map((el) => {
        return (
          <VideosTableElement
            el={el}
            deleteScript={deleteVideoScript}
            editScript={editVideoScript}
            type={type}
            key={`videoElement_${el.id}`}
          />
        );
      });
    } else {
      return (
        <>
          <tr>
            <td colSpan={4}>
              There are no videos associated to this category!
            </td>
          </tr>
        </>
      );
    }
  };

  return (
    <>
      <section className="slice slice-sm">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <h5 className="mb-3">Videos</h5>
              <form onSubmit={handleSubmit(addVideo)}>
                <div className="row">
                  <div className="col-sm-6">
                    <Field
                      name="type"
                      label="Category"
                      defaultOption="Select..."
                      component={RenderDropdown}
                    >
                      <option value="mediaTrailers" key="mediaTrailers">
                        Trailer
                      </option>

                      <option value="mediaClips" key="mediaClips">
                        Clip
                      </option>
                      <option value="mediaBTSVideo" key="mediaBTSVideo">
                        Behind the Scenes Video
                      </option>
                    </Field>
                  </div>

                  <div className="col-sm-6">
                    <Field
                      name="url"
                      component={RenderInput}
                      label="Link"
                      placeholder="Enter the YouTube or Vimeo URL here..."
                      customClass=""
                      fieldType="url"
                    />
                  </div>
                  <div className="col-sm-6">
                    <Field
                      name="nameEn"
                      component={RenderInput}
                      label="Name (English)"
                      placeholder="Name of the video..."
                      customClass=""
                      fieldType="text"
                    />
                  </div>
                  <div className="col-sm-6">
                    <Field
                      name="nameEs"
                      component={RenderInput}
                      label="Name (Spanish)"
                      placeholder="Nombre del video..."
                      customClass=""
                      fieldType="text"
                    />
                  </div>
                </div>
                <div className="mt-3 placeholder-glow">
                  <button
                    type="submit"
                    className="btn btn-sm btn-primary"
                    disabled={submitting}
                  >
                    {!submitting ? "Add" : "Adding"}
                  </button>
                </div>
              </form>
              <br />

              <div className="col-12">
                <TableComponent>
                  <thead>
                    <tr>
                      <th>Name (English)</th>
                      <th>Name (Spanish)</th>
                      <th>URL</th>
                      <th className="text-right">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="bg-secondary">
                      <td colSpan={4}>
                        <strong>Trailers</strong>
                      </td>
                    </tr>
                    {loadVideos(trailers, "mediaTrailers")}
                    <tr className="bg-secondary">
                      <td colSpan={4}>
                        <strong>Clips</strong>
                      </td>
                    </tr>
                    {loadVideos(clips, "mediaClips")}
                    <tr className="bg-secondary">
                      <td colSpan={4}>
                        <strong>Behind the Scenes</strong>
                      </td>
                    </tr>
                    {loadVideos(bts, "mediaBTSVideo")}
                  </tbody>
                </TableComponent>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const wrappedComponent = reduxForm({
  form: "createLinkForm",
  validate,
})(VideosTable);

export default connect(null, { updateVideo, createVideo, deleteVideo })(
  wrappedComponent
);
