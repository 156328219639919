import React from "react";
import RenderError from "./RenderError";

const RenderDropdown = ({ input, meta, label, defaultOption, children }) => {
  return (
    <>
      {RenderError(meta)}
      <div className="form-group">
        <label className="form-control-label">{label}</label>
        <div className="input-group">
          <select
            {...input}
            className="custom-select"
            id={input.name}
            // defaultValue="0"
          >
            <option key="0" value="" disabled>
              {defaultOption}
            </option>
            {children}
          </select>
        </div>
      </div>
    </>
  );
};

export default RenderDropdown;
