import validator from "validator";

const validateVerify = (formValues) => {
  const errors = {};

  if (!formValues.email) {
    //Email is empty
    errors.email = "forgotPassword.errors.emailEmpty";
  }
  if (!validator.isEmail(String(formValues.email))) {
    //Email is invalid
    errors.email = "forgotPassword.errors.emailInvalid";
  }

  if (!formValues.captchaResponse) {
    //No reCaptcha value
    errors.captchaResponse = "forgotPassword.errors.reCaptchaError";
  }

  return errors;
};

export default validateVerify;
