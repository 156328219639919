import React from "react";
import { Link } from "react-router-dom";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";

const lngs = {
  en: { nativeName: "English", flag: "US" },
  es: { nativeName: "Español", flag: "ES" },
};

const Footer = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <footer className="position-relative" id="footer-main">
        <div className="footer footer-dark bg-primary">
          <div className="container">
            <div className="row align-items-center justify-content-md-between pb-4">
              <div className="col-md-6">
                <div className="copyright text-sm text-center text-md-left">
                  &copy; 2022{" "}
                  <a
                    href="https://blackletterfilms.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Black Letter Films, S.R.L.
                  </a>{" "}
                  {t("footer.allRightsReserved")}
                </div>
              </div>
              <div className="col-md-3">
                <ul className="nav justify-content-center justify-content-md-end mt-3 mt-md-0">
                  <li key="termsNavItem" className="nav-item">
                    <Link className="nav-link" to="/terms">
                      {t("footer.terms")}
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-md-3">
                <ul className="nav justify-content-center justify-content-md-end mt-3 mt-md-0">
                  {Object.keys(lngs).map((lng) => (
                    <li key={lngs[lng].nativeName} className="nav-item">
                      <span
                        key={lng}
                        className="nav-link"
                        style={{
                          fontWeight: i18n.language === lng ? "bold" : "normal",
                        }}
                        onClick={() => {
                          i18n.changeLanguage(lng);
                        }}
                      >
                        <ReactCountryFlag countryCode={lngs[lng].flag} />
                        {` ${lngs[lng].nativeName}`}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
