import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Edit2, Trash2 } from "react-feather";
import Swal from "sweetalert2";

import { changeUserRole } from "../../../actions";

const ShowUsersContent = ({ filter, users, changeUserRole }) => {
  // console.log(users);
  let newArray;

  if (users) {
    if (filter === "all") {
      newArray = users;
    } else {
      newArray = users.filter((el) => {
        return el.role === filter;
      });
    }
  }

  const changeRole = (newRole, id, ev) => {
    ev.preventDefault();
    changeUserRole({ id, role: newRole });
  };

  const deleteUser = (id, fullName) => {
    Swal.fire({
      title: "Are you sure?",
      text: `Deleting ${fullName}'s user is an irreversible action!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("Trigger delete script");
        Swal.fire("Deleted!", "The user has been deleted.", "success");
      }
    });
  };

  const activateButton = (role, id) => {
    if (role === "admin" || role === "user") {
      return (
        <>
          <div className="dropdown dropdown">
            <button
              className="btn btn-sm btn-secondary dropdown-toggle bg-success"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Active
            </button>
            <div className="dropdown-menu bg-danger">
              <a
                className="dropdown-item text-white"
                href="#"
                onClick={(ev) => changeRole("inactive", id, ev)}
              >
                Deactivate
              </a>
            </div>
          </div>
        </>
      );
    } else if (role === "pending" || role === "inactive") {
      return (
        <>
          <div className="dropdown dropdown">
            <button
              className="btn btn-sm btn-secondary dropdown-toggle bg-warning"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Inactive
            </button>
            <div className="dropdown-menu bg-success">
              <a
                className="dropdown-item"
                href="#"
                onClick={(ev) => changeRole("user", id, ev)}
              >
                Activate
              </a>
            </div>
          </div>
        </>
      );
    }
  };

  const fetchUsersSnippets = () => {
    return newArray.map((el) => {
      return (
        <>
          <tr key={`userRow_${el.id}`}>
            <th scope="row">
              <div className="media align-items-center">
                <div></div>
                <div className="media-body">
                  <Link to={el.id} className="name h6 mb-0 text-sm">
                    {`${el.firstName}  ${el.lastName}`}
                  </Link>
                  <small className="d-block">{el.email}</small>
                </div>
              </div>
            </th>
            <td>{el.jobTitle}</td>
            <td>{el.outletName}</td>
            <td>{activateButton(el.role, el.id)}</td>
            <td className="text-right">
              {/* <!-- Actions --> */}
              <div className="actions ml-3">
                <Link
                  to={el.id}
                  className="action-item text-warning mr-2"
                  data-toggle="tooltip"
                  title="Edit"
                >
                  <Edit2 size={18} />
                </Link>
                <span
                  className="action-item text-danger mr-2"
                  data-toggle="tooltip"
                  title="Delete"
                  onClick={() =>
                    deleteUser(el.id, `${el.firstName} ${el.lastName}`)
                  }
                >
                  <Trash2 size={18} />
                </span>
              </div>
            </td>
          </tr>
        </>
      );
    });
  };

  if (newArray && newArray.length >= 1) {
    return fetchUsersSnippets();
  } else {
    console.log(newArray);
    return (
      <tr className="row justify-content-center">
        <td className="col-lg-9">There are no results.</td>
      </tr>
    );
  }
};

const mapStateToProps = (state) => {
  return { users: Object.values(state.bUsers) };
};

export default connect(mapStateToProps, { changeUserRole })(ShowUsersContent);
