import React from "react";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { X, Home, LogOut } from "react-feather";
import { logOut } from "../../actions";

const AdminHeader = (props) => {
  const logOutScript = () => {
    props.logOut();
  };

  return (
    <header id="header-main">
      {/* <!-- Navbar --> */}
      <nav
        className="navbar navbar-main navbar-expand-lg shadow navbar-light"
        id="navbar-main"
      >
        <div className="container">
          {/* <!-- Toggler --> */}
          <button
            className="navbar-toggler order-lg-2 ml-n3 ml-lg-0"
            type="button"
            data-toggle="collapse"
            data-target="#navbar-main-collapse"
            aria-controls="navbar-main-collapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          {/* <!-- Brand --> */}
          <Link className="navbar-brand order-lg-1" to="/admin/">
            <img
              alt="Black Letter Films | Press Desk"
              src="/assets/img/logo_blf_black.svg"
              id="navbar-logo"
            />
          </Link>
          {/* <!-- Collapse --> */}
          <div
            className="collapse navbar-collapse navbar-collapse-overlay order-lg-3"
            id="navbar-main-collapse"
          >
            {/* <!-- Toggler --> */}
            <div className="position-relative">
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbar-main-collapse"
                aria-controls="navbar-main-collapse"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <X />
              </button>
            </div>
            <ul className="navbar-nav ml-lg-auto mr-3">
              <li className="nav-item nav-item-spaced d-lg-block">
                <NavLink
                  className="nav-link"
                  // activeClassName="active"
                  to="/admin/"
                >
                  Dashboard
                </NavLink>
              </li>
              <li className="nav-item nav-item-spaced d-lg-block">
                <NavLink
                  className="nav-link"
                  // activeClassName="active"
                  to="/admin/films?filter=public"
                >
                  Films
                </NavLink>
              </li>
              <li className="nav-item nav-item-spaced d-lg-block">
                <NavLink className="nav-link" to="/admin/releases">
                  Releases
                </NavLink>
              </li>
              <li className="nav-item nav-item-spaced d-lg-block">
                <NavLink
                  className="nav-link"
                  // activeClassName="active"
                  to="/admin/users?filter=all"
                >
                  Users
                </NavLink>
              </li>
            </ul>
            {/* <!-- Right navigation --> */}
            <ul className="navbar-nav align-items-lg-center d-none d-lg-flex ml-lg-auto">
              <li className="nav-item nav px-2">Welcome, {props.userName}!</li>
              <li className="nav-item">
                <Link
                  className="nav-link nav-link-icon px-2 text-primary"
                  to="/"
                >
                  <Home />
                </Link>
              </li>
              <li className="nav-item" onClick={logOutScript}>
                <span className="nav-link nav-link-icon px-2 text-primary">
                  <LogOut />
                </span>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    userName: state.auth.userName,
  };
};

export default connect(mapStateToProps, { logOut })(AdminHeader);
