import React from "react";
import { connect } from "react-redux";
import IndividualFilmSnippet from "./IndividualFilmSnippet";

const ShowFilmsContent = ({ filter, films }) => {
  let newArray;

  if (films) {
    newArray = films.filter((el) => {
      return el.status === filter;
    });
  }

  const fetchFilmSnippets = () => {
    return newArray.map((el) => {
      return (
        <IndividualFilmSnippet data={el} key={`component_snippet_${el.id}`} />
      );
    });
  };

  if (newArray && newArray.length >= 1) {
    return (
      <section className="slice slice-sm">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div>
                <div className="row">{fetchFilmSnippets()}</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  } else {
    return (
      <section className="slice slice-sm">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">There are no results.</div>
          </div>
        </div>
      </section>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    films: Object.values(state.bFilms),
  };
};

export default connect(mapStateToProps)(ShowFilmsContent);
