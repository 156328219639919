import React from "react";

const RenderNotes = ({ input, label, placeholder }) => {
  return (
    <>
      <div className="form-group">
        <label className="form-control-label" htmlFor="userNotes">
          {label}
        </label>
        <div className="input-group input-group-merge">
          <textarea
            {...input}
            id={input.name}
            className="form-control"
            placeholder={placeholder}
            rows="3"
          />
        </div>
      </div>
    </>
  );
};

export default RenderNotes;
