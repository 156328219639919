const validate = (formValues) => {
  const errors = {};

  if (!formValues.festivalName) {
    //Festival name is empty
    errors.festivalName = "The festival name can't be empty!";
  }

  if (!formValues.laurel) {
    //Laurel image is empty
    errors.laurel = "You need to upload a festival laurel!";
  }

  return errors;
};

export default validate;
