import React from "react";
import { Bell } from "react-feather";

const DashboardSkeleton = () => {
  return (
    <>
      {/* <!-- Header --> */}
      <section className="pt-5 pb-5 bg-section-secondary">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="row align-items-center">
                <div className="col ml-3 ml-md-n2">
                  {/* <!-- Title --> */}
                  <h2 className="mb-0">
                    <b>Press</b> Desk
                  </h2>
                  {/* <!-- Subtitle --> */}
                  <span className="text-muted d-block">Black Letter Films</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="slice slice-sm bg-section-primary placeholder-glow">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              {/* <!-- Quick stats --> */}
              <div>
                {/* <!-- Title --> */}
                <div className="row align-items-center mb-3">
                  <div className="col">
                    <h6 className="mb-0">Quick stats</h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card card-fluid">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-6 col-sm-3 text-center">
                            <span className="h5 mb-0 placeholder col-6 placeholder-lg"></span>
                            <span className="d-block text-sm">
                              Public Films
                            </span>
                          </div>
                          <div className="col-6 col-sm-3 text-center">
                            <span className="h5 mb-0 placeholder col-6 placeholder-lg"></span>
                            <span className="d-block text-sm">
                              Private Films
                            </span>
                          </div>
                          <div className="col-6 col-sm-3 text-center">
                            <span className="h5 mb-0 placeholder col-6 placeholder-lg"></span>
                            <span className="d-block text-sm">
                              Press Releases
                            </span>
                          </div>
                          <div className="col-6 col-sm-3 text-center">
                            <span className="h5 mb-0 placeholder col-6 placeholder-lg"></span>
                            <span className="d-block text-sm">Press Users</span>
                          </div>
                        </div>
                      </div>
                      <div className="card-footer py-2">
                        <div className="row align-items-center">
                          <div className="col-6">
                            <span className="btn btn-xs btn-warning text-dark btn-icon rounded-pill">
                              <span className="btn-inner--icon">
                                <i className="fas fa-plus"></i>
                              </span>
                              <span className="btn-inner--text">
                                Add New Press Release
                              </span>
                            </span>
                          </div>
                          <div className="col-6 text-right">
                            <span className="btn btn-xs btn-primary btn-icon rounded-pill">
                              <span className="btn-inner--icon">
                                <i className="fas fa-plus"></i>
                              </span>
                              <span className="btn-inner--text">
                                Add New Film
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Messages and Alerts --> */}
              <div className="row mb-4">
                <div className="col-md-6">
                  <div className="card">
                    {/* <!-- Title --> */}
                    <div className="card-header">
                      <h6>Press Releases</h6>
                    </div>
                    {/* <!-- List group --> */}
                    <div className="list-group list-group-flush">
                      <span className="list-group-item list-group-item-action">
                        <div className="d-flex align-items-center">
                          <div className="flex-fill ml-3">
                            <span className="h6 text-sm placeholder col-10">
                              Title
                            </span>
                            <span className="text-sm lh-140 mb-0 placeholder col-12"></span>
                            <span className="text-sm mb-0 mt-0 placeholder col-11"></span>
                          </div>
                        </div>
                      </span>
                      <span className="list-group-item list-group-item-action">
                        <div className="d-flex align-items-center">
                          <div className="flex-fill ml-3">
                            <span className="h6 text-sm placeholder col-11">
                              Title
                            </span>
                            <span className="text-sm lh-140 mb-0 placeholder col-12"></span>
                            <span className="text-sm mb-0 mt-0 placeholder col-9"></span>
                          </div>
                        </div>
                      </span>
                      <span className="list-group-item list-group-item-action">
                        <div className="d-flex align-items-center">
                          <div className="flex-fill ml-3">
                            <div className="h6 text-sm placeholder col-8">
                              Title
                            </div>
                            <span className="text-sm lh-140 mb-0 placeholder col-12"></span>
                            <span className="text-sm mb-0 mt-0 placeholder col-12"></span>
                          </div>
                        </div>
                      </span>
                      <span className="list-group-item list-group-item-action">
                        <div className="d-flex align-items-center">
                          <div className="flex-fill ml-3">
                            <div className="h6 text-sm placeholder col-11">
                              Title
                            </div>
                            <span className="text-sm lh-140 mb-0 placeholder col-12"></span>
                            <span className="text-sm mb-0 mt-0 placeholder col-7"></span>
                          </div>
                        </div>
                      </span>
                    </div>
                    {/* <!-- Card footer --> */}
                    <div className="card-footer py-3 text-center border-0 position-relative">
                      <span className="text-xs ls-1 text-dark text-uppercase font-weight-bold stretched-link">
                        See all
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-header">
                      <h6>Users Pending for Activation</h6>
                    </div>
                    {/* <!-- List group --> */}
                    <div className="list-group list-group-flush">
                      <span className="list-group-item list-group-item-action border-0 py-2">
                        <div className="d-flex">
                          <div>
                            <Bell size={20} className="mr-3 text-danger" />
                          </div>

                          <span className="text-sm placeholder col-10"></span>
                        </div>
                      </span>
                      <span className="list-group-item list-group-item-action border-0 py-2">
                        <div className="d-flex">
                          <div>
                            <Bell size={20} className="mr-3 text-danger" />
                          </div>

                          <span className="text-sm  placeholder col-11"></span>
                        </div>
                      </span>
                      <span className="list-group-item list-group-item-action border-0 py-2">
                        <div className="d-flex">
                          <div>
                            <Bell size={20} className="mr-3 text-danger" />
                          </div>

                          <span className="text-sm  placeholder col-8"></span>
                        </div>
                      </span>
                      <span className="list-group-item list-group-item-action border-0 py-2">
                        <div className="d-flex">
                          <div>
                            <Bell size={20} className="mr-3 text-danger" />
                          </div>

                          <span className="text-sm  placeholder col-6"></span>
                        </div>
                      </span>
                      <span className="list-group-item list-group-item-action border-0 py-2">
                        <div className="d-flex">
                          <div>
                            <Bell size={20} className="mr-3 text-danger" />
                          </div>

                          <span className="text-sm  placeholder col-10"></span>
                        </div>
                      </span>
                      <span className="list-group-item list-group-item-action border-0 py-2">
                        <div className="d-flex">
                          <div>
                            <Bell size={20} className="mr-3 text-danger" />
                          </div>

                          <span className="text-sm  placeholder col-8"></span>
                        </div>
                      </span>
                    </div>
                    {/* <!-- Card footer --> */}
                    <div className="card-footer py-3 text-center mt-3 position-relative">
                      <span className="text-xs text-dark ls-1 text-uppercase font-weight-bold stretched-link">
                        See all
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DashboardSkeleton;
