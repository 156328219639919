import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";

import ShowUsersContent from "./ShowUsersContent";
import ShowUsersContentSkeleton from "./ShowUsersContentSkeleton";
import { fetchUsers } from "../../../actions";
import useDocumentTitle from "../../public/useDocumentTitle";

const SubMenuLink = (props) => {
  let [searchParams] = useSearchParams();
  let isActive = searchParams.get("filter") === props.to;

  return (
    <Link
      to={`?filter=${props.to}`}
      className={`nav-link${isActive ? " active" : ""}`}
      onClick={() => props.onClick()}
    >
      {props.children}
    </Link>
  );
};

const ShowUsers = ({ fetchUsers }) => {
  let [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [filterUsers, setFilterUsers] = useState("all");

  useEffect(() => {
    const fetchScript = async () => {
      setIsLoading(true);
      fetchUsers();
      setIsLoading(false);
    };

    fetchScript();

    setFilterUsers(searchParams.get("filter"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDocumentTitle("Users - Black Letter Press");

  console.log(`Fitler users: ${filterUsers}`);
  return (
    <>
      <section className="pt-5 bg-section-secondary">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="row align-items-center mb-4">
                <div className="col ml-n3 ml-md-n2">
                  {/* <!-- Title --> */}
                  <h2 className="mb-0">Users</h2>
                </div>
                <div className="col-auto">
                  <button
                    type="button"
                    className="btn btn-sm btn-primary btn-icon-only rounded-circle"
                    data-toggle="modal"
                    data-target="#searchModal"
                  >
                    <span className="btn-inner--icon">
                      <i className="fas fa-search"></i>
                    </span>
                  </button>
                  <Link
                    to="/admin/users/create"
                    className="btn btn-sm btn-primary btn-icon"
                  >
                    <span className="btn-inner--text">Add User</span>
                    <span className="btn-inner--icon">
                      <i className="fas fa-plus"></i>
                    </span>
                  </Link>
                </div>
                <div
                  className="modal fade"
                  id="searchModal"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-centered"
                    role="document"
                  >
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Search User
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search by Name, Email or Outlet..."
                          id="searchForm"
                        />
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-primary"
                          // onclick="searchScript()"
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <ul className="nav nav-tabs overflow-x">
                  <li className="nav-item">
                    <SubMenuLink to="all" onClick={() => setFilterUsers("all")}>
                      All
                    </SubMenuLink>
                  </li>
                  <li className="nav-item">
                    <SubMenuLink
                      to="user"
                      onClick={() => setFilterUsers("user")}
                    >
                      Press Members
                    </SubMenuLink>
                  </li>
                  <li className="nav-item">
                    <SubMenuLink
                      to="pending"
                      onClick={() => setFilterUsers("pending")}
                    >
                      Pending for Activation
                    </SubMenuLink>
                  </li>
                  <li className="nav-item">
                    <SubMenuLink
                      to="admin"
                      onClick={() => setFilterUsers("admin")}
                    >
                      Admins
                    </SubMenuLink>
                  </li>
                  <li className="nav-item">
                    <SubMenuLink
                      to="inactive"
                      onClick={() => setFilterUsers("inactive")}
                    >
                      Denied
                    </SubMenuLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="slice slice-sm ">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div>
                {/* <!-- Press Release Show --> */}
                <div className="table-responsive">
                  <table className="table table-cards align-items-center">
                    <thead>
                      <tr>
                        <th>User</th>
                        <th>Job Title</th>
                        <th>Outlet</th>
                        <th className="text-center">Status</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <ShowUsersContentSkeleton />
                      ) : (
                        <ShowUsersContent filter={filterUsers} />
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default connect(null, { fetchUsers })(ShowUsers);
