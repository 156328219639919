import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { fetchFilmAdmin } from "../../../actions";
import FilmEditSkeleton from "./edit/FilmEditSkeleton";
import FilmEditContent from "./edit/FilmEditContent";

const FilmEdit = ({ film, fetchFilmAdmin }) => {
  let { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchContent = async () => {
      setIsLoading(true);
      await fetchFilmAdmin(id);
      setIsLoading(false);
    };

    fetchContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <FilmEditSkeleton />;
  } else {
    return <FilmEditContent film={film} />;
  }
};

const mapStateToProps = (state) => {
  return { film: state.bView.film };
};

export default connect(mapStateToProps, { fetchFilmAdmin })(FilmEdit);
