import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ExternalLink, Edit2, Trash2 } from "react-feather";
import Swal from "sweetalert2";

import {
  fetchReleasesAdmin,
  changeReleaseStatus,
  deleteRelease,
} from "../../../actions";
import useDocumentTitle from "../../public/useDocumentTitle";
import SectionHeader from "../_utils/SectionHeader";
import shortenText from "../../_utils/shortenText";
import ShowReleasesSkeleton from "./ShowReleasesSkeleton";

const ShowReleases = ({
  releases,
  fetchReleasesAdmin,
  changeReleaseStatus,
  deleteRelease,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  //Fetch releases
  useEffect(() => {
    const fetchScript = async () => {
      setIsLoading(true);
      await fetchReleasesAdmin();
      setIsLoading(false);
    };

    fetchScript();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDocumentTitle("Press Releases - Black Letter Press");

  //Script for status change
  const changeStatus = (id, newStatus) => {
    changeReleaseStatus({ id, status: newStatus });
  };

  //Script for deleting
  const deleteReleaseScript = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `Deleting this press release is an irreversible action!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        triggerDelete(id);
      }
    });
  };

  const triggerDelete = async (id) => {
    try {
      await deleteRelease(id);
    } catch (error) {
      console.log(error);
    }
  };

  //Map items
  const content = () => {
    return releases.map((el) => {
      let date = new Date(el.date);
      return (
        <>
          <tr key={`row_${el.id}`}>
            <th scope="row">
              <div className="media align-items-center">
                <div></div>
                <div className="media-body">
                  <Link
                    to={`/admin/releases/${el.id}`}
                    className="name h6 mb-0 text-sm"
                  >
                    {shortenText(el.titleEn, 55)}
                  </Link>
                  <small className="d-block">
                    {date.toLocaleDateString("en", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      timeZone: "UTC",
                    })}
                  </small>
                </div>
              </div>
            </th>
            <td>
              <div className="dropdown dropdown">
                <button
                  className="btn btn-sm btn-secondary dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {el.status === "public" ? "Published" : "Draft"}
                </button>
                <div className="dropdown-menu">
                  {el.status === "public" ? (
                    <span
                      className="dropdown-item"
                      onClick={() => changeStatus(el.id, "draft")}
                      key={`changeToDraft_${el.id}`}
                    >
                      Draft
                    </span>
                  ) : (
                    <span
                      className="dropdown-item"
                      onClick={() => changeStatus(el.id, "public")}
                      key={`changeToPublic_${el.id}`}
                    >
                      Publish
                    </span>
                  )}
                </div>
              </div>
            </td>
            <td className="text-right">
              {/* <!-- Actions --> */}
              <div className="actions ml-3">
                <Link
                  to={`/releases/${el.id}`}
                  target="_blank"
                  className="action-item mr-2"
                  data-toggle="tooltip"
                  title="Quick view"
                >
                  <ExternalLink size={18} />
                </Link>
                <Link
                  to={`/admin/releases/${el.id}`}
                  className="action-item text-warning mr-2"
                  data-toggle="tooltip"
                  title="Edit"
                >
                  <Edit2 size={18} />
                </Link>
                <span
                  className="action-item text-danger mr-2"
                  data-toggle="tooltip"
                  title="Delete"
                  onClick={() => deleteReleaseScript(el.id)}
                >
                  <Trash2 size={18} />
                </span>
              </div>
            </td>
          </tr>
        </>
      );
    });
  };

  return (
    <>
      <SectionHeader title="Press Releases">
        <Link
          to="/admin/releases/create"
          className="btn btn-sm btn-primary btn-icon"
        >
          <span className="btn-inner--text">Add Press Release</span>
          <span className="btn-inner--icon">
            <i className="fas fa-plus"></i>
          </span>
        </Link>
      </SectionHeader>

      <section className="slice slice-sm bg-section-secondary">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div>
                {/* <!-- Press Release Show --> */}
                <div className="table-responsive">
                  <table className="table table-cards align-items-center">
                    <thead>
                      <tr>
                        <th>Post</th>
                        <th>Status</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {!isLoading ? content() : <ShowReleasesSkeleton />}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    releases: Object.values(state.bReleases),
  };
};

export default connect(mapStateToProps, {
  fetchReleasesAdmin,
  changeReleaseStatus,
  deleteRelease,
})(ShowReleases);
