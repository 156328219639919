import axios from "axios";
import store from "./../store";

// grab current state
const state = store.getState();

const authToken = state.auth.authToken;
// console.log(`Auth Token: ${authToken}`);
console.log("Version: EC2-1.0.101");

export default axios.create({
  withCredentials: true,
  // baseURL: "http://localhost:5000/v1",
  baseURL: "https://api.pressdesk.io/v1",
  // headers: { Authorization: `Bearer ${authToken}` },
});
