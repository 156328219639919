// import React from "react";
import Swal from "sweetalert2";
// import { useTranslation } from "react-i18next";

const Notification = (type, props) => {
  // const { t } = useTranslation();

  if (type === "sweetAlert") {
    // props.title = t(props.title);
    // props.text = t(props.text);
    // console.log(`Title: ${props.title}`);
    return Swal.fire(props);
  }
};

export default Notification;
