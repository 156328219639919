import React from "react";
import { Link } from "react-router-dom";
import { Bell } from "react-feather";
import { EditorState, convertFromRaw } from "draft-js";

import shortenText from "../../_utils/shortenText";

const DashboardContent = ({ data }) => {
  const showUsers = () => {
    return data.usersPending.map((el) => {
      return (
        <>
          <Link
            to={`users/${el.id}`}
            className="list-group-item list-group-item-action border-0 py-2"
          >
            <div className="d-flex">
              <div>
                <Bell size={20} className="mr-3 text-danger" />
              </div>
              <div>
                <div className="text-sm lh-150">
                  <span className="text-dark font-weight-bold">
                    {`${el.firstName} ${el.lastName}`}
                  </span>{" "}
                  from{" "}
                  <span className="text-dark font-weight-bold">
                    {el.outletName}
                  </span>
                </div>
              </div>
            </div>
          </Link>
        </>
      );
    });
  };

  const showReleases = () => {
    return data.releasesLatest.map((el) => {
      //Format the date
      const dateToFormat = new Date(el.date);

      const dateFormated = dateToFormat.toLocaleDateString("en", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });

      //Format the body
      const contentState = EditorState.createWithContent(
        convertFromRaw(JSON.parse(el.contentEn))
      );
      // el.content = stateToHTML(contentState.getCurrentContent());
      el.content = contentState.getCurrentContent().getPlainText();

      return (
        <>
          <Link
            to={`releases/${el.id}`}
            className="list-group-item list-group-item-action"
          >
            <div className="d-flex align-items-center">
              <div className="flex-fill ml-3">
                <div className="h6 text-sm mb-0">{el.titleEn}</div>
                <p className="text-sm lh-140 mb-0">
                  <span className="text-muted text-underline--dashed">
                    {dateFormated} |
                  </span>{" "}
                  {shortenText(el.content, 85)}
                </p>
              </div>
            </div>
          </Link>
        </>
      );
    });
  };
  return (
    <>
      {/* <!-- Header --> */}
      <section className="pt-5 pb-5 bg-section-secondary">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="row align-items-center">
                <div className="col ml-3 ml-md-n2">
                  {/* <!-- Title --> */}
                  <h2 className="mb-0">
                    <b>Press</b> Desk
                  </h2>
                  {/* <!-- Subtitle --> */}
                  <span className="text-muted d-block">Black Letter Films</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="slice slice-sm bg-section-primary">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              {/* <!-- Quick stats --> */}
              <div>
                {/* <!-- Title --> */}
                <div className="row align-items-center mb-3">
                  <div className="col">
                    <h6 className="mb-0">Quick stats</h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card card-fluid">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-6 col-sm-3 text-center">
                            <span className="h5 mb-0">{data.publicFilms}</span>
                            <span className="d-block text-sm">
                              Public Films
                            </span>
                          </div>
                          <div className="col-6 col-sm-3 text-center">
                            <span className="h5 mb-0">{data.privateFilms}</span>
                            <span className="d-block text-sm">
                              Private Films
                            </span>
                          </div>
                          <div className="col-6 col-sm-3 text-center">
                            <span className="h5 mb-0">{data.releases}</span>
                            <span className="d-block text-sm">
                              Press Releases
                            </span>
                          </div>
                          <div className="col-6 col-sm-3 text-center">
                            <span className="h5 mb-0">{data.users}</span>
                            <span className="d-block text-sm">Press Users</span>
                          </div>
                        </div>
                      </div>
                      <div className="card-footer py-2">
                        <div className="row align-items-center">
                          <div className="col-6">
                            <Link
                              to="releases/create/"
                              className="btn btn-xs btn-warning text-dark btn-icon rounded-pill"
                            >
                              <span className="btn-inner--icon">
                                <i className="fas fa-plus"></i>
                              </span>
                              <span className="btn-inner--text">
                                Add New Press Release
                              </span>
                            </Link>
                          </div>
                          <div className="col-6 text-right">
                            <Link
                              to="films/create/"
                              className="btn btn-xs btn-primary btn-icon rounded-pill"
                            >
                              <span className="btn-inner--icon">
                                <i className="fas fa-plus"></i>
                              </span>
                              <span className="btn-inner--text">
                                Add New Film
                              </span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-md-6">
                  <div className="card">
                    {/* <!-- Title --> */}
                    <div className="card-header">
                      <h6>Press Releases</h6>
                    </div>
                    {/* <!-- List group --> */}
                    <div className="list-group list-group-flush">
                      {showReleases()}
                    </div>
                    {/* <!-- Card footer --> */}
                    <div className="card-footer py-3 text-center border-0 position-relative">
                      <Link
                        to="releases/"
                        className="text-xs ls-1 text-uppercase font-weight-bold stretched-link"
                      >
                        See all
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-header">
                      <h6>
                        Users Pending for Activation ({data.usersPending.length}
                        )
                      </h6>
                    </div>
                    {/* <!-- List group --> */}
                    <div className="list-group list-group-flush">
                      {showUsers()}
                    </div>
                    {/* <!-- Card footer --> */}
                    <div className="card-footer py-3 text-center mt-3 position-relative">
                      <Link
                        to="users?filter=pending"
                        className="text-xs ls-1 text-uppercase font-weight-bold stretched-link"
                      >
                        See all
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DashboardContent;
