import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { EditorState, convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";

import { fetchRelease } from "../../../actions";
import useDocumentTitle from "../useDocumentTitle";
import IndividualReleaseSkeleton from "./IndividualReleaseSkeleton";

const IndividualRelease = ({ fetchRelease, release, fetchStatus }) => {
  const [isLoading, setIsLoading] = useState(true);
  let { id } = useParams();
  let { t } = useTranslation();

  useEffect(() => {
    const fetchContent = async (id) => {
      setIsLoading(true);
      await fetchRelease(id);
      setIsLoading(false);
    };

    fetchContent(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let navigate = useNavigate();

  if (fetchStatus === 404) {
    //Redirect to 404
    navigate("/404", { replace: true });
  } else if (fetchStatus === 500) {
    //Redirect to 500
    navigate("/500", { replace: true });
  }

  /*
   * Translate DB values
   */

  if (i18next.language === "en") {
    release.title = release.titleEn;
    release.mediaContacts = release.mediaContactsEn;
    release.introContent = release.introContentEn;
    release.content = release.contentEn;
  } else if (i18next.language === "es") {
    release.title = release.titleEs;
    release.mediaContacts = release.mediaContactsEs;
    release.introContent = release.introContentEs;
    release.content = release.contentEs;
  }

  useDocumentTitle(`${release.title} - Black Letter Press`);

  //Transform the content to DraftJS State
  if (release.content) {
    const contentState = EditorState.createWithContent(
      convertFromRaw(JSON.parse(release.content))
    );
    release.content = stateToHTML(contentState.getCurrentContent());
  }

  if (release.mediaContacts) {
    const mediaContactsState = EditorState.createWithContent(
      convertFromRaw(JSON.parse(release.mediaContacts))
    );
    release.mediaContacts = stateToHTML(mediaContactsState.getCurrentContent());
  }

  /*
   * Format the Release Date
   */
  const dateToFormat = new Date(release.date);

  release.dateFormated = dateToFormat.toLocaleDateString(i18next.language, {
    year: "numeric",
    month: "long",
    day: "numeric",
    timeZone: "UTC",
  });

  if (isLoading) {
    return <IndividualReleaseSkeleton />;
  } else {
    return (
      <>
        <section
          className="slice slice-lg pt-17 pb-0 bg-cover bg-size--cover"
          style={{
            backgroundImage: `url(${release.image})`,
          }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-9 col-lg-10">
                <div className="card mb-n7 position-relative zindex-100">
                  <div className="card-body p-md-5">
                    <div className=" text-center">
                      <span>{release.dateFormated}</span>

                      <h1 className="h2 lh-150 mt-3 mb-0">{release.title}</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="slice slice-lg pt-10 pb-5">
          <div className="container pb-6">
            <div className="row row-grid align-items-center">
              <div className="col-xl-8 col-lg-10 offset-xl-2 offset-lg-1">
                <article>
                  <h5>{release.introContent}</h5>
                  {/* {release.content} */}
                  <div
                    dangerouslySetInnerHTML={{ __html: release.content }}
                  ></div>
                </article>
              </div>
            </div>
          </div>
        </section>

        <section className="slice slice-lg bg-section-secondary">
          <div className="container">
            <div className="row align-items-center mb-5">
              <div className="col-12 col-md">
                <h3 className="h4 mb-4">{t("pressReleases.mediaContacts")}</h3>
                <div
                  className="mb-0 text-muted"
                  dangerouslySetInnerHTML={{ __html: release.mediaContacts }}
                ></div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    release: state.releaseView,
    fetchStatus: state.fetchStatus.releaseView,
  };
};

export default connect(mapStateToProps, { fetchRelease })(IndividualRelease);
