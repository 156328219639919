import _ from "lodash";
import {
  FETCH_FILMS_B,
  // CREATE_FILM,
  EDIT_FILM,
  UPDATE_STATUS_FILM,
  DELETE_FILM,
} from "../actions/types";

const bFilmsReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_FILMS_B:
      return { ...state, ..._.mapKeys(action.payload, "id") };
    // case CREATE_FILM:
    //   return { ...state, [action.payload.id]: action.payload };
    case EDIT_FILM:
      return { ...state, [action.payload.id]: action.payload };
    case UPDATE_STATUS_FILM:
      // const newData = _.mapKeys(action.payload, "id");
      // return _.merge(state, newData);
      return { ...state, [action.payload.id]: action.payload };

    case DELETE_FILM:
      return _.omit(state, action.payload);
    default:
      return state;
  }
};

export default bFilmsReducer;
