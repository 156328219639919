import React from "react";
import RenderError from "./RenderError";

const RenderTextArea = ({
  input,
  label,
  placeholder,
  customClass,
  fieldType,
  meta,
}) => {
  const className = `form-control ${
    meta.error && meta.touched ? "is-invalid" : ""
  }`;

  return (
    <>
      {RenderError(meta)}

      <div className={`form-group ${customClass}`}>
        <label className="form-control-label">{label}</label>
        <textarea
          {...input}
          id={input.name}
          type={fieldType}
          className={className}
          placeholder={placeholder}
          data-toggle="autosize"
          row="2"
        />
      </div>
    </>
  );
};

export default RenderTextArea;
