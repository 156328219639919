import {
  FETCH_FILM_STATUS,
  FETCH_FILMS_STATUS,
  FETCH_FILMS_FEATURED_STATUS,
  FETCH_RELEASES_STATUS,
  FETCH_RELEASE_STATUS,
  FETCH_RELEASES_FEATURED_STATUS,
} from "../actions/types";

const INITIAL_STATE = {
  films: null,
  filmsFeatured: null,
  filmView: null,
  releases: null,
  releasesFeatured: null,
  releasesSearch: null,
  releaseView: null,
};

const fetchStatusReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_FILM_STATUS:
      return {
        ...state,
        filmView: action.payload,
      };
    case FETCH_FILMS_STATUS:
      return {
        ...state,
        films: action.payload,
      };
    case FETCH_FILMS_FEATURED_STATUS:
      return {
        ...state,
        filmsFeatured: action.payload,
      };
    case FETCH_RELEASES_STATUS:
      return { ...state, releases: action.payload };
    case FETCH_RELEASES_FEATURED_STATUS:
      return { ...state, releasesFeatured: action.payload };
    case FETCH_RELEASE_STATUS:
      return { ...state, releaseView: action.payload };
    default:
      return state;
  }
};

export default fetchStatusReducer;
