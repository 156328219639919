import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, reset } from "redux-form";
import Swal from "sweetalert2";

import FestivalElement from "./FestivalElement";
import TableComponent from "../_utils/TableComponent";
import {
  updateFestivalLaurel,
  createFestivalLaurel,
  deleteFestivalLaurel,
} from "../../../../../actions";
import validate from "./validate";
import RenderInput from "../../../_utils/RenderInput";
import RenderUpload from "../../../_utils/RenderUpload";

const FestivalLaurels = ({
  laurels,
  updateFestivalLaurel,
  filmId,
  submitting,
  handleSubmit,
  createFestivalLaurel,
  deleteFestivalLaurel,
}) => {
  const addLaurel = async (data, dispatch) => {
    data.filmId = filmId;
    await createFestivalLaurel(data);
    dispatch(reset("createLaurelForm"));
  };

  const editLaurelScript = async (data) => {
    data.filmId = filmId;
    await updateFestivalLaurel(data);
  };

  const deleteLaurel = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `Deleting this laurel is an irreversible action!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        triggerDelete(id);
      }
    });
  };

  const triggerDelete = async (id) => {
    const data = {
      id,
      filmId,
    };
    try {
      await deleteFestivalLaurel(data);
    } catch (error) {
      console.log(error);
    }
  };

  const loadLaurels = () => {
    if (laurels && laurels.length > 0) {
      return laurels.map((el) => {
        return (
          <FestivalElement
            el={el}
            deleteScript={deleteLaurel}
            editScript={editLaurelScript}
            key={`laurelElement_${el.id}`}
          />
        );
      });
    } else {
      return (
        <tr>
          <td colSpan={4}>There are no laurels associated to this project!</td>
        </tr>
      );
    }
  };

  return (
    <>
      <section className="slice slice-sm">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <h5 className="mb-3">Festivals Laurels</h5>

              <form onSubmit={handleSubmit(addLaurel)}>
                <div className="row">
                  <div className="col-sm-5 col-9">
                    <Field
                      name="festivalName"
                      component={RenderInput}
                      label="Festival Name"
                      placeholder="Cannes Film Festival..."
                      customClass=""
                      fieldType="text"
                    />
                  </div>

                  <div className="col-sm-2 col-3">
                    <Field
                      name="order"
                      component={RenderInput}
                      label="Order"
                      placeholder="0"
                      customClass=""
                      fieldType="number"
                    />
                  </div>

                  <div className="col-sm-5">
                    <Field
                      name="laurel"
                      component={RenderUpload}
                      label="Laurel"
                      placeholder="Select a file..."
                      customClass=""
                      accept=".png,.jpg,.jpeg"
                      notes="File size limit: 25MB"
                    />
                  </div>
                </div>

                <div className="mt-3">
                  <button
                    type="submit"
                    className="btn btn-sm btn-primary"
                    disabled={submitting}
                  >
                    Upload
                  </button>
                </div>
              </form>
              <br />

              <div className="row">
                <div className="col-sm-12">
                  <TableComponent>
                    <thead>
                      <tr>
                        <th>Order</th>
                        <th>Laurel</th>
                        <th>Festival Name</th>
                        <th className="text-right">Actions</th>
                      </tr>
                    </thead>
                    <tbody>{loadLaurels()}</tbody>
                  </TableComponent>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const wrappedComponent = reduxForm({
  form: "createLaurelForm",
  validate,
})(FestivalLaurels);

export default connect(null, {
  updateFestivalLaurel,
  createFestivalLaurel,
  deleteFestivalLaurel,
})(wrappedComponent);
