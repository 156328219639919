import React from "react";

const EditingField = ({ state, setState, type, customStyle }) => {
  return (
    <input
      className="form-control"
      value={state}
      onChange={(el) => setState(el.target.value)}
      type={type}
      style={customStyle}
    />
  );
};

export default EditingField;
