import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { User, Key, Home } from "react-feather";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";

import useDocumentTitle from "../public/useDocumentTitle";
import validate from "./signIn/validate";
import RenderInput from "./_utils/RenderInput";
import { signIn } from "../../actions";

const Login = (props) => {
  const location = String(useLocation().search);
  const { t } = useTranslation();
  const navigate = useNavigate();
  useDocumentTitle(`${t("navigation.signIn")} - Black Letter Press`);

  var navigateAdmin = "../admin",
    navigateUser = "../";

  if (location.includes("return")) {
    /*
     * Get the "return" GET parameter
     */
    //1. Remove the "?" and make an array of GET parameters
    const locationArray = location.replace("?", "").split("&");
    const locationArray2 = locationArray.map((el) => {
      const values = el.split("=");
      return { prop: values[0], value: values[1] };
    });

    //2. Search through the GET parameters searching for "return"
    const returnURLGetter = locationArray2.map((el) => {
      return el.prop === "return" ? el.value : "";
    });

    //3. Decode "return" & assign it to the navigate URL
    const returnURL = decodeURIComponent(
      String(returnURLGetter).replace(/,/g, "")
    );
    navigateAdmin = returnURL;
    navigateUser = returnURL;
  }

  useEffect(() => {
    if (props.isSignedIn && props.userRole === "admin") {
      navigate(navigateAdmin, { replace: true });
    } else if (props.isSignedIn) {
      navigate(navigateUser, { replace: true });
    }
  });

  const onSubmit = async (formValues) => {
    await props.signIn(formValues);
  };
  return (
    <section>
      <div className="container d-flex flex-column">
        <div className="row align-items-center justify-content-center min-vh-100">
          <div className="col-md-6 col-lg-5 col-xl-4 py-6 py-md-0">
            <div>
              <div className="mb-5 text-center">
                <h6 className="h3 mb-1">{t("signIn.title")}</h6>
                <p className="text-muted mb-0">{t("signIn.subtitle")}</p>
              </div>
              <span className="clearfix"></span>
              <form onSubmit={props.handleSubmit(onSubmit)}>
                <Field
                  name="email"
                  component={RenderInput}
                  label={t("signIn.email")}
                  placeholder={t("signIn.emailPlaceholder")}
                  customClass=""
                  fieldType="email"
                  icon={<User />}
                />
                <Field
                  name="password"
                  component={RenderInput}
                  label={t("signIn.password")}
                  placeholder={`${t("signIn.password")}...`}
                  customClass="mb-0"
                  fieldType="password"
                  icon={<Key />}
                />
                <Field
                  name="outlet"
                  component={RenderInput}
                  label="Outlet"
                  placeholder="Acme News..."
                  customClass="mb-0 d-none"
                  fieldType="text"
                  icon={<Home />}
                />
                <div className="mt-4">
                  <button
                    type="submit"
                    name="loginSubmit"
                    className="btn btn-block btn-primary"
                    disabled={props.submitting}
                  >
                    {t("signIn.button")}
                  </button>
                </div>
              </form>
              {/* <!-- Links --> */}
              <div className="mt-4 text-center">
                <Link to="/forgot_password" className="small font-weight-bold">
                  {t("signIn.forgotPassword")}
                </Link>{" "}
                |{" "}
                <Link to="/request" className="small font-weight-bold">
                  {t("signIn.requestAccess")}
                </Link>
              </div>
              <div className="mt-5 text-center">
                <span className="small font-weight-bold">
                  {t("signIn.needHelp.title")}
                </span>
                <br />
                <span className="small">
                  {t("signIn.needHelp.description")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return { isSignedIn: state.auth.isSignedIn, userRole: state.auth.userRole };
};

const wrappedComponent = reduxForm({
  form: "loginForm",
  validate,
})(Login);

export default connect(mapStateToProps, { signIn })(wrappedComponent);
