import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Key } from "react-feather";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import useDocumentTitle from "../public/useDocumentTitle";
import validateReset from "./forgotPassword/validateReset";
import RenderInput from "./_utils/RenderInput";
import { resetPassword } from "../../actions";

const ResetPassword = (props) => {
  let { token } = useParams();

  const { t } = useTranslation();
  const navigate = useNavigate();
  useDocumentTitle(`${t("forgotPassword.title")} - Black Letter Press`);

  useEffect(() => {
    if (props.isSignedIn) {
      navigate("/", { replace: true });
    }
  });

  if (!token) {
    navigate("/forgot_password", { replace: true });
  }

  const onSubmit = async (formValues) => {
    const data = {
      token,
      password: formValues.password,
    };
    await props.resetPassword(data);
    navigate("/login", { replace: true });
  };
  return (
    <section>
      <div className="container d-flex flex-column">
        <div className="row align-items-center justify-content-center min-vh-100">
          <div className="col-md-6 col-lg-5 col-xl-4 py-6 py-md-0">
            <div>
              <div className="mb-5 text-center">
                <h6 className="h3 mb-1">{t("forgotPassword.title")}</h6>
                <p className="text-muted mb-0">
                  {t("forgotPassword.subtitle2")}
                </p>
              </div>
              <span className="clearfix"></span>
              <form onSubmit={props.handleSubmit(onSubmit)}>
                <Field
                  name="password"
                  component={RenderInput}
                  label={t("forgotPassword.fields.newPassword")}
                  placeholder="********"
                  customClass=""
                  fieldType="password"
                  icon={<Key />}
                />
                <Field
                  name="passwordRepeat"
                  component={RenderInput}
                  label={t("forgotPassword.fields.repeatPassword")}
                  placeholder="********"
                  customClass=""
                  fieldType="password"
                  icon={<Key />}
                />
                <div className="mt-4">
                  <button
                    type="submit"
                    name="forgotPassVerifySubmit"
                    className="btn btn-block btn-primary"
                    disabled={props.submitting}
                  >
                    {t("forgotPassword.fields.change")}
                  </button>
                </div>
              </form>
              {/* <!-- Links --> */}
              <div className="mt-4 text-center">
                <Link to="/login" className="small font-weight-bold">
                  {t("forgotPassword.signIn")}
                </Link>{" "}
                |{" "}
                <Link to="/request" className="small font-weight-bold">
                  {t("signIn.requestAccess")}
                </Link>
              </div>
              <div className="mt-5 text-center">
                <span className="small font-weight-bold">
                  {t("signIn.needHelp.title")}
                </span>
                <br />
                <span className="small">
                  {t("signIn.needHelp.description")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return { isSignedIn: state.auth.isSignedIn, userRole: state.auth.userRole };
};

const wrappedComponent = reduxForm({
  form: "resetPassForm",
  validate: validateReset,
})(ResetPassword);

export default connect(mapStateToProps, { resetPassword })(wrappedComponent);
