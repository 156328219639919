const validateReset = (formValues) => {
  const errors = {};

  if (!formValues.password) {
    //Password is empty
    errors.password = "signUp.errors.passwordEmpty";
  }

  if (formValues.password !== formValues.passwordRepeat) {
    //Password don't match
    errors.password = "signUp.errors.passwordsDontMatch";
  }

  if (!/[a-z]/g.test(formValues.password)) {
    //The password doesn't have a lower case letter
    errors.password = "signUp.errors.passwordLower";
  }

  if (!/[A-Z]/g.test(formValues.password)) {
    //The password doesn't have upper case letter
    errors.password = "signUp.errors.passwordUpper";
  }

  if (!/[0-9]/g.test(formValues.password)) {
    //The password doesn't have a number
    errors.password = "signUp.errors.passwordNumber";
  }

  if (String(formValues.password).length <= 7) {
    //The password has less than 8 characters
    errors.password = "signUp.errors.password8Chars";
  }

  if (!formValues.passwordRepeat) {
    //Password repeat is empty
    errors.passwordRepeat = "signUp.errors.passwordRepeatEmpty";
  }

  return errors;
};

export default validateReset;
