import React from "react";
import { Edit2, Trash2, Eye, Check, X } from "react-feather";

const ActionBar = ({
  navbar,
  isEditing,
  setIsEditing,
  saveScript,
  deleteScript,
  previewUrl,
}) => {
  const saveButton = () => {
    return (
      <>
        <div
          className="icon text-danger icon-shape hover-scale-110"
          onClick={() => setIsEditing(false)}
        >
          <X />
        </div>

        <div
          className="icon text-success icon-shape hover-scale-110"
          onClick={saveScript}
        >
          <Check />
        </div>
      </>
    );
  };

  const editButton = () => {
    return (
      <>
        <div
          className="icon text-warning icon-shape hover-scale-110"
          onClick={() => setIsEditing(true)}
        >
          <Edit2 />
        </div>
      </>
    );
  };

  const deleteButton = () => {
    return (
      <>
        <div
          className="icon text-danger icon-shape hover-scale-110"
          onClick={deleteScript}
        >
          <Trash2 />
        </div>
      </>
    );
  };

  const previewButton = () => {
    return (
      <>
        <a href={previewUrl} data-fancybox>
          <div className="icon text-primary icon-shape hover-scale-110">
            <Eye />
          </div>
        </a>
      </>
    );
  };

  const showEditButton = () => {
    if (navbar.edit) {
      return !isEditing ? editButton() : saveButton();
    } else {
      return null;
    }
  };

  return (
    <>
      {navbar.preview && !isEditing && previewButton()}
      {showEditButton()}
      {navbar.delete && !isEditing && deleteButton()}
    </>
  );
};

export default ActionBar;
