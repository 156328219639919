import React from "react";
import { useTranslation } from "react-i18next";
import ImageComponent from "./ImageComponent";
import DropdownLinks from "./DropdownLinks";

const TopContent = ({ film }) => {
  let { t } = useTranslation();

  //Dropdown Button with Links
  const renderButtonLinks = (object, translation) => {
    if (object && object.length > 0) {
      return (
        <>
          <div className="btn-group">
            <button
              type="button"
              className="btn btn-primary dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {t(translation)}
            </button>
            <div className="dropdown-menu">
              <DropdownLinks links={object} />
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <div className="row pb-4">
      <div className="col-md-4 col-sm-12 sm-pb-3">
        <ImageComponent
          placeholderHeight="380px"
          src={film.oneSheet}
          width="100%"
        />
      </div>
      <div className="col-md-8 col-sm-12">
        <div>
          <small>
            {t(`filmPage.distributionStatus.${film.distributionStatus}`)}
          </small>
        </div>
        <div>
          <h2 className="card-title">{film.name}</h2>
        </div>

        <div className="pb-3">{film.logline}</div>
        <div>
          <b className="text-underline--dashed">{t("filmPage.director")}:</b>{" "}
          {film.director}
        </div>
        <div>
          <b className="text-underline--dashed">{t("filmPage.writtenBy")}:</b>{" "}
          {film.writer}
        </div>
        <div>
          <b className="text-underline--dashed">{t("filmPage.producedBy")}:</b>{" "}
          {film.producer}
        </div>
        <div>
          <b className="text-underline--dashed">{t("filmPage.cast")}:</b>{" "}
          {film.cast}
        </div>
        <br />
        <div>
          {renderButtonLinks(film.epkLinks, "filmPage.EPK")}
          &nbsp;
          {renderButtonLinks(film.onePagerLinks, "filmPage.onePager")}
          &nbsp;
          {renderButtonLinks(film.pressNotesLinks, "filmPage.productionNotes")}
        </div>
      </div>
    </div>
  );
};

export default TopContent;
