import React from "react";
import { Link } from "react-router-dom";
import i18next from "i18next";
import { EditorState, convertFromRaw } from "draft-js";

import ImageComponent from "../films/individualFilm/ImageComponent";
import shortenText from "../../_utils/shortenText";

const IndividualReleaseSnippet = ({ release }) => {
  if (i18next.language === "en") {
    release.title = release.titleEn;
    release.content = release.contentEn;
  } else if (i18next.language === "es") {
    release.title = release.titleEs;
    release.content = release.contentEs;
  }

  //Transform the content to DraftJS State
  if (release.content) {
    const contentState = EditorState.createWithContent(
      convertFromRaw(JSON.parse(release.content))
    );
    release.content = String(contentState.getCurrentContent().getPlainText());
  }

  release.shortTitle = shortenText(release.title, 65);
  release.shortContent = shortenText(release.content, 125);

  /*
   * Format the Date
   */
  const dateToFormat = new Date(release.date);

  release.dateFormated = dateToFormat.toLocaleDateString(i18next.language, {
    year: "numeric",
    month: "long",
    day: "numeric",
    timeZone: "UTC",
  });

  return (
    <>
      <div className="col-xl-4 col-md-6" key={release.id}>
        <div className="card hover-translate-y-n3 hover-shadow-lg overflow-hidden">
          <div className="position-relative overflow-hidden">
            <Link to={release.id} className="d-block">
              <ImageComponent
                placeholderHeight="300px"
                isPoster={true}
                alt={release.shortTitle}
                src={release.imageList}
                className="card-img-top"
              />
            </Link>
          </div>
          <div className="card-body py-4">
            <small className="d-block text-sm mb-2">
              {release.dateFormated}
            </small>
            <Link
              to={release.id}
              className="h5 stretched-link lh-150"
              title={release.title}
            >
              {release.shortTitle}
            </Link>
            <p className="mt-3 mb-0 lh-170">{release.shortContent}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default IndividualReleaseSnippet;
