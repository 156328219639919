import React, { useEffect } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import AdminHeader from "./AdminHeader";

const AdminMainContainer = (props) => {
  let navigate = useNavigate();
  let location = useLocation();
  let returnLocation = encodeURIComponent(location.pathname);

  useEffect(() => {
    if (!props.isSignedIn) {
      navigate(`/login?return=${returnLocation}`, { replace: true });
    } else if (props.userRole !== "admin") {
      navigate("/", { replace: true });
    }
  });

  return (
    <div>
      <AdminHeader />
      <Outlet />
    </div>
  );
};

const mapStateToProps = (state) => {
  return { isSignedIn: state.auth.isSignedIn, userRole: state.auth.userRole };
};

export default connect(mapStateToProps)(AdminMainContainer);
