import React from "react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import ImageComponent from "./ImageComponent";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const ImagesGallery = ({ elements, title, thumbnailHeight }) => {
  // console.log("One-Sheets");
  // console.log(oneSheets);
  let { t } = useTranslation();

  const renderItems = (values) => {
    return values.map((el) => {
      // console.log(el);
      if (i18next.language === "en") {
        el.name = el.nameEn;
        el.copyright = el.copyrightEn;
      } else if (i18next.language === "es") {
        el.name = el.nameEs;
        el.copyright = el.copyrightEs;
      }

      return (
        <div
          className="col-lg-3 col-md-4 col-sm-6 col-xs-12"
          key={`onesheet_${el.id}`}
        >
          <OverlayTrigger
            key={`ot-${el.id}`}
            placement="top"
            overlay={
              <Tooltip id={`tooltip-${el.id}`}>
                {`${el.size} / ${el.dimensions}`}
              </Tooltip>
            }
          >
            <div className="card text-center">
              <div className="card-body">
                <a href={el.url} data-fancybox>
                  <ImageComponent
                    placeholderHeight={thumbnailHeight}
                    className="pb-3"
                    src={el.thumb}
                    width="100%"
                  />
                </a>
                <span className="text-dark">{el.name}</span>
                <small>
                  <div>{el.copyright}</div>
                </small>
                <div className="pt-2">
                  <OverlayTrigger
                    key={`download-ot-${el.id}`}
                    placement="top"
                    overlay={
                      <Tooltip id={`download-tooltip-${el.id}`}>
                        {t("filmPage.download")}
                      </Tooltip>
                    }
                  >
                    <a href={el.url}>
                      <i className="fas fa-download"></i>
                    </a>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
          </OverlayTrigger>
        </div>
      );
    });
  };

  if (elements && elements.length >= 1) {
    return (
      <div className="row pb-5">
        <div className="col-12">
          <h5 className="card-title h4">{t(`filmPage.${title}`)}</h5>
          <div className="row">{renderItems(elements)}</div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default ImagesGallery;
