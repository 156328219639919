import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";

import AdminPageTitle from "../AdminPageTitle";
import validate from "./create/validate";
import RenderInput from "../_utils/RenderInput";
import RenderUpload from "../_utils/RenderUpload";
import { createFilm } from "../../../actions";
import { useNavigate } from "react-router-dom";
import useDocumentTitle from "../../public/useDocumentTitle";

const FilmCreate = (props) => {
  let navigate = useNavigate();

  useDocumentTitle("Add Film - Black Letter Press");

  const onSubmit = async (formValues) => {
    const response = await props.createFilm(formValues);
    if (response !== false) {
      navigate(`/admin/films/?filter=draft`, { replace: true });
    }
  };

  return (
    <>
      <AdminPageTitle>Add New Film</AdminPageTitle>
      <section className="slice slice-sm bg-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div>
                {/* <!-- Content --> */}
                <div className="row">
                  <div className="col-lg-12">
                    <div>
                      {/* <!-- Title --> */}
                      <h5 className="mb-3">Basic Information</h5>
                      {/* <!-- Form --> */}
                      <form onSubmit={props.handleSubmit(onSubmit)}>
                        {/* <!-- General information --> */}
                        <div className="row">
                          <div className="col-md-6">
                            <Field
                              name="titleEn"
                              component={RenderInput}
                              label="Film Title (English)"
                              placeholder="Enter the film title..."
                              customClass=""
                              fieldType="text"
                            />
                          </div>
                          <div className="col-md-6">
                            <Field
                              name="titleEs"
                              component={RenderInput}
                              label="Film Title (Spanish)"
                              placeholder="Escribe el título del film..."
                              customClass=""
                              fieldType="text"
                            />
                          </div>
                        </div>
                        <div className="row align-items-center">
                          <div className="col-md-6">
                            <Field
                              name="slug"
                              component={RenderInput}
                              label="Slug"
                              placeholder="The short name for the links..."
                              customClass=""
                              fieldType="text"
                            />
                          </div>
                          <div className="col-md-6">
                            <Field
                              name="year"
                              component={RenderInput}
                              label="Year"
                              placeholder="Release year or ???? if unknown..."
                              customClass=""
                              fieldType="text"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <Field
                              name="oneSheet"
                              component={RenderUpload}
                              label="One-Sheet"
                              placeholder="Select a file..."
                              customClass=""
                              accept=".png,.jpg,.jpeg"
                              notes="File size limit: 25MB"
                            />
                          </div>
                        </div>
                        {/* <!-- Buttons --> */}
                        <div className="mt-3">
                          <button
                            type="submit"
                            id="submitFilmCreate"
                            className="btn btn-sm btn-primary"
                            disabled={props.submitting}
                          >
                            Create
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const wrappedComponent = reduxForm({
  form: "filmCreateForm",
  validate,
})(FilmCreate);

export default connect(null, { createFilm })(wrappedComponent);
