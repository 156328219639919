import React from "react";
import { useTranslation } from "react-i18next";
import VideoGallery from "./VideoGallery";

const VideosMain = ({ trailers, clips }) => {
  let { t } = useTranslation();

  if (trailers && clips) {
    if (trailers.length >= 1 || clips.length >= 1) {
      return (
        <div className="row pb-5">
          <div className="col-12">
            <h5 className="card-title h4">{t("filmPage.videos.videos")}</h5>
            <VideoGallery elements={trailers} title={"videos.trailers"} />
            <VideoGallery elements={clips} title={"videos.clips"} />
          </div>
        </div>
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export default VideosMain;
