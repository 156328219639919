// import _ from "lodash";
import pressDeskAPI from "../apis/pressdesk";
import {
  SIGN_IN,
  SIGN_OUT,
  FETCH_FILM,
  FETCH_FILM_STATUS,
  FETCH_FILMS_FEATURED,
  FETCH_FILMS_FEATURED_STATUS,
  FETCH_FILMS,
  FETCH_FILMS_STATUS,
  FETCH_FILMS_B,
  FETCH_FILM_ADMIN,
  // EDIT_FILM,
  UPDATE_STATUS_FILM,
  // DELETE_FILM,
  FETCH_RELEASE,
  FETCH_RELEASE_STATUS,
  FETCH_RELEASES,
  FETCH_RELEASES_STATUS,
  FETCH_RELEASES_FEATURED,
  EDIT_RELEASE,
  DELETE_RELEASE,
  FETCH_RELEASES_B,
  FETCH_RELEASES_FEATURED_STATUS,
  FETCH_RELEASE_ADMIN,
  FETCH_USERS_ADMIN,
  UPDATE_STATUS_USER,
  CREATE_FILM_FRAGMENT,
  DELETE_FILM_LINK,
  FETCH_DASHBOARD_ADMIN,
  DELETE_FILM_FRAGMENT,
} from "./types";
import Swal from "sweetalert2";
import Notification from "../components/_utils/Notification";

export const signIn = (formValues) => async (dispatch) => {
  try {
    const response = await pressDeskAPI.post("/users/login", formValues);

    const userInfo = {
      id: response.data.user.id,
      role: response.data.user.role,
      name: response.data.user.firstName,
      authToken: response.data.token,
    };

    dispatch({ type: SIGN_IN, payload: userInfo });

    Notification("sweetAlert", {
      icon: "success",
      timer: 1500,
      showConfirmButton: false,
    });
  } catch (error) {
    const err = String(error.response.data.error);

    switch (err) {
      case "incorrect_credentials":
        Notification("sweetAlert", {
          icon: "error",
          title: "Incorrect credentials",
          text: "Please, try again!",
        });
        break;
      case "error_pending":
        Swal.fire({
          icon: "warning",
          title: "Activation Pending for Activation",
          text: "Your account is still being verified by our team. Please, wait for a response email or check back later!",
        });
        break;
      case "error_user":
        Swal.fire({
          icon: "error",
          title: "User not found",
          text: "We couldn't find that user. Please, try again!",
        });
        break;
      case "email_empty":
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "The email can't be empty!",
        });
        break;
      case "password_empty":
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "The password can't be empty!",
        });
        break;
      default:
        // console.log(err);

        Swal.fire({
          icon: "error",
          title: "Error",
          text: "There was an error. Please, try again!",
        });
    }
  }
};

export const logOut = () => async (dispatch) => {
  console.log("Logging out...");
  try {
    await pressDeskAPI.post("/users/logout");

    dispatch({ type: SIGN_OUT });
  } catch (error) {
    // const err = String(error.response.data.message);
    // console.log(`Error message: ${err}`);
  }
};

export const signUp = (formValues) => async (dispatch) => {
  try {
    await pressDeskAPI.post("/users/signup", formValues);

    Swal.fire({
      icon: "success",
      title: "Request Sent",
      text: "Once a decision has been made about your request, you'll receive an email with the results. Most requests are processed within 12 hours or less.",
    });
  } catch (error) {
    const err = String(error.response.data.error);

    switch (err) {
      case "email_exists":
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "The email is already associated with an account! Try with a different email address or resetting your password",
        });
        break;
      case "email_invalid":
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "The email is invalid!",
        });
        break;
      case "phone_invalid":
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "The phone number is invalid!",
        });
        break;
      default:
        //   console.log(err);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "There was an error. Please, try again!",
        });
    }
  }
};

export const forgotPassword = (data) => async (dispatch) => {
  try {
    await pressDeskAPI.post(`/users/forgotPassword`, data);

    Notification("sweetAlert", {
      icon: "success",
      // text: "forgotPassword.notification.success",
      text: "A verification link has been sent to your email account. Click on the link to reset the password. The link is only avaiable for 10 minutes.",
    });
  } catch (error) {
    // console.log(error);
    const err = String(error.response.data.message);

    switch (err) {
      case "email_empty":
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "The email is empty!",
        });
        break;
      case "error_user":
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "The user doesn't exists!",
        });
        break;
      case "validation_error":
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "There was an error. Please, try again!",
        });
        break;
      default:
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "There was an error. Please, try again!",
        });
    }
  }
};

export const resetPassword = (data) => async (dispatch) => {
  try {
    await pressDeskAPI.patch(`/users/resetPassword`, data);

    Notification("sweetAlert", {
      icon: "success",
      timer: 1500,
      showConfirmButton: false,
    });
  } catch (error) {
    // console.log(error);
    //   const err = String(error.response.data.message);

    Notification("sweetAlert", {
      icon: "error",
      title: "Error",
      text: "The token is invalid or has expired. Please, try again!",
    });
  }
};

export const isSignedInCheck = () => async (dispatch) => {
  try {
    const response = await pressDeskAPI.post("/users/isSignedIn");

    const userInfo = {
      id: response.data.user.id,
      role: response.data.user.role,
      name: response.data.user.name,
      authToken: response.data.token,
    };

    dispatch({ type: SIGN_IN, payload: userInfo });
  } catch (error) {
    // const err = String(error.response.data.error);
    // console.log(`Error message: ${err}`);
  }
};

/*
 * Public Films
 */

export const fetchFilmsFront = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_FILMS_FEATURED_STATUS, payload: null });
    const response = await pressDeskAPI.get("/films/featured");
    dispatch({ type: FETCH_FILMS_FEATURED_STATUS, payload: 200 });
    dispatch({ type: FETCH_FILMS_FEATURED, payload: response.data.films });
  } catch (error) {
    console.log(error.response.data.message);
    dispatch({
      type: FETCH_FILMS_FEATURED_STATUS,
      payload: error.response.status,
    });
  }
};

export const fetchFilms = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_FILMS_STATUS, payload: null });
    const response = await pressDeskAPI.get("/films");
    dispatch({ type: FETCH_FILMS_STATUS, payload: 200 });
    dispatch({ type: FETCH_FILMS, payload: response.data.films });
  } catch (error) {
    dispatch({ type: FETCH_FILMS_STATUS, payload: error.response.status });
  }
};

export const fetchFilm = (slug) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_FILM_STATUS, payload: null });
    const response = await pressDeskAPI.get(`/films/${slug}`);
    dispatch({ type: FETCH_FILM_STATUS, payload: 200 });
    dispatch({ type: FETCH_FILM, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_FILM_STATUS, payload: error.response.status });
  }
};

/*
 * Public Releases
 */

export const fetchReleasesFront = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_RELEASES_FEATURED_STATUS, payload: null });
    const response = await pressDeskAPI.get("/releases/featured");
    dispatch({ type: FETCH_RELEASES_FEATURED_STATUS, payload: 200 });
    dispatch({
      type: FETCH_RELEASES_FEATURED,
      payload: response.data.releases,
    });
  } catch (error) {
    dispatch({
      type: FETCH_RELEASES_FEATURED_STATUS,
      payload: error.response.status,
    });
  }
};

export const fetchReleases = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_RELEASES_STATUS, payload: null });
    const response = await pressDeskAPI.get("/releases/");
    dispatch({ type: FETCH_RELEASES_STATUS, payload: 200 });
    dispatch({
      type: FETCH_RELEASES,
      payload: response.data.releases,
    });
  } catch (error) {
    dispatch({
      type: FETCH_RELEASES_STATUS,
      payload: error.response.status,
    });
  }
};

export const fetchRelease = (id) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_RELEASE_STATUS, payload: null });
    const response = await pressDeskAPI.get(`/releases/${id}`);
    dispatch({ type: FETCH_RELEASE_STATUS, payload: 200 });
    dispatch({
      type: FETCH_RELEASE,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_RELEASE_STATUS,
      payload: error.response.status,
    });
  }
};

/*
 * ADMIN ACTION CREATORS
 */

//Dashboard
export const fetchDashboardAdmin = () => async (dispatch) => {
  try {
    const response = await pressDeskAPI.get("/admin/dashboard");
    // console.log(response.data);
    dispatch({ type: FETCH_DASHBOARD_ADMIN, payload: response.data });
  } catch (error) {
    console.error("There was an error fetching the dashboard data");
    console.log(error);
    Notification("sweetAlert", {
      icon: "error",
      title: "Error",
      text: error,
    });
  }
};

//Films
export const fetchFilmsAdmin = () => async (dispatch) => {
  try {
    const response = await pressDeskAPI.get(`/admin/films`);
    dispatch({ type: FETCH_FILMS_B, payload: response.data.films });
  } catch (error) {
    console.error("There was an error fetching the films");
    console.log(error);
    Notification("sweetAlert", {
      icon: "error",
      title: "Error",
      text: error,
    });
  }
};

export const fetchFilmAdmin = (id) => async (dispatch) => {
  try {
    const response = await pressDeskAPI.get(`/admin/films/${id}`);
    // console.log(response);
    dispatch({ type: FETCH_FILM_ADMIN, payload: response.data });
  } catch (error) {
    console.error("There was an error fetching the films");
    console.log(error);
    Notification("sweetAlert", {
      icon: "error",
      title: "Error",
      text: error,
    });
  }
};

export const changeFilmStatus = (id, status) => async (dispatch) => {
  try {
    const response = await pressDeskAPI.patch(
      `/admin/films/${id}`,
      { status },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    dispatch({ type: UPDATE_STATUS_FILM, payload: response.data });
  } catch (error) {
    console.error("There was an error changing the film status");
    console.log(error);
  }
};

export const createFilm = (data) => async () => {
  const dataFormatted = new FormData();
  dataFormatted.append("nameEn", data.titleEn);
  dataFormatted.append("nameEs", data.titleEs);
  dataFormatted.append("slug", data.slug);
  dataFormatted.append("year", data.year);
  dataFormatted.append("oneSheet", data.oneSheet[0]);

  try {
    await pressDeskAPI.post("/admin/films", dataFormatted, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    Notification("sweetAlert", {
      icon: "success",
      timer: 1500,
      showConfirmButton: false,
    });
  } catch (error) {
    const err = String(error.response.data.message);
    console.log(err);
    Notification("sweetAlert", {
      icon: "error",
      title: "Error",
      text: err,
    });
  }
};

export const updateFilm = (data) => async () => {
  const dataFormatted = new FormData();
  dataFormatted.append("nameEn", data.nameEn);
  dataFormatted.append("nameEs", data.nameEs);
  dataFormatted.append("year", data.year);
  dataFormatted.append("loglineEn", data.loglineEn);
  dataFormatted.append("loglineEs", data.loglineEs);
  dataFormatted.append("synopsisEn", data.synopsisEn);
  dataFormatted.append("synopsisEs", data.synopsisEs);
  dataFormatted.append("productionCompany", data.productionCompany);
  dataFormatted.append("distributor", data.distributor);
  dataFormatted.append("distributionStatus", data.distributionStatus);
  dataFormatted.append("releaseDate", data.releaseDate);
  dataFormatted.append("rating", data.rating);
  dataFormatted.append("country", data.country);
  dataFormatted.append("director", data.director);
  dataFormatted.append("writer", data.writer);
  dataFormatted.append("producer", data.producer);
  dataFormatted.append("cast", data.cast);

  if (data.oneSheet) {
    dataFormatted.append("oneSheet", data.oneSheet[0]);
  }

  try {
    await pressDeskAPI.patch(`/admin/films/${data.id}`, dataFormatted, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    Notification("sweetAlert", {
      icon: "success",
      timer: 1500,
      showConfirmButton: false,
    });
  } catch (error) {
    const err = String(error.response.data.message);
    console.log(err);
    Notification("sweetAlert", {
      icon: "error",
      title: "Error",
      text: err,
    });
  }
};

export const createFestivalLaurel = (data) => async (dispatch) => {
  const dataFormatted = new FormData();
  dataFormatted.append("festivalName", data.festivalName);
  if (data.order) {
    dataFormatted.append("order", data.order);
  }
  dataFormatted.append("laurel", data.laurel[0]);

  try {
    const response = await pressDeskAPI.post(
      `/admin/films/${data.filmId}/festivalLaurels`,
      dataFormatted,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    dispatch({
      type: CREATE_FILM_FRAGMENT,
      payload: response.data,
    });

    Notification("sweetAlert", {
      icon: "success",
      timer: 1500,
      showConfirmButton: false,
    });
  } catch (error) {
    const err = String(error.response.data.message);
    console.log(err);

    Notification("sweetAlert", {
      icon: "error",
      title: "Error",
      text: err,
    });
  }
};

export const updateFestivalLaurel = (data) => async (dispatch) => {
  try {
    await pressDeskAPI.patch(
      `/admin/films/${data.filmId}/festivalLaurels/${data.id}`,
      {
        festivalName: data.festivalName,
        order: data.order,
      }
    );

    Notification("sweetAlert", {
      icon: "success",
      timer: 1500,
      showConfirmButton: false,
    });
  } catch (error) {
    const err = String(error.response.data.message);
    console.log(err);

    Notification("sweetAlert", {
      icon: "error",
      title: "Error",
      text: err,
    });
  }
};

export const deleteFestivalLaurel = (data) => async (dispatch) => {
  try {
    const response = await pressDeskAPI.delete(
      `/admin/films/${data.filmId}/festivalLaurels/${data.id}`
    );

    Notification("sweetAlert", {
      icon: "success",
      timer: 1500,
      showConfirmButton: false,
    });
    dispatch({
      type: DELETE_FILM_FRAGMENT,
      payload: response.data,
    });
  } catch (error) {
    const err = String(error.response.data.message);
    console.log(err);

    Notification("sweetAlert", {
      icon: "error",
      title: "Error",
      text: err,
    });
  }
};

export const createFilmLink = (data) => async (dispatch) => {
  try {
    const response = await pressDeskAPI.post(
      `/admin/films/${data.filmId}/links`,
      {
        url: data.url,
        type: data.type,
      }
    );

    dispatch({
      type: CREATE_FILM_FRAGMENT,
      payload: response.data,
    });

    Notification("sweetAlert", {
      icon: "success",
      timer: 1500,
      showConfirmButton: false,
    });
  } catch (error) {
    const err = String(error.response.data.message);
    console.log(err);

    Notification("sweetAlert", {
      icon: "error",
      title: "Error",
      text: err,
    });
  }
};

export const updateFilmLink = (data) => async (dispatch) => {
  try {
    await pressDeskAPI.patch(`/admin/films/${data.filmId}/links/${data.id}`, {
      url: data.url,
      type: data.type,
    });

    Notification("sweetAlert", {
      icon: "success",
      timer: 1500,
      showConfirmButton: false,
    });
  } catch (error) {
    const err = String(error.response.data.message);
    console.log(err);

    Notification("sweetAlert", {
      icon: "error",
      title: "Error",
      text: err,
    });
  }
};

export const deleteFilmLink = (data) => async (dispatch) => {
  try {
    const response = await pressDeskAPI.delete(
      `/admin/films/${data.filmId}/links/${data.id}`
    );

    dispatch({
      type: DELETE_FILM_LINK,
      payload: response.data,
    });

    Notification("sweetAlert", {
      icon: "success",
      timer: 1500,
      showConfirmButton: false,
    });
  } catch (error) {
    const err = String(error.response.data.message);
    console.log(err);

    Notification("sweetAlert", {
      icon: "error",
      title: "Error",
      text: err,
    });
  }
};

export const createFilmFile = (data) => async (dispatch) => {
  const dataFormatted = new FormData();
  dataFormatted.append("lang", data.lang);
  dataFormatted.append("type", data.type);
  dataFormatted.append("file", data.filmFile[0]);

  try {
    const response = await pressDeskAPI.post(
      `/admin/films/${data.filmId}/files`,
      dataFormatted,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    dispatch({
      type: CREATE_FILM_FRAGMENT,
      payload: response.data,
    });

    Notification("sweetAlert", {
      icon: "success",
      timer: 1500,
      showConfirmButton: false,
    });
  } catch (error) {
    const err = String(error.response.data.message);
    console.log(err);

    Notification("sweetAlert", {
      icon: "error",
      title: "Error",
      text: err,
    });
  }
};

export const deleteFilmFile = (data) => async (dispatch) => {
  try {
    const response = await pressDeskAPI.delete(
      `/admin/films/${data.filmId}/files/${data.id}`
    );

    Notification("sweetAlert", {
      icon: "success",
      timer: 1500,
      showConfirmButton: false,
    });
    dispatch({
      type: DELETE_FILM_FRAGMENT,
      payload: response.data,
    });
  } catch (error) {
    const err = String(error.response.data.message);
    console.log(err);

    Notification("sweetAlert", {
      icon: "error",
      title: "Error",
      text: err,
    });
  }
};

export const createVideo = (data) => async (dispatch) => {
  try {
    const response = await pressDeskAPI.post(
      `/admin/films/${data.filmId}/videos`,
      data
    );

    dispatch({
      type: CREATE_FILM_FRAGMENT,
      payload: response.data,
    });

    Notification("sweetAlert", {
      icon: "success",
      timer: 1500,
      showConfirmButton: false,
    });
  } catch (error) {
    const err = String(error.response.data.message);
    console.log(err);

    Notification("sweetAlert", {
      icon: "error",
      title: "Error",
      text: err,
    });
  }
};

export const updateVideo = (data) => async (dispatch) => {
  try {
    await pressDeskAPI.patch(`/admin/films/${data.filmId}/videos/${data.id}`, {
      url: data.url,
      type: data.type,
      nameEn: data.nameEn,
      nameEs: data.nameEs,
    });

    Notification("sweetAlert", {
      icon: "success",
      timer: 1500,
      showConfirmButton: false,
    });
  } catch (error) {
    const err = String(error.response.data.message);
    console.log(err);

    Notification("sweetAlert", {
      icon: "error",
      title: "Error",
      text: err,
    });
  }
};

export const deleteVideo = (data) => async (dispatch) => {
  try {
    const response = await pressDeskAPI.delete(
      `/admin/films/${data.filmId}/videos/${data.id}`
    );

    Notification("sweetAlert", {
      icon: "success",
      timer: 1500,
      showConfirmButton: false,
    });
    dispatch({
      type: DELETE_FILM_FRAGMENT,
      payload: response.data,
    });
  } catch (error) {
    const err = String(error.response.data.message);
    console.log(err);

    Notification("sweetAlert", {
      icon: "error",
      title: "Error",
      text: err,
    });
  }
};

export const createImage = (data) => async (dispatch) => {
  if (data.nameEn === null) {
    data.nameEn = "";
  } else if (data.nameEs === null) {
    data.nameEs = "";
  } else if (data.copyrightEn === null) {
    data.copyrightEn = "";
  } else if (data.copyrightEs === null) {
    data.copyrightEs = "";
  }

  try {
    const dataFormatted = new FormData();

    dataFormatted.append("type", data.type);
    dataFormatted.append("nameEn", data.nameEn);
    dataFormatted.append("nameEs", data.nameEs);
    dataFormatted.append("copyrightEn", data.copyrightEn);
    dataFormatted.append("copyrightEs", data.copyrightEs);

    // const totalFilesToUpload = data.images.length;

    // for (let i = 0; i < totalFilesToUpload; i++) {
    dataFormatted.append(`image`, data.images[0]);
    // }
    const response = await pressDeskAPI.post(
      `/admin/films/${data.filmId}/images`,
      dataFormatted,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    dispatch({
      type: CREATE_FILM_FRAGMENT,
      payload: response.data,
    });

    Notification("sweetAlert", {
      icon: "success",
      timer: 1500,
      showConfirmButton: false,
    });
  } catch (error) {
    const err = String(error.response.data.message);
    console.log(err);

    Notification("sweetAlert", {
      icon: "error",
      title: "Error",
      text: err,
    });
  }
  // }
};

// const createImageLogic = (data) => async (dispatch) => {
//   const dataFormatted = new FormData();

//   dataFormatted.append("type", data.type);
//   dataFormatted.append("nameEn", data.nameEn);
//   dataFormatted.append("nameEs", data.nameEs);
//   dataFormatted.append("copyrightEn", data.copyrightEn);
//   dataFormatted.append("copyrightEs", data.copyrightEs);
//   dataFormatted.append("totalPayload", data.images.length);

//   const totalFilesToUpload = data.images.length;

//   dataFormatted.append("image", data.images[0]);

//   try {
//     const response = await pressDeskAPI.post(
//       `/admin/films/${data.filmId}/images`,
//       dataFormatted,
//       {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       }
//     );

//     dispatch({
//       type: CREATE_FILM_FRAGMENT,
//       payload: response.data.film,
//     });

//     Notification("sweetAlert", {
//       icon: "success",
//       timer: 1500,
//       showConfirmButton: false,
//     });
//   } catch (error) {
//     const err = String(error.response.data.message);
//     console.log(err);

//     Notification("sweetAlert", {
//       icon: "error",
//       title: "Error",
//       text: err,
//     });
//   }
// };

export const updateImage = (data) => async (dispatch) => {
  try {
    await pressDeskAPI.patch(`/admin/films/${data.filmId}/images/${data.id}`, {
      type: data.type,
      nameEn: data.nameEn,
      nameEs: data.nameEs,
      copyrightEn: data.copyrightEn,
      copyrightEs: data.copyrightEs,
    });

    Notification("sweetAlert", {
      icon: "success",
      timer: 1500,
      showConfirmButton: false,
    });
  } catch (error) {
    const err = String(error.response.data.message);
    console.log(err);

    Notification("sweetAlert", {
      icon: "error",
      title: "Error",
      text: err,
    });
  }
};

export const deleteImage = (data) => async (dispatch) => {
  try {
    const response = await pressDeskAPI.delete(
      `/admin/films/${data.filmId}/images/${data.id}`
    );

    Notification("sweetAlert", {
      icon: "success",
      timer: 1500,
      showConfirmButton: false,
    });
    dispatch({
      type: DELETE_FILM_FRAGMENT,
      payload: response.data,
    });
  } catch (error) {
    const err = String(error.response.data.message);
    console.log(err);

    Notification("sweetAlert", {
      icon: "error",
      title: "Error",
      text: err,
    });
  }
};

//Press Releases

export const createRelease = (data) => async (dispatch) => {
  const dataFormatted = new FormData();
  dataFormatted.append("titleEn", data.titleEn);
  dataFormatted.append("titleEs", data.titleEs);
  dataFormatted.append("status", data.status);
  dataFormatted.append("date", data.date);
  dataFormatted.append("category", data.category);
  dataFormatted.append("mediaContactsEn", data.mediaContactsEn);
  dataFormatted.append("mediaContactsEs", data.mediaContactsEs);
  dataFormatted.append("contentEn", data.contentEn);
  dataFormatted.append("contentEs", data.contentEs);
  dataFormatted.append("introContentEn", data.introContentEn);
  dataFormatted.append("introContentEs", data.introContentEs);
  dataFormatted.append("image", data.image[0]);

  await pressDeskAPI
    .post("/admin/releases", dataFormatted, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(function (response) {
      console.log(response);

      Notification("sweetAlert", {
        icon: "success",
        timer: 1500,
        showConfirmButton: false,
      });
    })
    .catch(function (error) {
      const err = String(error.response.data.message);
      console.log(err);

      Notification("sweetAlert", {
        icon: "error",
        title: "Error",
        text: err,
      });
      return;
    });
};

export const fetchReleasesAdmin = () => async (dispatch) => {
  try {
    const response = await pressDeskAPI.get("/admin/releases/");
    // console.log(response);
    dispatch({
      type: FETCH_RELEASES_B,
      payload: response.data.releases,
    });
  } catch (error) {
    console.error("There was an error fetching the press releases");
    console.log(error);

    Notification("sweetAlert", {
      icon: "error",
      title: "Error",
      text: error,
    });
  }
};

export const fetchReleaseAdmin = (id) => async (dispatch) => {
  try {
    const response = await pressDeskAPI.get(`/admin/releases/${id}`);
    dispatch({
      type: FETCH_RELEASE_ADMIN,
      payload: response.data,
    });
  } catch (error) {
    console.error("There was an error fetching the press release");
    console.log(error);

    Notification("sweetAlert", {
      icon: "error",
      title: "Error",
      text: error,
    });
  }
};

export const editRelease = (data) => async (dispatch) => {
  const dataFormatted = new FormData();
  dataFormatted.append("titleEn", data.titleEn);
  dataFormatted.append("titleEs", data.titleEs);
  dataFormatted.append("status", data.status);
  dataFormatted.append("date", data.date);
  dataFormatted.append("category", data.category);
  dataFormatted.append("mediaContactsEn", data.mediaContactsEn);
  dataFormatted.append("mediaContactsEs", data.mediaContactsEs);
  dataFormatted.append("contentEn", data.contentEn);
  dataFormatted.append("contentEs", data.contentEs);
  dataFormatted.append("introContentEn", data.introContentEn);
  dataFormatted.append("introContentEs", data.introContentEs);
  if (data.image) {
    dataFormatted.append("image", data.image[0]);
  }

  await pressDeskAPI
    .patch(`/admin/releases/${data.id}`, dataFormatted, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(function (response) {
      Notification("sweetAlert", {
        icon: "success",
        timer: 1500,
        showConfirmButton: false,
      });
    })
    .catch(function (error) {
      const err = String(error.response.data.message);
      console.log(err);

      Notification("sweetAlert", {
        icon: "error",
        title: "Error",
        text: err,
      });
      return;
    });
};

export const changeReleaseStatus = (data) => async (dispatch) => {
  try {
    const response = await pressDeskAPI.patch(`/admin/releases/${data.id}`, {
      status: data.status,
    });

    // console.log(response);

    Notification("sweetAlert", {
      icon: "success",
      timer: 1500,
      showConfirmButton: false,
    });

    dispatch({ type: EDIT_RELEASE, payload: response.data });
  } catch (error) {
    const err = String(error.response.data.message);
    console.log(err);

    Notification("sweetAlert", {
      icon: "error",
      title: "Error",
      text: err,
    });
  }
};

export const deleteRelease = (id) => async (dispatch) => {
  try {
    const response = await pressDeskAPI.delete(`/admin/releases/${id}`);
    // console.log(response);

    Notification("sweetAlert", {
      icon: "success",
      title: "Deleted!",
      text: "The press release has been deleted.",
    });

    dispatch({
      type: DELETE_RELEASE,
      payload: id,
    });
  } catch (error) {
    console.error("There was an error deleting the press release");
    console.log(error);

    Notification("sweetAlert", {
      icon: "error",
      title: "Error",
      text: error,
    });
  }
};

//Users

export const fetchUsers = () => async (dispatch) => {
  try {
    const response = await pressDeskAPI.get(`/admin/users`);
    dispatch({ type: FETCH_USERS_ADMIN, payload: response.data.users });
  } catch (error) {
    console.error("There was an error fetching the users");
    console.log(error);

    Notification("sweetAlert", {
      icon: "error",
      title: "Error",
      text: error,
    });
  }
};

export const changeUserRole = (data) => async (dispatch) => {
  try {
    const response = await pressDeskAPI.patch(`/admin/users/${data.id}`, {
      role: data.role,
    });

    console.log(response);
    Notification("sweetAlert", {
      icon: "success",
      timer: 1500,
      showConfirmButton: false,
    });

    // const newData = [
    //   {
    //     id: data.id,
    //     role: data.role,
    //   },
    // ];
    dispatch({ type: UPDATE_STATUS_USER, payload: response.data.user });
  } catch (error) {
    const err = String(error.response.data.message);
    console.log(err);

    Notification("sweetAlert", {
      icon: "error",
      title: "Error",
      text: err,
    });
  }
};
