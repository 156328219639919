import React from "react";

const ShowUsersContentSkeleton = ({ filter }) => {
  return (
    <>
      <tr class="placeholder-glow" key="placeholderRow_1">
        <th scope="row">
          <div class="media align-items-center">
            <div></div>
            <div className="media-body">
              <span className="name h6 mb-0 text-sm placeholder col-12 placeholder-lg"></span>
              <small className="d-block">
                <span className="placeholder col-6"></span>
              </small>
            </div>
          </div>
        </th>
        <td>
          <span className="placeholder col-12 placeholder-lg"></span>
        </td>
        <td>
          <span className="placeholder col-12 placeholder-lg"></span>
        </td>
        <td>
          <div>
            <span className="btn btn-sm btn-dark placeholder col-12 placeholder-lg"></span>
          </div>
        </td>
        <td className="text-right">
          <div className="actions ml-3">
            <div className="action-item text-warning mr-2">
              <span className="placeholder col-12 card-img-top card-img-bottom"></span>
            </div>
            <div className="action-item text-danger mr-2">
              <span className="placeholder col-12 card-img-top card-img-bottom"></span>
            </div>
          </div>
        </td>
      </tr>
      <tr class="placeholder-glow" key="placeholderRow_2">
        <th scope="row">
          <div class="media align-items-center">
            <div></div>
            <div className="media-body">
              <span className="name h6 mb-0 text-sm placeholder col-12 placeholder-lg"></span>
              <small className="d-block">
                <span className="placeholder col-6"></span>
              </small>
            </div>
          </div>
        </th>
        <td>
          <span className="placeholder col-6 placeholder-lg"></span>
        </td>
        <td>
          <span className="placeholder col-9 placeholder-lg"></span>
        </td>
        <td>
          <div>
            <span className="btn btn-sm btn-dark placeholder col-12 placeholder-lg"></span>
          </div>
        </td>
        <td className="text-right">
          <div className="actions ml-3">
            <div className="action-item text-warning mr-2">
              <span className="placeholder col-12 card-img-top card-img-bottom"></span>
            </div>
            <div className="action-item text-danger mr-2">
              <span className="placeholder col-12 card-img-top card-img-bottom"></span>
            </div>
          </div>
        </td>
      </tr>
      <tr class="placeholder-glow" key="placeholderRow_3">
        <th scope="row">
          <div class="media align-items-center">
            <div></div>
            <div className="media-body">
              <span className="name h6 mb-0 text-sm placeholder col-12 placeholder-lg"></span>
              <small className="d-block">
                <span className="placeholder col-6"></span>
              </small>
            </div>
          </div>
        </th>
        <td>
          <span className="placeholder col-10 placeholder-lg"></span>
        </td>
        <td>
          <span className="placeholder col-11 placeholder-lg"></span>
        </td>
        <td>
          <div>
            <span className="btn btn-sm btn-dark placeholder col-12 placeholder-lg"></span>
          </div>
        </td>
        <td className="text-right">
          <div className="actions ml-3">
            <div className="action-item text-warning mr-2">
              <span className="placeholder col-12 card-img-top card-img-bottom"></span>
            </div>
            <div className="action-item text-danger mr-2">
              <span className="placeholder col-12 card-img-top card-img-bottom"></span>
            </div>
          </div>
        </td>
      </tr>
    </>
  );
};

export default ShowUsersContentSkeleton;
