import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import authReducer from "./authReducer";
import filmsReducer from "./filmsReducer";
import filmsFeaturedReducer from "./filmsFeaturedReducer";
import individualFilmReducer from "./individualFilmReducer";
import fetchStatus from "./fetchStatusReducer";
import bFilmsReducer from "./bFilmsReducer";
import releasesFeaturedReducer from "./releasesFeaturedReducer";
import releasesReducer from "./releasesReducer";
import individualReleaseReducer from "./individualReleaseReducer";
import bReleasesReducer from "./bReleasesReducer";
import bViewReducer from "./bViewReducer";
import bUsersReducer from "./bUsersReducer";

export default combineReducers({
  form: formReducer,
  auth: authReducer,
  fetchStatus: fetchStatus,
  filmsFeatured: filmsFeaturedReducer,
  films: filmsReducer,
  filmView: individualFilmReducer,
  releasesFeatured: releasesFeaturedReducer,
  releases: releasesReducer,
  releaseView: individualReleaseReducer,
  bView: bViewReducer,
  bFilms: bFilmsReducer,
  bReleases: bReleasesReducer,
  bUsers: bUsersReducer,
});
