import React from "react";
import { useTranslation } from "react-i18next";

const DropdownLinks = ({ links }) => {
  let { t } = useTranslation();
  let title;
  return links.map((link) => {
    if (link.lang === "en") {
      title = t("filmPage.english");
    } else if (link.lang === "es") {
      title = t("filmPage.spanish");
    }
    return (
      <a
        className="dropdown-item"
        href={link.url}
        target="_blank"
        key={link.id}
        rel="noopener noreferrer"
      >
        {title}
      </a>
    );
  });
};

export default DropdownLinks;
