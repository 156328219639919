import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { connect } from "react-redux";

import MainContainer from "./public/MainContainer";
import Error404 from "./Error404";
import Error500 from "./Error500";
import Terms from "./Terms";
import MainPage from "./public/home/MainPage";
import AllFilms from "./public/films/AllFilms";
import IndividualFilm from "./public/films/IndividualFilm";
import Login from "./authentication/Login";
import SignUp from "./authentication/SignUp";
import ForgotPassword from "./authentication/ForgotPassword";
import ShowFilms from "./admin/films/ShowFilms";
import FilmCreate from "./admin/films/FilmCreate";
import FilmEdit from "./admin/films/FilmEdit";
import AdminUserCreate from "./admin/users/AdminUserCreate";
import ShowUsers from "./admin/users/ShowUsers";
import UserEdit from "./admin/users/UserEdit";
import MainDash from "./admin/dashboard/MainDash";
import AdminMainContainer from "./admin/AdminMainContainer";
import AllReleases from "./public/releases/AllReleases";
import IndividualRelease from "./public/releases/IndividualRelease";
import ReleaseCreate from "./admin/releases/ReleaseCreate";
import ShowReleases from "./admin/releases/ShowReleases";
import ReleaseEdit from "./admin/releases/ReleaseEdit";
//
import { isSignedInCheck } from "../actions";
import ResetPassword from "./authentication/ResetPassword";

const App = ({ isSignedInCheck }) => {
  useEffect(() => {
    isSignedInCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <BrowserRouter>
        <div>
          <Routes>
            <Route path="/" exact element={<MainContainer />}>
              <Route index element={<MainPage />} />
              <Route path="films" exact element={<AllFilms />} />
              <Route path="films/:slug" exact element={<IndividualFilm />} />
              <Route path="releases" exact element={<AllReleases />} />
              <Route
                path="releases/:id"
                exact
                element={<IndividualRelease />}
              />
              <Route path="terms" exact element={<Terms />} />
            </Route>
            <Route path="/login" exact element={<Login />} />
            <Route path="/request" exact element={<SignUp />} />
            <Route path="/forgot_password" exact element={<ForgotPassword />} />
            <Route
              path="/forgot_password/:token"
              exact
              element={<ResetPassword />}
            />
            <Route path="/admin" exact element={<AdminMainContainer />}>
              <Route index exact element={<MainDash />} />

              <Route path="films" exact element={<ShowFilms />} />
              <Route path="films/create" exact element={<FilmCreate />} />
              <Route path="films/:id" exact element={<FilmEdit />} />
              <Route path="users" exact element={<ShowUsers />} />
              <Route path="users/create" exact element={<AdminUserCreate />} />
              <Route path="users/:id" exact element={<UserEdit />} />
              <Route path="releases" exact element={<ShowReleases />} />
              <Route path="releases/create" exact element={<ReleaseCreate />} />
              <Route path="releases/:id" exact element={<ReleaseEdit />} />
            </Route>
            <Route path="500" element={<Error500 />} />;
            <Route path="*" element={<Error404 />} />;
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { isSignedIn: state.auth.isSignedIn };
};

export default connect(mapStateToProps, {
  isSignedInCheck,
})(App);
