import React from "react";
import { RichUtils } from "draft-js";
import { Bold, Italic, Underline } from "react-feather";

//Handle Rich Styling buttons
const onToggleBold = (onChangeElement, stateElement) => {
  onChangeElement(RichUtils.toggleInlineStyle(stateElement, "BOLD"));
};
const onToggleItalic = (onChangeElement, stateElement) => {
  onChangeElement(RichUtils.toggleInlineStyle(stateElement, "ITALIC"));
};
const onToggleUnderline = (onChangeElement, stateElement) => {
  onChangeElement(RichUtils.toggleInlineStyle(stateElement, "UNDERLINE"));
};
// const onToggleLink = (onChangeElement, stateElement) => {
//   onChangeElement(RichUtils.toggleLink(stateElement, "https://url.to.go/"));
// };

const StyleBar = ({ onChangeElement, stateElement }) => {
  return (
    <>
      <div className="pb-3">
        <button
          type="button"
          className="btn btn-secondary btn-sm"
          onClick={() => onToggleBold(onChangeElement, stateElement)}
        >
          <Bold />
        </button>
        <button
          type="button"
          className="btn btn-secondary btn-sm"
          onClick={() => onToggleItalic(onChangeElement, stateElement)}
        >
          <Italic />
        </button>
        <button
          type="button"
          className="btn btn-secondary btn-sm"
          onClick={() => onToggleUnderline(onChangeElement, stateElement)}
        >
          <Underline />
        </button>
        {/* <button
            type="button"
            className="btn btn-secondary btn-sm"
            // onClick={() => onToggleLink(onChangeElement, stateElement)}
          >
            <Link />
          </button> */}
      </div>
    </>
  );
};

export default StyleBar;
