import {
  FETCH_FILM_ADMIN,
  FETCH_RELEASE_ADMIN,
  CREATE_FILM_FRAGMENT,
  DELETE_FILM_LINK,
  FETCH_DASHBOARD_ADMIN,
  DELETE_FILM_FRAGMENT,
} from "../actions/types";

const INITIAL_STATE = {
  dashboard: null,
  film: null,
  release: null,
  user: null,
};

const bViewReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_FILM_ADMIN:
      return { ...state, film: action.payload };
    case CREATE_FILM_FRAGMENT:
      return { ...state, film: action.payload };
    case DELETE_FILM_LINK:
      return { ...state, film: action.payload };
    case DELETE_FILM_FRAGMENT:
      return { ...state, film: action.payload };
    case FETCH_RELEASE_ADMIN:
      return { ...state, release: action.payload };
    case FETCH_DASHBOARD_ADMIN:
      return { ...state, dashboard: action.payload };
    default:
      return state;
  }
};

export default bViewReducer;
