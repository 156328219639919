import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import ImageComponent from "./../../public/films/individualFilm/ImageComponent";
import { changeFilmStatus } from "../../../actions";

const IndividualFilmSnippet = ({ data, changeFilmStatus }) => {
  const triggerStatusChange = (id, status) => {
    changeFilmStatus(id, status);
  };

  const showButtons = () => {
    switch (data.status) {
      case "public":
        return (
          <>
            <div className="col-6">
              <Link
                to={`${data.id}`}
                className="btn btn-xs btn-primary rounded-pill"
              >
                <span className="btn-inner--text">Edit</span>
              </Link>
            </div>

            <div className="col-6">
              <span
                onClick={() => triggerStatusChange(data.id, "private")}
                className="btn btn-xs btn-light text-dark rounded-pill"
              >
                <span className="btn-inner--text">Private</span>
              </span>
            </div>

            <div className="col-12">
              <span
                onClick={() => triggerStatusChange(data.id, "archive")}
                className="btn btn-xs btn-warning text-dark rounded-pill"
              >
                <span className="btn-inner--text">Archive</span>
              </span>
            </div>
          </>
        );
      case "private":
        return (
          <>
            <div className="col-6">
              <Link
                to={`${data.id}`}
                className="btn btn-xs btn-primary rounded-pill"
              >
                <span className="btn-inner--text">Edit</span>
              </Link>
            </div>

            <div className="col-6">
              <span
                onClick={() => triggerStatusChange(data.id, "public")}
                className="btn btn-xs btn-warning text-dark rounded-pill"
              >
                <span className="btn-inner--text">Publish</span>
              </span>
            </div>

            <div className="col-12">
              <span
                onClick={() => triggerStatusChange(data.id, "archive")}
                className="btn btn-xs btn-warning text-dark rounded-pill"
              >
                <span className="btn-inner--text">Archive</span>
              </span>
            </div>
          </>
        );
      case "draft":
        return (
          <>
            <div className="col-6">
              <Link
                to={`${data.id}`}
                className="btn btn-xs btn-primary rounded-pill"
              >
                <span className="btn-inner--text">Edit</span>
              </Link>
            </div>

            <div className="col-6">
              <span
                onClick={() => triggerStatusChange(data.id, "private")}
                className="btn btn-xs btn-light text-dark rounded-pill"
              >
                <span className="btn-inner--text">Private</span>
              </span>
            </div>

            <div className="col-12">
              <span
                onClick={() => triggerStatusChange(data.id, "public")}
                className="btn btn-xs btn-warning text-dark rounded-pill"
              >
                <span className="btn-inner--text">Publish</span>
              </span>
            </div>
          </>
        );
      case "archive":
        return (
          <>
            <div className="col-6">
              <Link
                to={`${data.id}`}
                className="btn btn-xs btn-primary rounded-pill"
              >
                <span className="btn-inner--text">Edit</span>
              </Link>
            </div>

            <div className="col-6">
              <span
                onClick={() => triggerStatusChange(data.id, "private")}
                className="btn btn-xs btn-light text-dark rounded-pill"
              >
                <span className="btn-inner--text">Private</span>
              </span>
            </div>

            <div className="col-12">
              <span
                onClick={() => triggerStatusChange(data.id, "public")}
                className="btn btn-xs btn-warning text-dark rounded-pill"
              >
                <span className="btn-inner--text">Publish</span>
              </span>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="col-xl-4 col-md-6">
      <div className="card hover-translate-y-n3 hover-shadow-lg overflow-hidden">
        <div className="position-relative overflow-hidden">
          <Link to={`/films/${data.slug}`} target="_blank" className="d-block">
            <ImageComponent
              placeholderHeight="480px"
              alt={`${data.nameEn} (${data.year})`}
              src={data.oneSheet}
              className="card-img-top"
            />
          </Link>
        </div>
        <div className="card-body py-4 text-center">
          <Link to={`/${data.slug}`} target="_blank" className="h5 lh-150">
            {data.nameEn}
          </Link>
          <small className="d-block text-sm mb-2">({data.year})</small>

          <div className="card-footer py-2">
            <div className="row align-items-center">{showButtons()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { changeFilmStatus })(IndividualFilmSnippet);
