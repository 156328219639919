import React from "react";
// import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { X } from "react-feather";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { logOut } from "../actions";

const lngs = {
  en: { id: 0, nativeName: "English", flag: "US" },
  es: { id: 1, nativeName: "Español", flag: "ES" },
};

const lastLang = Object.keys(lngs).length - 1;

const Navigation = (props) => {
  // const [show, setShow] = useState(true);
  const { t, i18n } = useTranslation();

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  const logOutScript = () => {
    props.logOut();
  };

  const loginRender = () => {
    // console.log(`Signed status: ${props.isSignedIn}`);
    if (!props.isSignedIn) {
      return (
        <Link className="nav-link" to="/login" key="loginNavItem1">
          {t("navigation.signIn")}
        </Link>
      );
    } else {
      return (
        <>
          <li
            key="greetingNavItem"
            className="nav-item nav-item-spaced dropdown dropdown-animate"
            data-toggle="hover"
          >
            <a
              className="nav-link"
              href="#"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {t("navigation.hello") + props.userName}
            </a>
            <div className="dropdown-menu dropdown-menu-md p-0">
              <ul className="list-group list-group-flush px-lg-4">
                <li key="logOutNavItem">
                  <div className="d-lg-block py-3 bg-transparent">
                    {props.userRole === "admin" ? (
                      <Link className="dropdown-item" to="/admin">
                        {t("navigation.dashboard")}
                      </Link>
                    ) : undefined}
                    <Link
                      className="dropdown-item"
                      onClick={logOutScript}
                      to={logOutScript}
                    >
                      {t("navigation.signOut")}
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
          </li>
        </>
      );
    }
  };
  return (
    <>
      {/* <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        // className="modal-dialog modal-dialog-aside left-4 right-4 bottom-4"
        centered
        size="lg"
        // aria-labelledby="modal-cookies"
        // id="modal-cookies"
      >
        <Modal.Body className=".bg-dark-dark">
          <p className=".text-sm .text-white .mb-3 text-center">
            {t("navigation.cookiesDisclaimer")}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Link to="/terms" className="btn btn-sm btn-white" target="_blank">
            {t("navigation.learnMore")}
          </Link>
          <button
            type="button"
            className="btn btn-sm btn-primary mr-2"
            onClick={handleClose}
          >
            OK
          </button>

           <Button variant="primary" onClick={handleClose}>
            OK
          </Button> 
        </Modal.Footer>
      </Modal> */}
      {/* <div
        className="modal fade"
        tabIndex="-1"
        role="dialog"
        id="modal-cookies"
        data-backdrop="false"
        aria-labelledby="modal-cookies"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-aside left-4 right-4 bottom-4">
          <div className="modal-content bg-dark-dark">
            <div className="modal-body">
              
              <p className="text-sm text-white mb-3">
                {t("navigation.cookiesDisclaimer")}
              </p>
              
              <Link
                to="/terms"
                className="btn btn-sm btn-white"
                target="_blank"
              >
                {t("navigation.learnMore")}
              </Link>
              <button
                type="button"
                className="btn btn-sm btn-primary mr-2"
                data-dismiss="modal"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div> */}
      <header id="header-main">
        {/* Navbar */}
        <nav
          className="navbar navbar-main navbar-expand-lg navbar-dark bg-primary"
          id="navbar-main"
        >
          <div className="container">
            {/* Brand */}
            <Link className="navbar-brand" to="/">
              <img
                alt="Black Letter Films Press"
                src="/assets/img/logo_blf_white.svg"
                id="navbar-logo"
              />
            </Link>
            {/* Toggler */}
            <button
              className="navbar-toggler"
              type="button"
              id="buttonToggleCollapse"
              data-toggle="collapse"
              data-target="#navbar-main-collapse"
              aria-controls="navbar-main-collapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            {/* Collapse */}
            <div
              className="collapse navbar-collapse navbar-collapse-overlay"
              id="navbar-main-collapse"
            >
              {/* Toggler */}
              <div className="position-relative">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbar-main-collapse"
                  aria-controls="navbar-main-collapse"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <X />
                </button>
              </div>
              {/* Main navigation */}
              <ul className="navbar-nav ml-lg-auto">
                {/* Home */}
                <li
                  key="homeNavItem"
                  className="nav-item nav-item-spaced d-lg-block"
                >
                  <Link className="nav-link" to="/">
                    {t("navigation.home")}
                  </Link>
                </li>
                {/* Films */}
                <li
                  key="filmsNavItem"
                  className="nav-item nav-item-spaced d-lg-block"
                >
                  <Link className="nav-link" to="/films">
                    {t("navigation.films")}
                  </Link>
                </li>
                <li
                  key="releasesNavItem"
                  className="nav-item nav-item-spaced d-lg-block"
                >
                  <Link className="nav-link" to="/releases">
                    {t("navigation.releases")}
                  </Link>
                </li>
              </ul>

              {/* Right navigation */}
              <ul className="navbar-nav align-items-lg-center d-lg-flex ml-lg-auto">
                {/* Auth */}

                <li key="loginNavItem" className="nav-item">
                  {loginRender()}
                </li>

                {/* Desktop language switcher */}
                <li
                  key="languageSwitcherDesktop"
                  className="nav-item d-none d-lg-block d-xl-block"
                >
                  {Object.keys(lngs).map((lng) => (
                    <a
                      key={lng}
                      onClick={() => {
                        i18n.changeLanguage(lng);
                      }}
                    >
                      <ReactCountryFlag countryCode={lngs[lng].flag} />
                      &nbsp; &nbsp;
                    </a>
                  ))}
                </li>
                {/* Mobile language switcher */}
                <li
                  className="nav-item d-md-block d-lg-none text-center"
                  key="mobileLanguageSwitcher"
                >
                  <span className="nav-link">
                    {Object.keys(lngs).map((lng) => (
                      <span key={`${lngs[lng].nativeName}MobileNavParent`}>
                        <span
                          key={`${lngs[lng].nativeName}MobileNav`}
                          style={{
                            fontWeight:
                              i18n.language === lng ? "bold" : "normal",
                          }}
                          onClick={() => {
                            i18n.changeLanguage(lng);
                          }}
                        >
                          {lngs[lng].nativeName}
                        </span>
                        {` ${lngs[lng].id === lastLang ? "" : " | "}`}
                      </span>
                    ))}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    userName: state.auth.userName,
    userRole: state.auth.userRole,
  };
};

export default connect(mapStateToProps, { logOut })(Navigation);
