const validate = (formValues) => {
  const errors = {};

  if (!formValues.titleEn) {
    //English title is empty
    errors.titleEn = "The English title can't be empty!";
  }

  if (!formValues.titleEs) {
    //Spanish title is empty
    errors.titleEs = "The Spanish title can't be empty!";
  }

  if (!formValues.slug) {
    //Slug is empty
    errors.slug = "The slug can't be empty!";
  }

  if (!formValues.year) {
    //Year is empty
    errors.year = "The year can't be empty!";
  }

  return errors;
};

export default validate;
