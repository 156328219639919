import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";

import AdminPageTitle from "../../AdminPageTitle";
import RenderInput from "../../_utils/RenderInput";
import RenderTextArea from "../../_utils/RenderTextArea";
import RenderUpload from "../../_utils/RenderUpload";
import RenderDropdown from "../../_utils/RenderDropdown";
import ImageComponent from "../../../public/films/individualFilm/ImageComponent";
import FestivalLaurels from "./festivalLaurels/FestivalLaurels";
import FilmFiles from "./filmFiles/FilmFiles";
import FilmLinks from "./filmLinks/FilmLinks";
import UploadFilmMedia from "./uploadFilmMedia/UploadFilmMedia";
import VideosTable from "./videosTable/VideosTable";
import ImagesGallery from "./imagesGallery/ImagesGallery";
import validate from "./validate";
import { updateFilm } from "../../../../actions";

let reduxFormValues = {
  cast: null,
  country: null,
  director: null,
  distributionStatus: null,
  distributor: null,
  loglineEn: null,
  loglineEs: null,
  nameEn: null,
  nameEs: null,
  producer: null,
  productionCompany: null,
  rating: null,
  releaseDate: null,
  slug: null,
  status: null,
  synopsisEn: null,
  synopsisEs: null,
  writer: null,
  year: null,
};

const FilmEditContent = ({ film, handleSubmit, submitting, updateFilm }) => {
  const [isSubmittingChange, setIsSubmittingChange] = useState(false);
  //Populate the field values
  reduxFormValues.cast = film.cast;
  reduxFormValues.country = film.country;
  reduxFormValues.director = film.director;
  reduxFormValues.distributionStatus = film.distributionStatus;
  reduxFormValues.distributor = film.distributor;
  reduxFormValues.loglineEn = film.loglineEn;
  reduxFormValues.loglineEs = film.loglineEs;
  reduxFormValues.nameEn = film.nameEn;
  reduxFormValues.nameEs = film.nameEs;
  reduxFormValues.producer = film.producer;
  reduxFormValues.productionCompany = film.productionCompany;
  reduxFormValues.rating = film.rating;
  reduxFormValues.slug = film.slug;
  reduxFormValues.synopsisEn = film.synopsisEn;
  reduxFormValues.synopsisEs = film.synopsisEs;
  reduxFormValues.writer = film.writer;
  reduxFormValues.year = film.year;

  let formDate = new Date(film.releaseDate);
  const dd = String(formDate.getDate()).padStart(2, "0");
  const mm = String(formDate.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = formDate.getFullYear();
  formDate = yyyy + "-" + mm + "-" + dd;

  reduxFormValues.releaseDate = formDate;

  const onSubmit = async (data) => {
    data.id = film.id;
    setIsSubmittingChange(true);
    await updateFilm(data);
    setIsSubmittingChange(false);
  };

  return (
    <>
      <AdminPageTitle>Edit {film.nameEn}</AdminPageTitle>
      <section className="slice slice-sm">
        <div className="container">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row justify-content-center">
              <div className="col-lg-9">
                <div>
                  {/* <!-- Content --> */}
                  <div className="row">
                    <div className="col-lg-12">
                      <div>
                        <h5 className="mb-3">Basic Information</h5>
                        <div className="row">
                          <div className="col-md-6">
                            <Field
                              name="nameEn"
                              component={RenderInput}
                              label="Film Title (English)"
                              placeholder="Enter the film title..."
                              customClass=""
                              fieldType="text"
                            />
                          </div>
                          <div className="col-md-6">
                            <Field
                              name="nameEs"
                              component={RenderInput}
                              label="Film Title (Spanish)"
                              placeholder="Escribe el título del proyecto..."
                              customClass=""
                              fieldType="text"
                            />
                          </div>
                        </div>
                        <div className="row align-items-center">
                          <div className="col-6">
                            <div className="form-group">
                              <label className="form-control-label">Slug</label>
                              <input
                                type="text"
                                name="filmSlug"
                                id="filmSlug"
                                className="form-control"
                                value={`/${film.slug}`}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="col-6">
                            <Field
                              name="year"
                              component={RenderInput}
                              label="Year"
                              placeholder="Release year or ???? if unknown..."
                              customClass=""
                              fieldType="text"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group">
                              <label className="form-control-label">
                                One-Sheet
                              </label>
                              <br />
                              <div className="row">
                                <div className="col-md-2 col-3">
                                  <ImageComponent
                                    id="oneSheetPreview"
                                    src={film.oneSheet}
                                    width="100%"
                                    placeholderHeight="150px"
                                  />
                                </div>
                                <div className="col-md-10 col-9">
                                  <Field
                                    name="oneSheet"
                                    component={RenderUpload}
                                    label="Upload a new One-Sheet"
                                    placeholder="Select a file..."
                                    customClass=""
                                    accept=".png,.jpg,.jpeg"
                                    notes="File size limit: 25MB"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br />
                      <div>
                        <h5 className="mb-3">Film Details</h5>
                        <div className="row">
                          <div className="col-md-6">
                            <Field
                              name="loglineEn"
                              component={RenderTextArea}
                              label="Logline (English)"
                              placeholder="A thief who steals corporate secrets through the use of dream-sharing technology is given the inverse task of planting an idea into the mind of a C.E.O."
                              customClass=""
                              fieldType="text"
                            />
                          </div>
                          <div className="col-md-6">
                            <Field
                              name="loglineEs"
                              component={RenderTextArea}
                              label="Logline (Spanish)"
                              placeholder="A thief who steals corporate secrets through the use of dream-sharing technology is given the inverse task of planting an idea into the mind of a C.E.O."
                              customClass=""
                              fieldType="text"
                            />
                          </div>
                          <div className="col-12">
                            <Field
                              name="synopsisEn"
                              component={RenderTextArea}
                              label="Synopsis (English)"
                              placeholder="Dom Cobb (Leonardo DiCaprio) is a thief with the rare ability to enter people's dreams and steal their secrets from their subconscious. His skill has made him a hot commodity in the world of corporate espionage but has also cost him everything he loves. Cobb gets a chance at redemption when he is offered a seemingly impossible task: Plant an idea in someone's mind. If he succeeds, it will be the perfect crime, but a dangerous enemy anticipates Cobb's every move."
                              customClass=""
                              fieldType="text"
                            />
                          </div>
                          <div className="col-12">
                            <Field
                              name="synopsisEs"
                              component={RenderTextArea}
                              label="Synopsis (Spanish)"
                              placeholder="Dom Cobb (Leonardo DiCaprio) is a thief with the rare ability to enter people's dreams and steal their secrets from their subconscious. His skill has made him a hot commodity in the world of corporate espionage but has also cost him everything he loves. Cobb gets a chance at redemption when he is offered a seemingly impossible task: Plant an idea in someone's mind. If he succeeds, it will be the perfect crime, but a dangerous enemy anticipates Cobb's every move."
                              customClass=""
                              fieldType="text"
                            />
                          </div>
                          <div className="col-6">
                            <Field
                              name="productionCompany"
                              component={RenderInput}
                              label="Production Company"
                              placeholder="Syncopy..."
                              customClass=""
                              fieldType="text"
                            />
                          </div>
                          <div className="col-6">
                            <Field
                              name="distributor"
                              component={RenderInput}
                              label="Distributor"
                              placeholder="Warner Bros..."
                              customClass=""
                              fieldType="text"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-3 col-6">
                            <Field
                              name="distributionStatus"
                              label="Status"
                              defaultOption="Select..."
                              component={RenderDropdown}
                            >
                              <option value="comingSoon" key="comingSoon">
                                Coming Soon
                              </option>
                              <option value="nowPlaying" key="nowPlaying">
                                Now Playing
                              </option>
                              <option
                                value="nowAvailableOnDemand"
                                key="nowAvailableOnDemand"
                              >
                                Now Available On Demand
                              </option>
                            </Field>
                          </div>
                          <div className="col-xl-4 col-6">
                            <Field
                              name="releaseDate"
                              component={RenderInput}
                              label="Release Date"
                              placeholder="Select date..."
                              customClass=""
                              fieldType="date"
                            />
                          </div>
                          <div className="col-xl-2 col-5">
                            <Field
                              name="rating"
                              component={RenderInput}
                              label="Release Date"
                              placeholder="PG-13..."
                              customClass=""
                              fieldType="text"
                            />
                          </div>
                          <div className="col-xl-3 col-7">
                            <Field
                              name="country"
                              component={RenderInput}
                              label="Country"
                              placeholder="Country..."
                              customClass=""
                              fieldType="text"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4 col-6">
                            <Field
                              name="director"
                              component={RenderInput}
                              label="Director"
                              placeholder="Christopher Nolan..."
                              customClass=""
                              fieldType="text"
                            />
                          </div>
                          <div className="col-md-4 col-6">
                            <Field
                              name="writer"
                              component={RenderInput}
                              label="Writer"
                              placeholder="Aaron Sorkin..."
                              customClass=""
                              fieldType="text"
                            />
                          </div>
                          <div className="col-md-4 col-12">
                            <Field
                              name="producer"
                              component={RenderInput}
                              label="Produced by"
                              placeholder="Irving Thalberg..."
                              customClass=""
                              fieldType="text"
                            />
                          </div>
                          <div className="col-12">
                            <Field
                              name="cast"
                              component={RenderTextArea}
                              label="Cast"
                              placeholder="Leonardo DiCaprio, Joseph-Gordon-Levitt, Ellen Page..."
                              customClass=""
                              fieldType="text"
                            />
                          </div>
                        </div>
                        {/* <div id="saveAlert_2" className="alert alert-group alert-danger fade show alert-icon d-none" role="alert">
        <div className="alert-group-prepend">
            <span className="alert-group-icon text-">
                <i data-feather="clock"></i>
            </span>
        </div>
        <div className="alert-content">
            <strong>Hey!</strong> Don't forget to save your changes in this section!
        </div>
    </div> */}
                        {/* <!-- Buttons --> */}
                        <div className="mt-3 placeholder-glow">
                          <button
                            type="submit"
                            className={`btn btn-sm btn-primary ${
                              isSubmittingChange && "placeholder"
                            }`}
                            disabled={isSubmittingChange || submitting}
                          >
                            {isSubmittingChange ? `Saving...` : "Save"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
      <hr />
      <FestivalLaurels laurels={film.festivalLaurels} filmId={film.id} />
      <hr />
      <FilmFiles
        epks={film.epkLinks}
        onePagers={film.onePagerLinks}
        pressNotes={film.pressNotesLinks}
        filmId={film.id}
      />
      <hr />
      <FilmLinks links={film.filesLinks} filmId={film.id} />

      <hr />
      <VideosTable
        trailers={film.mediaTrailers}
        clips={film.mediaClips}
        bts={film.mediaBTSVideo}
        filmId={film.id}
      />
      <hr />
      <UploadFilmMedia filmId={film.id} />
      <ImagesGallery
        title="One-Sheets"
        source={film.mediaOneSheets}
        rows={3}
        placeholderHeight="250px"
        type="mediaOneSheets"
        filmId={film.id}
      />
      <ImagesGallery
        title="Frame Stills"
        source={film.mediaFrameStill}
        rows={4}
        placeholderHeight="100px"
        type="mediaFrameStill"
        filmId={film.id}
      />
      <ImagesGallery
        title="Publicity Stills"
        source={film.mediaPublicityStill}
        rows={4}
        placeholderHeight="100px"
        type="mediaPublicityStill"
        filmId={film.id}
      />
      <ImagesGallery
        title="Behind The Scenes Stills"
        source={film.mediaBTSStill}
        rows={4}
        placeholderHeight="100px"
        type="mediaBTSStill"
        filmId={film.id}
      />
    </>
  );
};

const wrappedComponent = reduxForm({
  form: "filmEditForm",
  validate,
  initialValues: reduxFormValues,
})(FilmEditContent);

export default connect(null, { updateFilm })(wrappedComponent);
