import React from "react";

const ImageComponentSkeleton = ({ props }) => {
  const height = props.placeholderHeight;
  const customClass = props.isPoster;
  return (
    <div className="placeholder-glow" key={props.key}>
      <span
        className={`${
          customClass ? "card-img-top" : "card-img-top card-img-bottom"
        } placeholder`}
        style={{ height: height }}
      ></span>
    </div>
  );
};

export default ImageComponentSkeleton;
