import React from "react";

const IndividualFilmSnippetSkeleton = (props) => {
  return (
    <>
      <div
        className="col-md-4 col-sm-6 col-xs-12 placeholder-glow"
        key={`col_${props.id}`}
      >
        <div className="card hover-translate-y-n3 hover-shadow-lg overflow-hidden">
          <div className="position-relative overflow-hidden">
            <span
              className="card-img-top placeholder"
              style={{ height: 425 + "px" }}
            ></span>
          </div>
          <div className="card-body py-4 text-center">
            <span className="placeholder col-11 placeholder-lg"></span>
            <br />
            <span className="placeholder col-4"></span>
          </div>
        </div>
      </div>
    </>
  );
};

export default IndividualFilmSnippetSkeleton;
