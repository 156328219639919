import React from "react";
import { Field, reduxForm } from "redux-form";
import { useTranslation } from "react-i18next";
import * as Icon from "react-feather";

import validate from "./validate";
import RenderInputSignup from "../_utils/RenderInputSignup";

const FormFirstPage = (props) => {
  const { t } = useTranslation();
  const { handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="firstName"
        component={RenderInputSignup}
        label={`${t("signUp.fields.firstName")}*`}
        placeholder="John"
        customClass=""
        fieldType="text"
        icon={<Icon.User />}
      />
      <Field
        name="lastName"
        component={RenderInputSignup}
        label={`${t("signUp.fields.lastName")}*`}
        placeholder="Doe"
        customClass=""
        fieldType="text"
        icon={<Icon.User />}
      />
      <Field
        name="email"
        component={RenderInputSignup}
        label={`${t("signUp.fields.email")}*`}
        placeholder="john@doe.com"
        customClass=""
        fieldType="email"
        icon={<Icon.AtSign />}
      />
      <Field
        name="phone"
        component={RenderInputSignup}
        label={`${t("signUp.fields.phone")}*`}
        placeholder="(123) 456-7890"
        customClass=""
        fieldType="tel"
        icon={<Icon.Phone />}
      />
      <div className="mt-4">
        <button type="submit" className="btn btn-block btn-primary">
          {t("signUp.buttons.next")}
        </button>
      </div>
    </form>
  );
};

export default reduxForm({
  form: "signUpWizard",
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
})(FormFirstPage);
