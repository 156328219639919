import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Search } from "react-feather";

import { fetchReleases, fetchFilms } from "../../../actions";
import IndividualReleaseSnippet from "./IndividualReleaseSnippet";
import useDocumentTitle from "../useDocumentTitle";

const AllReleases = (props) => {
  const [searchTerm, setSearchTerm] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  let { t } = useTranslation();
  useDocumentTitle(`${t("navigation.films")} - Black Letter Press`);
  useEffect(() => {
    props.fetchReleases();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (props.fetchStatus !== 200 && props.fetchStatus !== null) {
    return (
      <section className="slice pt-5 pb-7 bg-section-secondary">
        <div className="container">
          <h3>{t("pressReleases.error.title")}</h3>
          <p>{t("pressReleases.error.description")}</p>
        </div>
      </section>
    );
  }

  const renderList = () => {
    return props.releases.map((release) => {
      return <IndividualReleaseSnippet release={release} key={release.id} />;
    });
  };

  const resultsSearch = (ev) => {
    // ev.preventDefault();
    setSearchTerm(ev.target.value);
    if (ev.target.value) {
      setIsSearching(true);
    } else {
      setIsSearching(false);
    }

    console.log(`Search: ${searchTerm}`);
    // const results = props.releases.map((el) => {
    //   // return el.contentEn.indexOf(searchTerm) > -1;
    //   // return _.hasIn(el, searchTerm);
    // });
    // console.log(results);
  };

  const renderSearching = () => {
    if (isSearching) {
      return (
        <>
          <div className="nav-item nav-link">
            <strong>{t("pressReleases.search.searching")}</strong>: {searchTerm}
          </div>
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <section className="slice slice-lg py-4">
        <span className="mask bg-light"></span>
        <div
          className="container d-flex align-items-center"
          data-offset-top="#navbar-main"
        >
          <div className="col py-5">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-7 col-lg-7 text-center">
                <h1 className="display-4 text-dark mb-2">
                  {t("pressReleases.title")}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="border-bottom">
        <div className="container py-3">
          <div className="row align-items-center">
            <div className="col-lg-7 mb-4 mb-lg-0">
              <ul className="nav">{renderSearching()}</ul>
            </div>
            <div className="col-lg-5">
              <div className="input-group input-group-merge">
                <div className="input-group-prepend">
                  <span className="input-group-text border-0 pr-2">
                    <Search />
                  </span>
                </div>

                <input
                  type="text"
                  className="form-control border-0 px-1"
                  aria-label={t("pressReleases.search.placeholder")}
                  placeholder={t("pressReleases.search.placeholder")}
                  id="searchForm"
                  value={searchTerm}
                  onChange={(el) => resultsSearch(el)}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="slice pt-5 pb-7 bg-section-secondary">
        <div className="container">
          <div className="row">{renderList()}</div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    releases: Object.values(state.releases),
    films: Object.values(state.films),
    fetchStatus: state.fetchStatus.releases,
  };
};

export default connect(mapStateToProps, { fetchReleases, fetchFilms })(
  AllReleases
);
