import React from "react";

const SectionHeader = (props) => {
  return (
    <>
      <section className="pt-5 bg-section-secondary">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="row align-items-center mb-4">
                <div className="col ml-n3 ml-md-n2">
                  {/* <!-- Title --> */}
                  <h2 className="mb-0">{props.title}</h2>
                </div>
                <div className="col-auto">{props.children}</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionHeader;
