import React from "react";
import { Outlet } from "react-router-dom";
import Navigation from "../Navigation";
import Footer from "../Footer";

const MainContainer = ({ in: inProp }) => {
  return (
    <div>
      <Navigation />
      <Outlet />
      <Footer />
    </div>
  );
};

export default MainContainer;
