import validator from "validator";

const validate = (formValues) => {
  const errors = {};

  if (!formValues.titleEn) {
    //English title is empty
    errors.titleEn = "The English title can't be empty!";
  }

  if (!formValues.titleEs) {
    //Spanish title is empty
    errors.titleEs = "The Spanish title can't be empty!";
  }

  if (!formValues.slug) {
    //Slug is empty
    errors.slug = "The slug can't be empty!";
  }
  if (!validator.isSlug(String(formValues.slug))) {
    //Slug is invalid
    errors.slug = "The slug is invalid!";
  }

  if (!formValues.year) {
    //Year is empty
    errors.year = "The year can't be empty!";
  }

  if (!formValues.oneSheet) {
    //One Sheet is empty
    errors.oneSheet = "You need to upload a One-Sheet";
  }

  return errors;
};

export default validate;
