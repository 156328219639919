import React from "react";

const Links = ({ link }) => {
  let linkIcon;
  let linkTitle;

  switch (link.type) {
    case "facebook":
      linkIcon = "fab fa-facebook";
      linkTitle = "Facebook";
      break;
    case "instagram":
      linkIcon = "fab fa-instagram";
      linkTitle = "Instagram";
      break;
    case "twitter":
      linkIcon = "fab fa-twitter";
      linkTitle = "Twitter";
      break;
    case "tiktok":
      linkIcon = "fab fa-tiktok";
      linkTitle = "TikTok";
      break;
    case "imdb":
      linkIcon = "fab fa-imdb";
      linkTitle = "IMDb";
      break;
    case "officialSite":
      linkIcon = "fas fa-link";
      linkTitle = "Official Site";
      break;
    case "soundtrack":
      linkIcon = "fas fa-music";
      linkTitle = "Soundtrack";
      break;

    default:
  }

  return link.type === "facebook" ||
    link.type === "instagram" ||
    link.type === "twitter" ||
    link.type === "tiktok" ||
    link.type === "imdb" ||
    link.type === "officialSite" ||
    link.type === "soundtrack" ? (
    <div key={link.id}>
      <a href={link.url} target="_blank" rel="noopener noreferrer">
        <i className={linkIcon}></i> {linkTitle}
      </a>
    </div>
  ) : (
    ""
  );
};

export default Links;
