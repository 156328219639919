import React from "react";
import RenderError from "./RenderError";

const RenderInput = ({
  input,
  label,
  placeholder,
  customClass,
  fieldType,
  icon,
  meta,
}) => {
  const className = `form-control ${
    meta.error && meta.touched ? "is-invalid" : ""
  }`;

  return (
    <>
      {RenderError(meta)}
      <div className={`form-group ${customClass}`}>
        <label className="form-control-label">{label}</label>
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">{icon}</span>
          </div>
          <input
            {...input}
            id={input.name}
            type={fieldType}
            className={className}
            placeholder={placeholder}
          />
        </div>
      </div>
    </>
  );
};

export default RenderInput;
