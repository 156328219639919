import React, { useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { connect } from "react-redux";

import { fetchFilmsAdmin } from "../../../actions";
import ShowFilmsContent from "./ShowFilmsContent";
import useDocumentTitle from "../../public/useDocumentTitle";

const ShowFilms = ({ fetchFilmsAdmin }) => {
  const [searchParams] = useSearchParams();
  const searchFilter = searchParams.get("filter") || "public";

  useEffect(() => {
    fetchFilmsAdmin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDocumentTitle("Films - Black Letter Press");

  const SubMenuLink = (props) => {
    let isActive = searchFilter === props.to;

    return (
      <Link
        to={`?filter=${props.to}`}
        className={`nav-link${isActive ? " active" : ""}`}
      >
        {props.children}
      </Link>
    );
  };

  return (
    <>
      <section className="pt-5 bg-section-secondary">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="row align-items-center mb-4">
                {/* <!-- Title --> */}
                <div className="col">
                  <h2 className="mb-0">Films</h2>
                </div>
                <div className="col-auto">
                  <Link
                    to="/admin/films/create"
                    className="btn btn-sm btn-primary btn-icon"
                  >
                    <span className="btn-inner--text">Add Film</span>
                    <span className="btn-inner--icon">
                      <i className="fas fa-plus"></i>
                    </span>
                  </Link>
                </div>
              </div>
              <div className="mt-4">
                <ul className="nav nav-tabs overflow-x">
                  <li className="nav-item">
                    <SubMenuLink to="public">Public</SubMenuLink>
                  </li>
                  <li className="nav-item">
                    <SubMenuLink to="private">Private</SubMenuLink>
                  </li>
                  <li className="nav-item">
                    <SubMenuLink to="draft">Drafts</SubMenuLink>
                  </li>
                  <li className="nav-item">
                    <SubMenuLink to="archive">Archived</SubMenuLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ShowFilmsContent filter={searchFilter} />
    </>
  );
};

export default connect(null, { fetchFilmsAdmin })(ShowFilms);
