import React, { useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import i18next from "i18next";

import { fetchFilm } from "../../../actions";
import useDocumentTitle from "../useDocumentTitle";
import TopContent from "./individualFilm/TopContent";
import SecondaryContent from "./individualFilm/SecondaryContent";
import VideosMain from "./individualFilm/VideosMain";
import ImagesGallery from "./individualFilm/ImagesGallery";
import VideoGallery from "./individualFilm/VideoGallery";
import FestivalLaurels from "./individualFilm/FestivalLaurels";

const IndividualFilm = ({ fetchFilm, film, fetchStatus }) => {
  let { slug } = useParams();
  let navigate = useNavigate();
  let location = useLocation();
  let returnLocation = encodeURIComponent(location.pathname);

  useEffect(() => {
    fetchFilm(slug);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (fetchStatus === 401) {
    // console.log("Private film");
    navigate(`/login?return=${returnLocation}`, { replace: true });
  } else if (fetchStatus === 404) {
    //Redirect to 404
    navigate("/404", { replace: true });
  } else if (fetchStatus === 500) {
    //Redirect to 500
    navigate("/500", { replace: true });
  }

  /*
   * Translate DB values
   */

  if (i18next.language === "en") {
    film.name = film.nameEn;
    film.logline = film.loglineEn;
    film.synopsis = film.synopsisEn;
  } else if (i18next.language === "es") {
    film.name = film.nameEs;
    film.logline = film.loglineEs;
    film.synopsis = film.synopsisEs;
  }

  useDocumentTitle(`${film.name} - Black Letter Press`);

  /*
   * Format the Release Date
   */
  const dateToFormat = new Date(film.releaseDate);

  film.releaseDateFormated = dateToFormat.toLocaleDateString(i18next.language, {
    year: "numeric",
    month: "long",
    day: "numeric",
    timeZone: "UTC",
  });

  return (
    <>
      <section className="slice pt-5 pb-7 bg-section-secondary">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <TopContent film={film} />
                  <br />
                  <SecondaryContent film={film} />
                  <FestivalLaurels laurels={film.festivalLaurels} />
                  <VideosMain
                    trailers={film.mediaTrailers}
                    clips={film.mediaClips}
                  />
                  <ImagesGallery
                    key="oneSheetsComponent"
                    elements={film.mediaOneSheets}
                    title="oneSheets"
                    thumbnailHeight="200px"
                  />
                  <ImagesGallery
                    key="frameStillsComponent"
                    elements={film.mediaFrameStill}
                    title="frameStills"
                    thumbnailHeight="60px"
                  />
                  <ImagesGallery
                    key="publicityStillsComponent"
                    elements={film.mediaPublicityStill}
                    title="publicityStills"
                    thumbnailHeight="200px"
                  />
                  <VideoGallery
                    elements={film.mediaBTSVideo}
                    title="btsVideos"
                  />
                  <ImagesGallery
                    key="btsStillsComponent"
                    elements={film.mediaBTSStill}
                    title="btsStills"
                    thumbnailHeight="70px"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return { film: state.filmView, fetchStatus: state.fetchStatus.filmView };
};

export default connect(mapStateToProps, { fetchFilm })(IndividualFilm);
