const validate = (formValues) => {
  const errors = {};

  if (!formValues.titleEn) {
    //English title is empty
    errors.titleEn = "The English title can't be empty!";
  }

  if (!formValues.titleEs) {
    //Spanish title is empty
    errors.titleEs = "The Spanish title can't be empty!";
  }

  if (!formValues.category) {
    //Category is empty
    errors.category = "The category can't be empty!";
  }

  if (!formValues.status) {
    //Status is empty
    errors.status = "The status can't be empty!";
  }

  if (!formValues.date) {
    //Date is empty
    errors.date = "The date can't be empty!";
  }

  return errors;
};

export default validate;
