import React, { useState } from "react";

import EditingField from "../_utils/EditingField";
import ActionBar from "../_utils/ActionBar";

const VideosTableElement = ({ el, deleteScript, editScript, type }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [nameEnValue, setNameEnValue] = useState(el.nameEn);
  const [nameEsValue, setNameEsValue] = useState(el.nameEs);
  const [urlValue, setUrlValue] = useState(el.url);

  const saveContent = () => {
    editScript({
      id: el.id,
      nameEn: nameEnValue,
      nameEs: nameEsValue,
      url: urlValue,
      type,
    });
    setIsEditing(false);
  };

  return (
    <>
      <tr key={`video${el.id}`}>
        <td className="align-middle">
          {isEditing ? (
            <EditingField
              state={nameEnValue}
              setState={setNameEnValue}
              type="text"
            />
          ) : (
            nameEnValue
          )}
        </td>
        <td className="align-middle">
          {isEditing ? (
            <EditingField
              state={nameEsValue}
              setState={setNameEsValue}
              type="text"
            />
          ) : (
            nameEsValue
          )}
        </td>
        <td className="align-middle">
          {isEditing ? (
            <EditingField state={urlValue} setState={setUrlValue} type="text" />
          ) : (
            urlValue
          )}
        </td>
        <td className="text-right">
          <ActionBar
            navbar={{ preview: true, edit: true, delete: true }}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            saveScript={saveContent}
            deleteScript={() => deleteScript(el.id)}
            previewUrl={urlValue}
          />
        </td>
      </tr>
    </>
  );
};

export default VideosTableElement;
