import React from "react";
import { useTranslation } from "react-i18next";

const VideoGallery = ({ elements, title }) => {
  let { t } = useTranslation();

  const isYoutubeVideo = (url) => {
    return url.match(
      /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/
    );
  };
  const isVimeoVideo = (url) => {
    return url.match(
      /^(http\:\/\/|https\:\/\/)?(www\.)?(vimeo\.com\/)([0-9]+)$/
    );
  };

  const getYoutubeID = (url) => {
    const match = url.match(
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
    );
    return match && match[7].length === 11 ? match[7] : false;
  };

  const getVimeoID = (url) => {
    const match = url.match(
      /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/
    );
    return match[5];
  };

  const showYoutubeVideo = ({ url, name }) => {
    const videoID = getYoutubeID(url);
    return (
      <div
        className="col-md-6 col-sm-12 text-center"
        key={`yt_video_${videoID}`}
      >
        <iframe
          width="100%"
          height="300"
          src={`https://www.youtube-nocookie.com/embed/${videoID}`}
          frameBorder="0"
          title={`YouTube Video ${videoID}`}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <span className="pt-2 text-dark">{name}</span>
      </div>
    );
  };

  const showVimeoVideo = ({ url, name }) => {
    const videoID = getVimeoID(url);
    return (
      <div
        className="col-md-6 col-sm-12 text-center"
        key={`vimeo_video_${videoID}`}
      >
        <div style={{ paddingTop: 60 + "%", position: "relative" }}>
          <iframe
            src={`https://player.vimeo.com/video/${videoID}?color=FE6966&byline=0&portrait=0`}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: 100 + "%",
              height: 100 + "%",
            }}
            frameBorder="0"
            title={`Vimeo Video ${videoID}`}
            allow="autoplay; fullscreen"
            allowFullScreen
          ></iframe>
        </div>
        <script src="https://player.vimeo.com/api/player.js"></script>
        <span className="pt-2 text-dark">{name}</span>
      </div>
    );
  };

  const sortVideoSource = (array) => {
    return array.map(({ url, name }) => {
      if (isVimeoVideo(url)) {
        return showVimeoVideo({ url, name });
      } else if (isYoutubeVideo(url)) {
        return showYoutubeVideo({ url, name });
      } else {
        return null;
      }
    });
  };

  if (elements && elements.length >= 1) {
    return (
      <div>
        <div className="col-12">
          <h5 className="card-title h5">{t(`filmPage.${title}`)}</h5>
        </div>
        <div className="row">{sortVideoSource(elements)}</div>
      </div>
    );
  } else {
    return null;
  }
};

export default VideoGallery;
