import React from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";

import ImagesGalleryElement from "./ImagesGalleryElement";
import { updateImage, deleteImage } from "../../../../../actions";

const ImagesGallery = ({
  title,
  source,
  rows,
  placeholderHeight,
  updateImage,
  deleteImage,
  type,
  filmId,
}) => {
  let rowsClass;
  if (rows === 3) {
    rowsClass = "col-md-4";
  } else if (rows === 4) {
    rowsClass = "col-md-3";
  }

  const editImageScript = async (data) => {
    data.filmId = filmId;
    await updateImage(data);
  };

  const deleteImageScript = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `Deleting this image is an irreversible action!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        triggerDelete(id);
      }
    });
  };

  const triggerDelete = async (id) => {
    const data = {
      id,
      filmId,
    };
    try {
      await deleteImage(data);
    } catch (error) {
      console.log(error);
    }
  };

  const loadImages = () => {
    if (source && source.length > 0) {
      return source.map((el) => {
        return (
          <div className={rowsClass} key={`imageElement_${el.id}`}>
            <ImagesGalleryElement
              el={el}
              placeholderHeight={placeholderHeight}
              editScript={editImageScript}
              deleteScript={deleteImageScript}
              type={type}
            />
          </div>
        );
      });
    } else {
      return (
        <div className="col-12">
          There are no images associated with this category.
        </div>
      );
    }
  };

  return (
    <>
      <section className="slice slice-sm">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <h5 className="mb-3">{title}</h5>
              <div className="row">{loadImages()}</div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default connect(null, { updateImage, deleteImage })(ImagesGallery);
