import _ from "lodash";
import {
  FETCH_RELEASES_B,
  EDIT_RELEASE,
  UPDATE_STATUS_FILM,
  DELETE_RELEASE,
} from "../actions/types";

const bReleasesReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_RELEASES_B:
      return { ...state, ..._.mapKeys(action.payload, "id") };
    case EDIT_RELEASE:
      return { ...state, [action.payload.id]: action.payload };
    case UPDATE_STATUS_FILM:
      const newData = _.mapKeys(action.payload, "id");
      return _.merge(state, newData);
    case DELETE_RELEASE:
      return _.omit(state, action.payload);
    default:
      return state;
  }
};

export default bReleasesReducer;
