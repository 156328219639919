export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";

export const FETCH_FILM = "FETCH_FILM";
export const FETCH_FILM_ADMIN = "FETCH_FILM_ADMIN";
export const FETCH_FILM_STATUS = "FETCH_FILM_STATUS";
export const FETCH_FILMS_FEATURED = "FETCH_FILMS_FEATURED";
export const FETCH_FILMS_FEATURED_STATUS = "FETCH_FILMS_FEATURED_STATUS";
export const FETCH_FILMS = "FETCH_FILMS";
export const FETCH_FILMS_STATUS = "FETCH_FILMS_STATUS";
export const FETCH_FILMS_B = "FETCH_FILMS";
export const EDIT_FILM = "EDIT_FILM";
export const UPDATE_STATUS_FILM = "UPDATE_STATUS_FILM";
export const DELETE_FILM = "DELETE_FILM";

export const FETCH_RELEASES = "FETCH_RELEASES";
export const FETCH_RELEASE_ADMIN = "FETCH_RELEASE_ADMIN";
export const FETCH_RELEASES_STATUS = "FETCH_RELEASES_STATUS";
export const FETCH_RELEASES_B = "FETCH_RELEASES_B";
export const FETCH_RELEASES_FEATURED = "FETCH_RELEASES_FEATURED";
export const FETCH_RELEASES_FEATURED_STATUS = "FETCH_RELEASES_FEATURED_STATUS";
export const FETCH_RELEASE = "FETCH_RELEASE";
export const FETCH_RELEASE_STATUS = "FETCH_RELEASE_STATUS";
export const EDIT_RELEASE = "EDIT_RELEASE";
export const DELETE_RELEASE = "DELETE_RELEASE";

export const FETCH_USER = "FETCH_USER";
export const FETCH_USER_ADMIN = "FETCH_USER_ADMIN";
export const FETCH_USERS_ADMIN = "FETCH_USERS_ADMIN";
export const EDIT_USER = "EDIT_USER";
export const EDIT_USER_ADMIN = "EDIT_USER_ADMIN";
export const UPDATE_STATUS_USER = "UPDATE_STATUS_USER";
export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_ADMIN = "DELETE_USER_ADMIN";

export const CREATE_FILM_FRAGMENT = "CREATE_FILM_FRAGMENT";
export const DELETE_FILM_LINK = "DELETE_FILM_LINK";
export const DELETE_FILM_FRAGMENT = "DELETE_FILM_FRAGMENT";

export const FETCH_DASHBOARD_ADMIN = "FETCH_DASHBOARD_ADMIN";
