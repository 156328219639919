import validator from "validator";

const validate = (formValues) => {
  const errors = {};

  if (!formValues.firstName) {
    //First Name is empty
    errors.firstName = "signUp.errors.firstNameEmpty";
  }

  if (!formValues.lastName) {
    //Last Name is empty
    errors.lastName = "signUp.errors.lastNameEmpty";
  }

  if (!formValues.email) {
    //Email is empty
    errors.email = "signUp.errors.emailEmpty";
  } else if (!validator.isEmail(String(formValues.email))) {
    //Invalid email
    errors.email = "signUp.errors.emailInvalid";
  }

  if (!formValues.phone) {
    //Phone is empty
    errors.phone = "signUp.errors.phoneEmpty";
  }

  if (!validator.isMobilePhone(String(formValues.phone))) {
    //Phone is empty
    errors.phone = "signUp.errors.phoneInvalid";
  }

  if (!formValues.password) {
    //Password is empty
    errors.password = "signUp.errors.passwordEmpty";
  }

  if (formValues.password !== formValues.passwordRepeat) {
    //Password don't match
    errors.password = "signUp.errors.passwordsDontMatch";
  }

  if (!/[a-z]/g.test(formValues.password)) {
    //The password doesn't have a lower case letter
    errors.password = "signUp.errors.passwordLower";
  }

  if (!/[A-Z]/g.test(formValues.password)) {
    //The password doesn't have upper case letter
    errors.password = "signUp.errors.passwordUpper";
  }

  if (!/[0-9]/g.test(formValues.password)) {
    //The password doesn't have a number
    errors.password = "signUp.errors.passwordNumber";
  }

  if (String(formValues.password).length <= 7) {
    //The password has less than 8 characters
    errors.password = "signUp.errors.password8Chars";
  }

  if (!formValues.passwordRepeat) {
    //Password repeat is empty
    errors.passwordRepeat = "signUp.errors.passwordRepeatEmpty";
  }

  if (!formValues.outletName) {
    //Outlet Name is empty
    errors.outletName = "signUp.errors.outletNameEmpty";
  }

  if (!formValues.jobTitle) {
    //Job Title is empty
    errors.jobTitle = "signUp.errors.jobTitleEmpty";
  }

  if (!formValues.outletType) {
    //Outlet Type is empty
    errors.outletType = "signUp.errors.outletTypeEmpty";
  }

  return errors;
};

export default validate;
