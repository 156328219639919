import React from "react";
import { Link } from "react-router-dom";
import { Home, ArrowLeft } from "react-feather";
import { useTranslation } from "react-i18next";

const goBackScript = () => {
  window.history.back();
};

const Error500 = () => {
  const { t } = useTranslation();

  return (
    <>
      <button
        onClick={goBackScript}
        class="btn btn-primary btn-icon-only rounded-circle position-absolute left-4 top-4 d-lg-inline-flex"
        data-toggle="tooltip"
        data-placement="right"
        title="Go back"
      >
        <span class="btn-inner--icon">
          <ArrowLeft />
        </span>
      </button>
      <section>
        <div class="container d-flex flex-column">
          <div class="row align-items-center justify-content-between min-vh-100">
            <div class="col-12 col-md-6 col-xl-7 order-md-2">
              <img
                alt="Error 500"
                src="/assets/img/svg/illustrations/illustration-8.svg"
                class="img-fluid"
              />
            </div>
            <div class="col-12 col-md-6 col-xl-5 order-md-1 text-center text-md-left">
              <h6 class="display-1 mb-3 font-weight-600 text-danger">
                {t("errorPages.500.title")}
              </h6>
              <p class="lead text-lg mb-5">{t("errorPages.500.description")}</p>
              <Link
                to="/"
                class="btn btn-primary btn-icon hover-translate-y-n3"
              >
                <span class="btn-inner--icon">
                  <Home />
                </span>
                <span class="btn-inner--text">
                  {t("errorPages.500.button")}
                </span>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Error500;
