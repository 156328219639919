import React, { useState } from "react";

import ImageComponent from "../../../../public/films/individualFilm/ImageComponent";
import EditingField from "../_utils/EditingField";
import ActionBar from "../_utils/ActionBar";

const ImagesGalleryElement = ({
  el,
  placeholderHeight,
  editScript,
  deleteScript,
  type,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [nameEnValue, setNameEnValue] = useState(el.nameEn);
  const [nameEsValue, setNameEsValue] = useState(el.nameEs);
  const [copyrightEnValue, setCopyrightEnValue] = useState(el.copyrightEn);
  const [copyrightEsValue, setCopyrightEsValue] = useState(el.copyrightEs);

  const saveContent = () => {
    editScript({
      id: el.id,
      nameEn: nameEnValue,
      nameEs: nameEsValue,
      copyrightEn: copyrightEnValue,
      copyrightEs: copyrightEsValue,
      type,
    });
    setIsEditing(false);
  };

  return (
    <div className="card" key={`imageSnippet${el.id}`}>
      <div className="card-body text-center">
        <a href={el.url} data-fancybox>
          <ImageComponent
            src={el.thumb}
            width="100%"
            placeholderHeight={placeholderHeight}
          />
        </a>
        <hr />
        <small>
          <div>
            <strong>Name English:</strong>
          </div>
          <div className="pb-2">
            {isEditing ? (
              <EditingField
                state={nameEnValue}
                setState={setNameEnValue}
                type="text"
              />
            ) : (
              nameEnValue
            )}
          </div>
          <div>
            <strong>Name Spanish:</strong>
          </div>
          <div className="pb-2">
            {isEditing ? (
              <EditingField
                state={nameEsValue}
                setState={setNameEsValue}
                type="text"
              />
            ) : (
              nameEsValue
            )}
          </div>
          <div className="pb-2">
            <strong>Size:</strong> {el.size}
          </div>
          <div className="pb-2">
            <strong>Dimensions:</strong> {el.dimensions}
          </div>
          <div>
            <strong>Copyright English:</strong>
          </div>
          <div className="pb-2">
            {isEditing ? (
              <EditingField
                state={copyrightEnValue}
                setState={setCopyrightEnValue}
                type="text"
              />
            ) : (
              copyrightEnValue
            )}
          </div>
          <div>
            <strong>Copyright Spanish:</strong>
          </div>
          <div>
            {isEditing ? (
              <EditingField
                state={copyrightEsValue}
                setState={setCopyrightEsValue}
                type="text"
              />
            ) : (
              copyrightEsValue
            )}
          </div>
        </small>
      </div>
      <div className="card-footer text-center">
        <ActionBar
          navbar={{ preview: false, edit: true, delete: true }}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          saveScript={saveContent}
          deleteScript={() => deleteScript(el.id)}
        />
      </div>
    </div>
  );
};

export default ImagesGalleryElement;
