import React, { useState } from "react";

import EditingField from "../_utils/EditingField";
import ActionBar from "../_utils/ActionBar";

const FilmLinksElement = ({ el, deleteScript, editScript }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [typeValue, setTypeValue] = useState(el.type);
  const [urlValue, setUrlValue] = useState(el.url);

  const saveContent = () => {
    editScript({ id: el.id, type: typeValue, url: urlValue });
    setIsEditing(false);
  };

  const editingDropdown = (state, setState) => {
    return (
      <>
        <select
          className="form-control"
          data-toggle="select"
          value={state}
          onChange={(el) => setState(el.target.value)}
        >
          <option value="facebook" key="facebook">
            Facebook
          </option>
          <option value="instagram" key="instagram">
            Instagram
          </option>
          <option value="twitter" key="twitter">
            Twitter
          </option>
          <option value="tiktok" key="tiktok">
            TikTok
          </option>
          <option value="imdb" key="imdb">
            IMDb
          </option>
          <option value="officialSite" key="officialSite">
            Official Site
          </option>
          <option value="soundtrack" key="soundtrack">
            Soundtrack
          </option>
        </select>
      </>
    );
  };

  const typeInterpreter = (value) => {
    switch (value) {
      case "facebook":
        return "Facebook";
      case "instagram":
        return "Instagram";
      case "twitter":
        return "Twitter";
      case "tiktok":
        return "TikTok";
      case "imdb":
        return "IMDb";
      case "officialSite":
        return "Official Site";
      case "soundtrack":
        return "Soundtrack";
      default:
        return null;
    }
  };

  return (
    <>
      <tr key={`link${el.id}`}>
        <td className="align-middle">
          {isEditing
            ? editingDropdown(typeValue, setTypeValue)
            : typeInterpreter(typeValue)}
        </td>
        <td className="align-middle">
          {isEditing ? (
            <EditingField state={urlValue} setState={setUrlValue} type="text" />
          ) : (
            urlValue
          )}
        </td>
        <td className="text-right">
          <ActionBar
            navbar={{ preview: false, edit: true, delete: true }}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            saveScript={saveContent}
            deleteScript={() => deleteScript(el.id)}
          />
        </td>
      </tr>
    </>
  );
};

export default FilmLinksElement;
