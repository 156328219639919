import React from "react";

import ActionBar from "../_utils/ActionBar";

const FilmFilesElement = ({ el, deleteScript }) => {
  return (
    <>
      <tr key={`file${el.id}`}>
        <td className="align-middle">
          {el.lang === "en" ? "English" : "Spanish"}
        </td>
        <td className="text-right">
          <ActionBar
            navbar={{ preview: true, edit: false, delete: true }}
            deleteScript={() => deleteScript(el.id)}
            previewUrl={el.url}
          />
        </td>
      </tr>
    </>
  );
};

export default FilmFilesElement;
