import validator from "validator";

const validate = (formValues) => {
  const errors = {};

  if (!formValues.type) {
    //Type is empty
    errors.type = "The link type can't be empty!";
  }

  if (
    !validator.isURL(String(formValues.url), {
      protocols: ["http", "https"],
      require_protocol: true,
    })
  ) {
    //URL is invalid
    errors.url = "The URL is invalid";
  }

  if (!formValues.url) {
    //URL is empty
    errors.url = "The URL can't be empty!";
  }

  return errors;
};

export default validate;
