import React from "react";

import AdminPageTitle from "../../AdminPageTitle";

const ReleaseEditSkeleton = () => {
  return (
    <>
      <AdminPageTitle>Edit Press Release</AdminPageTitle>
      <section className="slice slice-sm bg-section">
        <div className="container placeholder-glow">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="row">
                <div className="col-lg-12 pb-4">
                  <div>
                    {/* <!-- Title --> */}
                    <h5 className="mb-3">Basic Information</h5>

                    {/* <!-- General information --> */}
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <span className="form-control-label placeholder col-6"></span>
                          <span className="form-control placeholder col-12 placeholder-lg"></span>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <span className="form-control-label placeholder col-5"></span>
                          <span className="form-control placeholder col-12 placeholder-lg"></span>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <span className="form-control-label placeholder col-6"></span>
                          <span className="form-control placeholder col-12 placeholder-lg"></span>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <span className="form-control-label placeholder col-7"></span>
                          <span className="form-control placeholder col-12 placeholder-lg"></span>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <span className="form-control-label placeholder col-6"></span>
                          <span className="form-control placeholder col-12 placeholder-lg"></span>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <span className="form-control-label placeholder col-6"></span>
                          <span className="form-control placeholder col-12 placeholder-lg"></span>
                        </div>
                      </div>
                      <hr />
                      <div className="col-md-6">
                        <div className="form-group">
                          <span className="form-control-label placeholder col-6"></span>
                          <span className="form-control placeholder col-12 placeholder-lg"></span>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <span className="form-control-label placeholder col-3"></span>
                          <span className="form-control placeholder col-12 placeholder-lg"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <h5 className="mb-3">Intro Summary (English)</h5>

                  <div className="row">
                    <div className="col-md-12 mb-5">
                      <span className="placeholder col-8"></span>
                      <span className="placeholder col-10"></span>
                      <span className="placeholder col-3"></span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <h5 className="mb-3">Intro Summary (Spanish)</h5>

                  <div className="row">
                    <div className="col-md-12 mb-5">
                      <span className="placeholder col-10"></span>
                      <span className="placeholder col-8"></span>
                      <span className="placeholder col-6"></span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div>
                    <h5 className="mb-3">Content (English)*</h5>

                    <div className="row">
                      <div className="col-md-12 mb-5">
                        <span className="placeholder col-4 placeholder-lg"></span>

                        <div>
                          <span className="placeholder col-11"></span>
                          <span className="placeholder col-10"></span>
                          <span className="placeholder col-6"></span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <h5 className="mb-3">Content (Spanish)*</h5>

                    <div className="row">
                      <div className="col-md-12 mb-5">
                        <span className="placeholder col-4 placeholder-lg"></span>

                        <div>
                          <span className="placeholder col-12"></span>
                          <span className="placeholder col-11"></span>
                          <span className="placeholder col-9"></span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-3">
                    <button
                      type="submit"
                      id="submitPost"
                      className="btn btn-sm btn-primary"
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      id="previewPost"
                      className="btn btn-sm btn-secondary"
                    >
                      Preview
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ReleaseEditSkeleton;
