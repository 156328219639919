import React from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { useTranslation } from "react-i18next";
import { Tv, Briefcase, Phone } from "react-feather";

import validate from "./validate";
import RenderInputSignup from "../_utils/RenderInputSignup";
import RenderOutletType from "../_utils/RenderOutletType";
import RenderNotes from "../_utils/RenderNotes";

const FormThirdPage = (props) => {
  const { t } = useTranslation();

  const { handleSubmit, previousPage, submitting } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="outletName"
        component={RenderInputSignup}
        label={`${t("signUp.fields.outletName")}*`}
        placeholder={t("signUp.fields.outletNamePlaceholder")}
        fieldType="text"
        icon={<Tv />}
      />
      <Field
        name="outletPhone"
        component={RenderInputSignup}
        label="Outlet Phone*"
        placeholder="(123) 456-7890"
        fieldType="phone"
        customClass="d-none"
        icon={<Phone />}
      />
      <Field
        name="jobTitle"
        component={RenderInputSignup}
        label={`${t("signUp.fields.jobTitle")}*`}
        placeholder={t("signUp.fields.jobTitlePlaceholder")}
        fieldType="text"
        icon={<Briefcase />}
      />
      <Field
        name="outletType"
        label={`${t("signUp.fields.outletType.title")}*`}
        defaultOption={t("signUp.fields.outletType.select")}
        component={RenderOutletType}
      >
        <option value="print_newspaper" key="print_newspaper">
          {t("signUp.fields.outletType.printNewspaper")}
        </option>
        <option value="print_magazine" key="print_magazine">
          {t("signUp.fields.outletType.printMagazine")}
        </option>
        <option value="broadcast_radio" key="broadcast_radio">
          {t("signUp.fields.outletType.broadcastRadio")}
        </option>
        <option value="broadcast_tv" key="broadcast_tv">
          {t("signUp.fields.outletType.broadcastTV")}
        </option>
        <option value="broadcast_cable" key="broadcast_cable">
          {t("signUp.fields.outletType.broadcastCable")}
        </option>
        <option value="online" key="online">
          {t("signUp.fields.outletType.online")}
        </option>
      </Field>

      <Field
        name="notes"
        label={t("signUp.fields.reasonForAccess")}
        placeholder={t("signUp.fields.reasonForAccessPlaceholder")}
        component={RenderNotes}
      />

      <div className="my-4">
        <label className="text-center">
          {t("signUp.terms.part1") + " "}
          <Link to="/terms">{t("signUp.terms.part2")}</Link>
        </label>
      </div>

      <div className="mt-4">
        <div className="row">
          <div className="col-6">
            <button
              type="button"
              className="btn btn-block btn-outline-primary"
              onClick={previousPage}
            >
              {t("signUp.buttons.previous")}
            </button>
          </div>
          <div className="col-6">
            <button
              type="submit"
              className="btn btn-block btn-primary"
              disabled={submitting}
            >
              {t("signUp.buttons.requestAccess")}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};
export default reduxForm({
  form: "signUpWizard",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
})(FormThirdPage);
