import _ from "lodash";
import { FETCH_USERS_ADMIN, UPDATE_STATUS_USER } from "../actions/types";

const bUsersReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_USERS_ADMIN:
      return { ...state, ..._.mapKeys(action.payload, "id") };
    case UPDATE_STATUS_USER:
      return { ...state, [action.payload.id]: action.payload };
    // case FETCH_RELEASE_ADMIN:
    //   return { ...state, releases: action.payload };
    default:
      return state;
  }
};

export default bUsersReducer;
