import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import DashboardContent from "./DashboardContent";
import DashboardSkeleton from "./DashboardSkeleton";

import { fetchDashboardAdmin } from "../../../actions";
import useDocumentTitle from "../../public/useDocumentTitle";

const MainDash = ({ fetchDashboardAdmin, dashboard }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchScript = async () => {
      setIsLoading(true);
      await fetchDashboardAdmin();
      setIsLoading(false);
    };

    fetchScript();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDocumentTitle("Admin Dashboard - Black Letter Press");

  if (isLoading) {
    return <DashboardSkeleton />;
  } else {
    return <DashboardContent data={dashboard} />;
  }
};

const mapStateToProps = (state) => {
  return { dashboard: state.bView.dashboard };
};

export default connect(mapStateToProps, { fetchDashboardAdmin })(MainDash);
