const validate = (formValues) => {
  const errors = {};

  if (!formValues.filmType) {
    //File type is empty
    errors.filmType = "The file type can't be empty!";
  }

  if (!formValues.filmFile) {
    //File is empty
    errors.filmFile = "You need to upload a file!";
  }

  return errors;
};

export default validate;
