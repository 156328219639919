import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, reset } from "redux-form";

import RenderDropdown from "../../../_utils/RenderDropdown";
import RenderInput from "../../../_utils/RenderInput";
import RenderUpload from "../../../_utils/RenderUpload";
import validate from "./validate";
import { createImage } from "../../../../../actions";

const UploadFilmMedia = ({ filmId, submitting, handleSubmit, createImage }) => {
  const addImage = async (data, dispatch) => {
    data.filmId = filmId;
    // console.log(data);
    // const totalFilesToUpload = data.images.length;
    // console.log(data.images);

    // for (let i = 0; i < totalFilesToUpload; i++) {
    // data.currentImage = i;
    // console.log(`ID FROM THE ARRAY: ${i}`);
    // data.currentImage = data.images.i;
    // console.log(data);

    await createImage(data);
    // }
    dispatch(reset("createImagesForm"));
  };

  return (
    <>
      <section className="slice slice-sm">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <h5 className="mb-3">Upload New Media</h5>
              <form onSubmit={handleSubmit(addImage)}>
                <div className="row">
                  <div className="col-sm-6">
                    <Field
                      name="type"
                      label="Category"
                      defaultOption="Select..."
                      component={RenderDropdown}
                    >
                      <option value="mediaOneSheets" key="mediaOneSheets">
                        One-Sheet
                      </option>
                      <option value="mediaFrameStill" key="mediaFrameStill">
                        Frame Still
                      </option>
                      <option
                        value="mediaPublicityStill"
                        key="mediaPublicityStill"
                      >
                        Publicity Still
                      </option>
                      <option value="mediaBTSStill" key="mediaBTSStill">
                        Behind the Scenes Still
                      </option>
                    </Field>
                  </div>

                  <div className="col-sm-6" id="fileGroup2">
                    <Field
                      name="images"
                      component={RenderUpload}
                      label="Files"
                      placeholder="Select files..."
                      customClass=""
                      accept=".png,.jpg,.jpeg"
                      notes="File size limit: 25MB"
                      // multiple={true}
                    />
                  </div>

                  <div className="col-sm-6">
                    <Field
                      name="nameEn"
                      component={RenderInput}
                      label="Name (English)"
                      placeholder="Name of the image..."
                      customClass=""
                      fieldType="text"
                    />
                  </div>
                  <div className="col-sm-6">
                    <Field
                      name="nameEs"
                      component={RenderInput}
                      label="Name (Spanish)"
                      placeholder="Nombre de la imagen..."
                      customClass=""
                      fieldType="text"
                    />
                  </div>
                  <div className="col-sm-12">
                    <Field
                      name="copyrightEn"
                      component={RenderInput}
                      label="Copyright (English)"
                      placeholder="Photo by John Doe, Courtesy of Black Letter Films"
                      customClass=""
                      fieldType="text"
                    />
                  </div>
                  <div className="col-sm-12">
                    <Field
                      name="copyrightEs"
                      component={RenderInput}
                      label="Copyright (Spanish)"
                      placeholder="Foto de John Doe, Cortesía de Black Letter Films"
                      customClass=""
                      fieldType="text"
                    />
                  </div>
                </div>
                <div className="mt-3">
                  <button
                    type="submit"
                    className="btn btn-sm btn-primary"
                    disabled={submitting}
                  >
                    {submitting ? "Uploading..." : "Upload"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const wrappedComponent = reduxForm({
  form: "createImagesForm",
  validate,
})(UploadFilmMedia);

export default connect(null, { createImage })(wrappedComponent);
