import React, { useState } from "react";

import ImageComponent from "../../../../public/films/individualFilm/ImageComponent";
import EditingField from "../_utils/EditingField";
import ActionBar from "../_utils/ActionBar";

const FestivalElement = ({ el, deleteScript, editScript }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [festivalNameValue, setFestivalNameValue] = useState(el.festivalName);
  const [orderValue, setOrderValue] = useState(el.order);

  const saveContent = () => {
    editScript({
      id: el.id,
      order: orderValue,
      festivalName: festivalNameValue,
    });
    setIsEditing(false);
  };

  return (
    <>
      <tr key={`laurel${el.id}`}>
        <td className="align-middle">
          {isEditing ? (
            <EditingField
              state={orderValue}
              setState={setOrderValue}
              type="number"
              customStyle={{
                width: "100px",
              }}
            />
          ) : (
            orderValue
          )}
        </td>
        <td className="align-middle">
          <ImageComponent
            src={el.thumb}
            width="100px"
            placeholderHeight="50px"
          />
        </td>
        <td className="align-middle">
          {isEditing ? (
            <EditingField
              state={festivalNameValue}
              setState={setFestivalNameValue}
              type="text"
            />
          ) : (
            festivalNameValue
          )}
        </td>
        <td className="text-right">
          <ActionBar
            navbar={{ preview: false, edit: true, delete: true }}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            saveScript={saveContent}
            deleteScript={() => deleteScript(el.id)}
          />
        </td>
      </tr>
    </>
  );
};

export default FestivalElement;
