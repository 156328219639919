import React from "react";
import { Link } from "react-router-dom";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

import ImageComponent from "../../films/individualFilm/ImageComponent";
import shortenText from "../../../_utils/shortenText";

const IndividualReleaseSnippet = ({ release }) => {
  let { t } = useTranslation();

  if (i18next.language === "en") {
    release.title = release.titleEn;
  } else if (i18next.language === "es") {
    release.title = release.titleEs;
  }

  release.shortTitle = shortenText(release.title, 55);

  /*
   * Format the Date
   */
  const dateToFormat = new Date(release.date);

  release.dateFormated = dateToFormat.toLocaleDateString(i18next.language, {
    year: "numeric",
    month: "long",
    day: "numeric",
    timeZone: "UTC",
  });

  return (
    <>
      <div className="col-lg-3 col-sm-6" key={release.id}>
        <div className="card card-overlay card-hover-overlay hover-shadow-lg hover-translate-y-n10">
          <figure className="figure">
            <ImageComponent
              placeholderHeight="350px"
              alt={release.title}
              src={release.imageFront}
              className="img-fluid"
            />
          </figure>
          <div className="card-img-overlay d-flex flex-column align-items-center p-0">
            <div className="overlay-text w-75 mt-auto p-4">
              <p className="lead" title={release.shortTitle}>
                {release.shortTitle}
              </p>
              <Link
                to={`/releases/${release.id}`}
                className="link link-underline-white font-weight-bold"
              >
                {t("home.latestNews.readMore")}
              </Link>
            </div>
            <div className="overlay-actions w-100 card-footer mt-auto d-flex justify-content-between align-items-center">
              {/* <div>
                <span className="h6 mb-0">{release.category}</span>
              </div> */}
              <div>
                <div className="actions text-center">
                  <span className="text-dark">{release.dateFormated}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IndividualReleaseSnippet;
