import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { fetchFilms } from "../../../actions";
import IndividualFilmSnippet from "./IndividualFilmSnippet";
import IndividualFilmSnippetSkeleton from "./IndividualFilmSnippetSkeleton";
import useDocumentTitle from "../useDocumentTitle";

const AllFilms = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  let { t } = useTranslation();
  useDocumentTitle(`${t("navigation.films")} - Black Letter Press`);
  useEffect(() => {
    const fetchScript = async () => {
      setIsLoading(true);
      await props.fetchFilms();
      setIsLoading(false);
    };

    fetchScript();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (props.fetchStatus !== 200 && props.fetchStatus !== null) {
    return (
      <section className="slice pt-5 pb-7 bg-section-secondary">
        <div className="container">
          <h3>{t("films.error.title")}</h3>
          <p>{t("films.error.description")}</p>
        </div>
      </section>
    );
  }

  const renderList = () => {
    if (isLoading) {
      return (
        <>
          <IndividualFilmSnippetSkeleton id={1} />
          <IndividualFilmSnippetSkeleton id={2} />
          <IndividualFilmSnippetSkeleton id={3} />
          <IndividualFilmSnippetSkeleton id={4} />
        </>
      );
    } else {
      return props.films.map((film) => {
        return <IndividualFilmSnippet film={film} key={film.id} />;
      });
    }
  };

  return (
    <section className="slice pt-5 pb-7 bg-section-secondary">
      <div className="container">
        <div className="row">{renderList()}</div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    films: Object.values(state.films),
    fetchStatus: state.fetchStatus.films,
  };
};

export default connect(mapStateToProps, { fetchFilms })(AllFilms);
