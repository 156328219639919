import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { User } from "react-feather";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import useDocumentTitle from "../public/useDocumentTitle";
import validateVerify from "./forgotPassword/validateVerify";
import RenderInput from "./_utils/RenderInput";
import { forgotPassword } from "../../actions";

const ForgotPassword = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  useDocumentTitle(`${t("forgotPassword.title")} - Black Letter Press`);

  useEffect(() => {
    if (props.isSignedIn) {
      navigate("/", { replace: true });
    }
  });

  const onSubmit = async (formValues) => {
    await props.forgotPassword(formValues);
  };

  return (
    <section>
      <div className="container d-flex flex-column">
        <div className="row align-items-center justify-content-center min-vh-100">
          <div className="col-md-6 col-lg-5 col-xl-4 py-6 py-md-0">
            <div>
              <div className="mb-5 text-center">
                <h6 className="h3 mb-1">{t("forgotPassword.title")}</h6>
                <p className="text-muted mb-0">
                  {t("forgotPassword.subtitle")}
                </p>
              </div>
              <span className="clearfix"></span>
              <form onSubmit={props.handleSubmit(onSubmit)}>
                <Field
                  name="email"
                  component={RenderInput}
                  label={t("forgotPassword.fields.email")}
                  placeholder={t("forgotPassword.fields.emailPlaceholder")}
                  customClass=""
                  fieldType="email"
                  icon={<User />}
                />
                <Field
                  name="emailConfirm"
                  component={RenderInput}
                  label={t("forgotPassword.fields.email")}
                  placeholder={t("forgotPassword.fields.emailPlaceholder")}
                  customClass="d-none"
                  fieldType="email"
                  icon={<User />}
                />
                <div className="mt-4">
                  <button
                    type="submit"
                    name="forgotPassVerifySubmit"
                    className="btn btn-block btn-primary"
                    disabled={props.submitting}
                  >
                    {t("forgotPassword.fields.submit")}
                  </button>
                </div>
              </form>
              {/* <!-- Links --> */}
              <div className="mt-4 text-center">
                <Link to="/login" className="small font-weight-bold">
                  {t("forgotPassword.signIn")}
                </Link>{" "}
                |{" "}
                <Link to="/request" className="small font-weight-bold">
                  {t("signIn.requestAccess")}
                </Link>
              </div>
              <div className="mt-5 text-center">
                <span className="small font-weight-bold">
                  {t("signIn.needHelp.title")}
                </span>
                <br />
                <span className="small">
                  {t("signIn.needHelp.description")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return { isSignedIn: state.auth.isSignedIn, userRole: state.auth.userRole };
};

const wrappedComponent = reduxForm({
  form: "forgotPassForm",
  validate: validateVerify,
})(ForgotPassword);

export default connect(mapStateToProps, { forgotPassword })(wrappedComponent);
