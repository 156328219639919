import React from "react";

const AdminPageTitle = (props) => {
  return (
    <section className="pt-5 bg-section-secondary">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-9">
            <div className="row align-items-center mb-4">
              <div className="col">
                <h2 className="mb-0">{props.children}</h2>
              </div>
              <div className="col-auto">{props.subtitle}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdminPageTitle;
