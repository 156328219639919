import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, reset } from "redux-form";

import FilmLinksElement from "./FilmLinksElement";
import TableComponent from "../_utils/TableComponent";
import {
  updateFilmLink,
  createFilmLink,
  deleteFilmLink,
} from "../../../../../actions";
import RenderInput from "../../../_utils/RenderInput";
import RenderDropdown from "../../../_utils/RenderDropdown";
import validate from "./validate";
import Swal from "sweetalert2";

const FilmLinks = ({
  links,
  updateFilmLink,
  filmId,
  submitting,
  handleSubmit,
  createFilmLink,
  deleteFilmLink,
}) => {
  const addLink = async (data, dispatch) => {
    data.filmId = filmId;
    await createFilmLink(data);
    dispatch(reset("createLinkForm"));
  };

  const editLinkScript = async (data) => {
    data.filmId = filmId;
    await updateFilmLink(data);
  };

  const deleteLink = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteScript({ filmId, id });
      }
    });
  };

  const deleteScript = async (data) => {
    await deleteFilmLink(data);
  };

  const loadLinks = () => {
    if (links && links.length > 0) {
      return links.map((el) => {
        return (
          <FilmLinksElement
            el={el}
            deleteScript={deleteLink}
            editScript={editLinkScript}
            key={`linkElement_${el.id}`}
          />
        );
      });
    } else {
      return (
        <>
          <tr>
            <td colSpan={3}>There are no external links!</td>
          </tr>
        </>
      );
    }
  };

  return (
    <>
      <section className="slice slice-sm">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <h5 className="mb-3">Film Links</h5>

              <form onSubmit={handleSubmit(addLink)}>
                <div className="row">
                  <div className="col-md-5 col-sm-6">
                    <Field
                      name="type"
                      label="Type"
                      defaultOption="Select..."
                      component={RenderDropdown}
                    >
                      <option value="facebook" key="facebook">
                        Facebook
                      </option>
                      <option value="instagram" key="instagram">
                        Instagram
                      </option>
                      <option value="twitter" key="twitter">
                        Twitter
                      </option>
                      <option value="tiktok" key="tiktok">
                        TikTok
                      </option>
                      <option value="imdb" key="imdb">
                        IMDb
                      </option>
                      <option value="officialSite" key="officialSite">
                        Official Site
                      </option>
                      <option value="soundtrack" key="soundtrack">
                        Soundtrack
                      </option>
                    </Field>
                  </div>
                  <div className="col-md-7 col-sm-6">
                    <Field
                      name="url"
                      component={RenderInput}
                      label="Link"
                      placeholder="Enter the URL here..."
                      customClass=""
                      fieldType="url"
                    />
                  </div>
                </div>

                <div className="mt-3">
                  <button
                    type="submit"
                    className="btn btn-sm btn-primary"
                    disabled={submitting}
                  >
                    Add
                  </button>
                </div>
              </form>
              <br />

              <div className="col-12">
                <TableComponent>
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>URL</th>
                      <th className="text-right">Actions</th>
                    </tr>
                  </thead>
                  <tbody>{loadLinks()}</tbody>
                </TableComponent>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const wrappedComponent = reduxForm({
  form: "createLinkForm",
  validate,
})(FilmLinks);

export default connect(null, {
  updateFilmLink,
  createFilmLink,
  deleteFilmLink,
})(wrappedComponent);
