import { SIGN_IN, SIGN_OUT } from "../actions/types";

const INITIAL_STATE = {
  isSignedIn: false,
  userId: null,
  userRole: null,
  userName: null,
  authToken: null,
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SIGN_IN:
      return {
        ...state,
        isSignedIn: true,
        userId: action.payload.id,
        userRole: action.payload.role,
        userName: action.payload.name,
        authToken: action.payload.authToken,
      };
    case SIGN_OUT:
      return {
        ...state,
        isSignedIn: false,
        userId: null,
        userRole: null,
        userName: null,
        authToken: null,
      };
    default:
      return state;
  }
};

export default authReducer;
