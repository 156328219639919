import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { useNavigate } from "react-router-dom";
import {
  Editor,
  EditorState,
  RichUtils,
  convertToRaw,
  convertFromRaw,
  ContentState,
} from "draft-js";
import "draft-js/dist/Draft.css";

import useDocumentTitle from "../../../public/useDocumentTitle";
import StyleBar from "../../_utils/StyleBar";
import AdminPageTitle from "../../AdminPageTitle";
import RenderInput from "../../_utils/RenderInput";
import RenderDropdown from "../../_utils/RenderDropdown";
import RenderUpload from "../../_utils/RenderUpload";
import validate from "./validate";
import { editRelease } from "./../../../../actions";

let reduxFormValues = {
  titleEn: null,
  titleEs: null,
  category: null,
  status: null,
  date: null,
};

const ReleaseEditContent = ({
  release,
  films,
  handleSubmit,
  submitting,
  editRelease,
}) => {
  let navigate = useNavigate();

  useDocumentTitle(release.titleEn);

  //Define states
  const [editorStateEnglish, setEditorStateEnglish] = useState(() =>
    EditorState.createWithContent(convertFromRaw(JSON.parse(release.contentEn)))
  );
  const [editorStateSpanish, setEditorStateSpanish] = useState(() =>
    EditorState.createWithContent(convertFromRaw(JSON.parse(release.contentEs)))
  );

  const [mediaContactsStateEnglish, setMediaContactsStateEnglish] = useState(
    () =>
      EditorState.createWithContent(
        convertFromRaw(JSON.parse(release.mediaContactsEn))
      )
  );
  const [mediaContactsStateSpanish, setMediaContactsStateSpanish] = useState(
    () =>
      EditorState.createWithContent(
        convertFromRaw(JSON.parse(release.mediaContactsEs))
      )
  );

  const [introContentStateEnglish, setIntroContentStateEnglish] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromText(release.introContentEn)
    )
  );
  const [introContentStateSpanish, setIntroContentStateSpanish] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromText(release.introContentEs)
    )
  );

  //Define input references
  const editorEnglish = useRef(null);
  const editorSpanish = useRef(null);
  const mediaContactsEnglish = useRef(null);
  const mediaContactsSpanish = useRef(null);
  const introContentEnglish = useRef(null);
  const introContentSpanish = useRef(null);

  //Define onChange functions
  const onChangeEditor = (stateElement, setStateElement) =>
    setStateElement(stateElement);

  //Define auto-focus
  function focusEditor(refElement) {
    refElement.current.focus();
  }

  //Handle Key Commands
  function handleKeyCommand(command, editorState, setStateElement) {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      // onChangeMediaContactsSpanish(newState);
      onChangeEditor(newState, setStateElement);
      return "handled";
    }

    return "not-handled";
  }

  //Submit script
  const onSubmit = async (formValues) => {
    console.log("Submitting...");
    // editorState.getCurrentContent().getPlainText("\u0001") //Removes spaces
    const newFormValues = {
      id: release.id,
      titleEn: formValues.titleEn,
      titleEs: formValues.titleEs,
      category: formValues.category,
      status: formValues.status,
      date: formValues.date,
      mediaContactsEn: JSON.stringify(
        convertToRaw(mediaContactsStateEnglish.getCurrentContent())
      ),
      mediaContactsEs: JSON.stringify(
        convertToRaw(mediaContactsStateSpanish.getCurrentContent())
      ),
      introContentEn: String(
        introContentStateEnglish.getCurrentContent().getPlainText()
      ),
      introContentEs: String(
        introContentStateSpanish.getCurrentContent().getPlainText()
      ),
      contentEn: JSON.stringify(
        convertToRaw(editorStateEnglish.getCurrentContent())
      ),
      contentEs: JSON.stringify(
        convertToRaw(editorStateSpanish.getCurrentContent())
      ),
    };

    if (formValues.image) {
      newFormValues.image = formValues.image;
    }

    try {
      await editRelease(newFormValues);
      navigate(`/admin/releases`, { replace: true });
    } catch (error) {
      console.log("There was an error");
      console.log(error);
    }
  };

  //Map films categories
  const getCategories = () => {
    return films.map((el) => {
      return (
        <option key={el.id} value={el.id}>
          {el.nameEn}
        </option>
      );
    });
  };

  //Populate the field values
  reduxFormValues.titleEn = release.titleEn;
  reduxFormValues.titleEs = release.titleEs;
  reduxFormValues.category = release.category;
  reduxFormValues.status = release.status;

  let formDate = new Date(release.date);
  const dd = String(formDate.getDate()).padStart(2, "0");
  const mm = String(formDate.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = formDate.getFullYear();
  formDate = yyyy + "-" + mm + "-" + dd;

  reduxFormValues.date = formDate;

  return (
    <>
      <AdminPageTitle>Edit Press Release</AdminPageTitle>
      <section className="slice slice-sm bg-section">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-9">
                <div className="row">
                  <div className="col-lg-12 pb-4">
                    <div>
                      {/* <!-- Title --> */}
                      <h5 className="mb-3">Basic Information</h5>

                      {/* <!-- General information --> */}
                      <div className="row">
                        <div className="col-md-12">
                          <Field
                            name="titleEn"
                            component={RenderInput}
                            label="Title (English)*"
                            placeholder="Enter the press release title..."
                            customClass=""
                            fieldType="text"
                          />
                        </div>
                        <div className="col-md-12">
                          <Field
                            name="titleEs"
                            component={RenderInput}
                            label="Title (Spanish)*"
                            placeholder="Escribe el título aquí..."
                            customClass=""
                            fieldType="text"
                          />
                        </div>
                        <div className="col-md-4">
                          <Field
                            name="category"
                            label="Category*"
                            defaultOption="Select..."
                            component={RenderDropdown}
                          >
                            {getCategories()}
                          </Field>
                        </div>
                        <div className="col-md-4">
                          <Field
                            name="status"
                            label="Status*"
                            defaultOption="Select..."
                            component={RenderDropdown}
                          >
                            <option value="public" key="public">
                              Published
                            </option>
                            <option value="draft" key="draft">
                              Draft
                            </option>
                          </Field>
                        </div>
                        <div className="col-md-4">
                          <Field
                            name="date"
                            component={RenderInput}
                            label="Date*"
                            // placeholder="01/01/2021"
                            customClass=""
                            fieldType="date"
                          />
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Media Contacts (English)
                            </label>
                            <div
                              onClick={() => focusEditor(mediaContactsEnglish)}
                            >
                              <Editor
                                ref={mediaContactsEnglish}
                                editorState={mediaContactsStateEnglish}
                                handleKeyCommand={(command, editorState) =>
                                  handleKeyCommand(
                                    command,
                                    editorState,
                                    setMediaContactsStateEnglish
                                  )
                                }
                                placeholder="Press &amp; publicity contacts..."
                                onChange={setMediaContactsStateEnglish}
                              />
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Media Contacts (Spanish)
                            </label>
                            <div
                              onClick={() => focusEditor(mediaContactsSpanish)}
                            >
                              <Editor
                                ref={mediaContactsSpanish}
                                editorState={mediaContactsStateSpanish}
                                handleKeyCommand={(command, editorState) =>
                                  handleKeyCommand(
                                    command,
                                    editorState,
                                    setMediaContactsStateSpanish
                                  )
                                }
                                placeholder="Contactos de prensa y publicity..."
                                onChange={setMediaContactsStateSpanish}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <Field
                            name="image"
                            component={RenderUpload}
                            label="Image*"
                            placeholder="Select a file..."
                            customClass=""
                            accept=".png,.jpg,.jpeg"
                            notes="Dimensions: 1920x1080px | File size limit: 25MB"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <h5 className="mb-3">Intro Summary (English)</h5>

                    <div className="row">
                      <div className="col-md-12 mb-5">
                        <div
                          onClick={() => focusEditor(introContentEnglish)}
                          // className="text-muted"
                        >
                          <Editor
                            ref={introContentEnglish}
                            editorState={introContentStateEnglish}
                            placeholder="Brief summary..."
                            onChange={setIntroContentStateEnglish}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <h5 className="mb-3">Intro Summary (Spanish)</h5>

                    <div className="row">
                      <div className="col-md-12 mb-5">
                        <div
                          onClick={() => focusEditor(introContentSpanish)}
                          // className="form-control"
                        >
                          <Editor
                            ref={introContentSpanish}
                            editorState={introContentStateSpanish}
                            placeholder="Breve resumen..."
                            onChange={setIntroContentStateSpanish}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div>
                      <h5 className="mb-3">Content (English)*</h5>

                      <div className="row">
                        <div className="col-md-12 mb-5">
                          {
                            <StyleBar
                              onChangeElement={(newState) =>
                                onChangeEditor(newState, setEditorStateEnglish)
                              }
                              stateElement={editorStateEnglish}
                            />
                          }

                          <div onClick={() => focusEditor(editorEnglish)}>
                            <Editor
                              ref={editorEnglish}
                              editorState={editorStateEnglish}
                              handleKeyCommand={(command, editorState) =>
                                handleKeyCommand(
                                  command,
                                  editorState,
                                  setEditorStateEnglish
                                )
                              }
                              placeholder="Start writing here..."
                              onChange={setEditorStateEnglish}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <h5 className="mb-3">Content (Spanish)*</h5>

                      <div className="row">
                        <div className="col-md-12 mb-5">
                          <StyleBar
                            onChangeElement={(newState) =>
                              onChangeEditor(newState, setEditorStateSpanish)
                            }
                            stateElement={editorStateSpanish}
                          />

                          <div onClick={() => focusEditor(editorSpanish)}>
                            <Editor
                              ref={editorSpanish}
                              editorState={editorStateSpanish}
                              handleKeyCommand={(command, editorState) =>
                                handleKeyCommand(
                                  command,
                                  editorState,
                                  setEditorStateSpanish
                                )
                              }
                              placeholder="Escribe aquí..."
                              onChange={setEditorStateSpanish}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-3">
                      <button
                        type="submit"
                        id="submitPost"
                        className="btn btn-sm btn-primary"
                        disabled={submitting}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </>
  );
};

const wrappedComponent = reduxForm({
  form: "releaseEditForm",
  validate,
  initialValues: reduxFormValues,
})(ReleaseEditContent);

export default connect(null, { editRelease })(wrappedComponent);
