import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import SignUpForm from "./signUp/SignUpForm";
import { signUp } from "../../actions";
import useDocumentTitle from "../public/useDocumentTitle";

const SignUp = (props) => {
  const { t } = useTranslation();

  useDocumentTitle(`${t("signUp.title")} - Black Letter Press`);

  const onSubmit = async (formValues) => {
    await props.signUp(formValues);
  };

  return (
    <section>
      <div className="container d-flex flex-column">
        <div className="row align-items-center justify-content-center min-vh-100">
          <div className="col-md-8 col-lg-5 py-6">
            <div>
              <div className="mb-5 text-center">
                <h6 className="h3 mb-1">{t("signUp.title")}</h6>
                <p className="text-muted mb-0">{t("signUp.disclaimer")}</p>
              </div>
              <span className="clearfix"></span>
              <SignUpForm onSubmit={onSubmit} />

              {/* <!-- Links --> */}
              <div className="mt-4 text-center">
                <small>{t("signUp.alreadyHaveAccount")}</small>{" "}
                <Link to="/login/" className="small font-weight-bold">
                  {t("signUp.signIn")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default connect(null, { signUp })(SignUp);
