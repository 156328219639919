import React from "react";
import { Field, reduxForm } from "redux-form";
import { useTranslation } from "react-i18next";
import * as Icon from "react-feather";

import validate from "./validate";
import RenderInputSignup from "../_utils/RenderInputSignup";

const FormSecondPage = (props) => {
  const { t } = useTranslation();

  const { handleSubmit, previousPage } = props;
  return (
    <form onSubmit={handleSubmit}>
      <small className="text-center">{t("signUp.fields.passwordHelper")}</small>
      <br />
      <br />
      <Field
        name="password"
        component={RenderInputSignup}
        label={`${t("signUp.fields.password")}*`}
        placeholder="********"
        customClass=""
        fieldType="password"
        icon={<Icon.Lock />}
      />
      <Field
        name="passwordRepeat"
        component={RenderInputSignup}
        label={`${t("signUp.fields.repeatPassword")}*`}
        placeholder="********"
        customClass=""
        fieldType="password"
        icon={<Icon.Lock />}
      />
      <div className="mt-4">
        <div className="row">
          <div className="col-6">
            <button
              type="button"
              className="btn btn-block btn-outline-primary"
              onClick={previousPage}
            >
              {t("signUp.buttons.previous")}
            </button>
          </div>
          <div className="col-6">
            <button type="submit" className="btn btn-block btn-primary">
              {t("signUp.buttons.next")}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default reduxForm({
  form: "signUpWizard",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
})(FormSecondPage);
