import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import "draft-js/dist/Draft.css";

import { fetchReleaseAdmin, fetchFilmsAdmin } from "../../../actions";
import ReleaseEditContent from "./edit/ReleaseEditContent";
import ReleaseEditSkeleton from "./edit/ReleaseEditSkeleton";

const ReleaseEdit = ({
  release,
  films,
  fetchReleaseAdmin,
  fetchFilmsAdmin,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  let { id } = useParams();

  useEffect(() => {
    const fetchContent = async (id) => {
      setIsLoading(true);
      //Fetch the release
      await fetchReleaseAdmin(id);

      //Fetch the films for the category dropdown
      await fetchFilmsAdmin();
      setIsLoading(false);
    };

    fetchContent(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isLoading) {
    return <ReleaseEditContent release={release} films={films} />;
  } else {
    return <ReleaseEditSkeleton />;
  }
};

const mapStateToProps = (state) => {
  return { release: state.bView.release, films: Object.values(state.bFilms) };
};

export default connect(mapStateToProps, { fetchReleaseAdmin, fetchFilmsAdmin })(
  ReleaseEdit
);
