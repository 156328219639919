import React from "react";

const Terms = () => {
  return (
    <>
      <section>
        <div className="container d-flex align-items-center text-center text-lg-left py-5 py-lg-6 border-bottom">
          <div className="col px-0">
            <div className="row justify-content-center">
              <div className="col-lg-8 text-center">
                <h2 className="h1 mb-2">Terms and Conditions</h2>
                <h6 className="font-weight-light text-muted">
                  Last updated: Apr 15th, 2021
                </h6>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="slice">
        <div className="container">
          <div className="row row-grid justify-content-between">
            <div className="col-lg-4">
              <div data-toggle="sticky" data-sticky-offset="50">
                <div className="card">
                  <div className="card-body">
                    <nav className="nav nav-pills flex-column" id="nav-terms">
                      <a
                        className="nav-link text-sm"
                        href="#section-1"
                        data-scroll-to
                        data-scroll-to-offset="130"
                      >
                        Introduction
                      </a>
                      <a
                        className="nav-link text-sm"
                        href="#section-2"
                        data-scroll-to
                        data-scroll-to-offset="130"
                      >
                        About https://press.blackletterfilms.com
                      </a>
                      <a
                        className="nav-link text-sm"
                        href="#section-3"
                        data-scroll-to
                        data-scroll-to-offset="130"
                      >
                        Use of Materials
                      </a>
                      <a
                        className="nav-link text-sm"
                        href="#section-4"
                        data-scroll-to
                        data-scroll-to-offset="130"
                      >
                        Contact Information
                      </a>
                      <a
                        className="nav-link text-sm"
                        href="#section-5"
                        data-scroll-to
                        data-scroll-to-offset="130"
                      >
                        Registration
                      </a>
                      <a
                        className="nav-link text-sm"
                        href="#section-6"
                        data-scroll-to
                        data-scroll-to-offset="130"
                      >
                        Access to the Site
                      </a>
                      <a
                        className="nav-link text-sm"
                        href="#section-7"
                        data-scroll-to
                        data-scroll-to-offset="130"
                      >
                        User IDs/Passwords
                      </a>
                      <a
                        className="nav-link text-sm"
                        href="#section-8"
                        data-scroll-to
                        data-scroll-to-offset="130"
                      >
                        External Links
                      </a>
                      <a
                        className="nav-link text-sm"
                        href="#section-9"
                        data-scroll-to
                        data-scroll-to-offset="130"
                      >
                        Security and Hacking
                      </a>
                      <a
                        className="nav-link text-sm"
                        href="#section-10"
                        data-scroll-to
                        data-scroll-to-offset="130"
                      >
                        Intellectual Property Rights and Restrictions
                      </a>
                      <a
                        className="nav-link text-sm"
                        href="#section-11"
                        data-scroll-to
                        data-scroll-to-offset="130"
                      >
                        Submissions, Postings and Email Communications
                      </a>
                      <a
                        className="nav-link text-sm"
                        href="#section-12"
                        data-scroll-to
                        data-scroll-to-offset="130"
                      >
                        Publicity and Confidentiality
                      </a>
                      <a
                        className="nav-link text-sm"
                        href="#section-13"
                        data-scroll-to
                        data-scroll-to-offset="130"
                      >
                        Termination
                      </a>
                      <a
                        className="nav-link text-sm"
                        href="#section-14"
                        data-scroll-to
                        data-scroll-to-offset="130"
                      >
                        Warranty Disclaimer
                      </a>
                      <a
                        className="nav-link text-sm"
                        href="#section-15"
                        data-scroll-to
                        data-scroll-to-offset="130"
                      >
                        Limitation of Liability
                      </a>
                      <a
                        className="nav-link text-sm"
                        href="#section-16"
                        data-scroll-to
                        data-scroll-to-offset="130"
                      >
                        Indemnification
                      </a>
                      <a
                        className="nav-link text-sm"
                        href="#section-17"
                        data-scroll-to
                        data-scroll-to-offset="130"
                      >
                        Dispute Resolution
                      </a>
                      <a
                        className="nav-link text-sm"
                        href="#section-18"
                        data-scroll-to
                        data-scroll-to-offset="130"
                      >
                        Severability
                      </a>
                      <a
                        className="nav-link text-sm"
                        href="#section-19"
                        data-scroll-to
                        data-scroll-to-offset="130"
                      >
                        Other Agreements
                      </a>
                      <a
                        className="nav-link text-sm"
                        href="#section-20"
                        data-scroll-to
                        data-scroll-to-offset="130"
                      >
                        Entire Agreement/Waiver
                      </a>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="mb-5">
                <h5 id="section-1">Introduction</h5>
                <p>
                  PLEASE READ THESE TERMS OF USE ("Terms", "Terms of Use", or
                  "Agreement") CAREFULLY BEFORE USING THIS SITE, AS THEY AFFECT
                  YOUR LEGAL RIGHTS AND OBLIGATIONS, INCLUDING, BUT NOT LIMITED
                  TO, WAIVERS OF RIGHTS, LIMITATION OF LIABILITY, AND YOUR
                  INDEMNITY TO US. THIS AGREEMENT REQUIRES THE USE OF
                  ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER
                  THAN COURTS, JURY TRIALS, OR className ACTIONS, AND LIMITS THE
                  REMEDIES AVAILABLE IN THE EVENT OF A DISPUTE.
                </p>
                <p>
                  This "Site" is owned and operated by Black Letter Films,
                  S.R.L. (“Black Letter Films”, "Black Letter", "we" or "us").
                  By accessing and using this Site, you agree to abide by the
                  Terms of Use. If you do not agree with any of the terms set
                  forth herein, then Black Letter Films will not approve your
                  request to register for access to the Site ("Registration
                  Request"), and you will not be permitted to use the Site.
                </p>
                <p>
                  We reserve the right, in our sole discretion, to modify, alter
                  or otherwise change the Terms of Use at any time. You agree
                  that we may notify you of modified terms or policies by
                  posting them on the Site, and agree that your continued use of
                  the Site after such notice constitutes your agreement to the
                  modified Terms of Use, which will govern your ongoing use of
                  the Site. Thus, you should review the posted Terms of Use each
                  time you use the Site. Any modifications to these Terms will
                  supersede the prior version for all activity occurring after
                  the revised version has been made available.
                </p>
              </div>
              <div className="mb-5">
                <h5 id="section-2">About https://press.blackletterfilms.com</h5>
                <p>
                  This Site is a secured trade web site for registrants
                  authorized by Black Letter Films (“Users”) to view, download
                  and/or otherwise utilize certain creative, publicity and other
                  marketing materials relating to motion pictures for which
                  Black Letter Films owns and/or controls distribution rights
                  (“Materials”) and/or to submit creative, publicity and other
                  marketing materials incorporating the Materials for Black
                  Letter Films’ approval.Users have the non-exclusive right to
                  use Materials solely in relation to the motion picture to
                  which the Materials directly relate (“Motion Picture”) and
                  subject to the restrictions on use set forth in these Terms of
                  Use in connection with the Motion Picture’s theatrical and
                  home entertainment release in the territory or territories for
                  which Black Letter Films has granted the User access to the
                  Site and Materials (“Territory”).
                </p>
              </div>
              <div className="mb-5">
                <h5 id="section-3">Use of Materials</h5>
                <p>
                  The Materials and other information made available on the Site
                  may be used by you only for the purposes set forth in these
                  Terms of Use. You agree to comply with the Terms of Use and
                  all policies, restrictions and procedures posted by Black
                  Letter Films on the Site from time to time regarding the use,
                  publication, posting and/or dissemination of such Materials
                  and Information (as defined below).
                </p>
                <p>
                  You acknowledge our collection and use of your information as
                  described in the Privacy Policy applicable to the Site{" "}
                  <a
                    href="https://blackletterfilms.com/privacy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://blackletterfilms.com/privacy
                  </a>
                  . Unless agreed otherwise in writing in advance by Black
                  Letter Films, if you are granted access to the Site by Black
                  Letter Films, you must observe (and ensure that everyone
                  within your organization who has access to the Materials is
                  aware of and observes)
                </p>
                <p>
                  (a) any specific requirements set out on the Site in relation
                  to any particular Materials; and
                </p>
                <p>
                  (b) the following: - Materials may only be used in connection
                  with the customary reviewing of, publicizing of, commenting on
                  and/or providing coverage of the Motion Picture in association
                  with the Motion Picture’s theatrical and home entertainment
                  release in the Territory, such as by means of: (i) broadcast,
                  print, online, mobile and internet; (ii) other means of
                  publication, both electronic and non-electronic; and (iii)
                  other authorized promotional and publicity opportunities and
                  outlets.
                </p>
                <ul>
                  <li>
                    Materials will be available for use until Black Letter’s
                    discretion. The Materials must be stored safely to prevent
                    unauthorized use, access or copying during this time and
                    then destroyed or returned to Black Letter Films on Black
                    Letter’s request. You may request Black Letter Films to
                    provide in its discretion, or, Black Letter Films may
                    provide via the Site, at its discretion, access to Materials
                    for Motion Pictures which are being re-released theatrically
                    after the period above has expired or for Motion Pictures
                    which are not being re-released but are the subject of
                    coverage that would have qualified as a permitted use in
                    association with the initial theatrical release of the
                    Motion Picture.
                  </li>
                  <li>
                    Any use of the Materials must include all logos, symbols,
                    credits, artwork, copyright notices and/or other wording
                    requested by Black Letter Films and/or included with the
                    Materials on the Site.
                  </li>
                  <li>
                    Materials may be reproduced only by you in or through the
                    publication or media outlet, news organization, society
                    and/or directly associated website, in each case
                    corresponding to those of which were submitted and approved
                    as part of your Registration Request. The Materials are not
                    to be sold, loaned, transferred or syndicated to any third
                    party without Black Letter Films' prior written approval.
                  </li>
                  <li>
                    Except for the customary re-sizing of still images and the
                    editing of moving footage for length, you must maintain the
                    original integrity of the Materials provided to you and use
                    as is. The Materials may not be altered, resequenced,
                    reordered, decompiled, edited or modified in any way
                    (including, without limitation, by altering any encryption,
                    or any burnt-in/embedded warnings or watermarks, whether
                    visible or invisible, and however located, encoded or
                    displayed). You must first obtain written permission and
                    approval from Black Letter Films for any changes to the
                    Materials.
                  </li>
                  <li>
                    The Materials, or any elements thereof, may not be used to
                    create new works of any kind or nature, including, without
                    limitation, those which are derivative of the Materials,
                    unless you have received Black Letter Films’ prior written
                    authorization.
                  </li>
                  <li>
                    The Materials shall not be used for any purposes other than
                    permitted under these Terms of Use, including, without
                    limitation, any way which: (i) parodies, ridicules or
                    satirizes either the Motion Picture or any material on which
                    the Motion Picture is based or the creators of the Motion
                    Picture or such material; (ii) libels, defames, invades
                    privacy, stalks, is obscene, pornographic, racist, abusive,
                    harassing or threatening; (iii) seeks to exploit or harm
                    children by exposing them to inappropriate content, asking
                    for personally identifiable information from them or
                    otherwise; (iv) infringes any intellectual property or other
                    right of any person or entity; (v) violates or may be
                    considered to violate any laws; (vi) advocates illegal
                    activity; (vii) is used in any advertisements other than
                    authorized promotional, marketing and publicity conducted in
                    association with the theatrical and home entertainment
                    release of the Motion Picture; (viii) is used in connection
                    with any advertisement, competition, contest, lottery, prize
                    draw, quiz, trivia game, promotion, event, organization
                    (including that organization’s logo), product, services;
                    (ix) is used in any publication, special editions, website
                    or microsite that is primarily and/or substantially devoted
                    to or themed around the Motion Picture to which the
                    Materials relate; and (x) is used as part of in combination
                    with any other work (e.g., “mash up”).
                  </li>
                  <li>
                    Any use of such Materials to create packaging is strictly
                    prohibited, unless separately agreed between you and Black
                    Letter Films in writing.
                  </li>
                  <li>
                    All rights not expressly granted to you are reserved
                    exclusively to Black Letter Films and/or its affiliates and
                    licensors and you agree not to use any Materials in any way
                    not authorized.You further acknowledge and agree that Black
                    Letter Films owns and retains all right, title and interest
                    in each and every copy of the Materials made by you or on
                    your behalf.
                  </li>
                </ul>
              </div>
              <div className="mb-5">
                <h5 id="section-4">Contact Information</h5>
                <p>
                  Should you require Black Letter Films’ assistance, such as
                  with your Account Information (as defined below) or
                  Registration Information (as defined below), with instructions
                  on how to use the Site or with any questions about whether
                  your proposed use of the Materials is permitted under these
                  Terms of Use, please contact Black Letter Films at:
                </p>
                <p>
                  <a
                    href="mailto:press@blackletterfilms.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    press@blackletterfilms.com
                  </a>
                  <br />
                  <a
                    href="tel:(829) 946-6411"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    (829) 946-6411
                  </a>
                </p>
              </div>
              <div className="mb-5">
                <h5 id="section-5">Registration</h5>
                <p>
                  In order to access the download sections and private projects
                  the Site, you must first provide the information required to
                  complete the Registration Request on the Registration Request
                  page (“Registration Information”), and Black Letter Films must
                  approve your Registration Request. Black Letter Films reserves
                  the right at any time, in its sole discretion, without notice,
                  to deny access to the Site or any part thereof, not to approve
                  the Registration Request or to rescind a previously approved
                  Registration Request. You agree, at all times, to: (a) provide
                  current, complete, true and accurate Registration Information;
                  (b) maintain and update your Registration Information as
                  required to keep it current, complete and accurate; and (c)
                  provide additional information about you and your organization
                  which is pertinent to your use of the Site and the Materials
                  thereon, as may be requested by Black Letter Films from time
                  to time.
                </p>
                <p>
                  For security and anti-piracy reasons, Black Letter Films also
                  reserves the right to independently verify that the
                  Registration Information in your Registration Request is
                  accurate, including, without limitation, by contacting any
                  publication, media outlet, news organization, society and/or
                  directly associated website referenced in the Registration
                  Information to verify your association with same.
                </p>
              </div>
              <div className="mb-5">
                <h5 id="section-6">Access to the Site</h5>
                <p>
                  If we approve your Registration Request, we will send you an
                  electronic message granting you access to the Site and
                  confirming your user identification and password submitted
                  during the registration process. Black Letter Films shall have
                  the right, in its sole discretion, to refuse or restrict
                  anyone from access to any or all areas of the Site at any time
                  for any reason, without notice, whether or not such access has
                  been granted at any time for previous Motion Pictures or the
                  same Motion Picture.
                </p>
                <p>
                  Black Letter Films shall also have the right, in its sole
                  discretion, to grant you access to the Site but restrict or
                  limit without notice the Materials you may access and/or have
                  the ability to download or otherwise utilize from the Site,
                  whether or not such access has been granted at any time for
                  other Motion Pictures or previously for the same Motion
                  Picture or Materials.
                </p>
              </div>
              <div className="mb-5">
                <h5 id="section-7">User IDs/Passwords</h5>
                <p>
                  Black Letter Films reserves the right to require you to
                  periodically change your user identification and password or
                  to require you to comply with additional or different access
                  and authentication requirements. You agree to exercise
                  reasonable care and control to maintain the security of your
                  user identification, password, character preferences and
                  account with Black Letter Films (collectively, "Account
                  Information"). You shall not disclose your Account Information
                  to anyone else, and you shall not use anyone else's Account
                  Information, even if Black Letter Films has granted such
                  person or someone from his or her organization access to the
                  Site. You agree to notify us immediately about any
                  unauthorized use of your Account Information and/or the
                  Materials any breach of security, such as for example only,
                  someone stealing your Account Information, someone gaining
                  unauthorized access to the Site by using your Account
                  Information and/or someone hacking into the Site through a
                  security breach affecting your computer, network, hardware
                  and/or software. You further agree that Black Letter Films
                  shall not be responsible for your failure to comply with this
                  Section or any loss or damage arising out of, or related to,
                  your use of your Account Information by you or anyone other
                  than Black Letter Films
                </p>
              </div>
              <div className="mb-5">
                <h5 id="section-8">External Links</h5>
                <p>
                  You may not provide an external direct hypertext link from
                  your site to this Site without our permission. To request such
                  permission, contact us and provide your name, address and
                  telephone number, and a description of your site and the
                  reason why you would like to establish a link to this Site.
                </p>
              </div>
              <div className="mb-5">
                <h5 id="section-9">Security and Hacking</h5>
                <p>
                  You shall not violate or attempt to violate or authorize
                  anyone else to violate or attempt to violate the security of
                  the Site or to disrupt or interrupt the operation or
                  interactivity of the Site. Violations of the foregoing may
                  result in civil or criminal liability. You are solely
                  responsible for the security of your communications to and
                  through the Site.
                </p>
              </div>
              <div className="mb-5">
                <h5 id="section-10">
                  Intellectual Property Rights and Restrictions
                </h5>
                <p>
                  The Site and all materials on the Site (including but not
                  limited to, the Materials, Download Software, and other
                  information, such as text, images, graphics, icons,
                  illustrations, photographs, designs, logos, audio clips, and
                  video clips) (“Information”) are subject to copyright,
                  trademark, service mark and/or other intellectual property
                  rights and are protected by U.S. and international patent,
                  trademark, copyright and trade secret laws, and any other
                  intellectual property or proprietary rights recognized in any
                  country or jurisdiction worldwide including, without
                  limitation, moral rights and similar rights (collectively,
                  “Intellectual Property Rights”), and all such Intellectual
                  Property Rights are owned and controlled by Black Letter
                  Films, its parent and/or affiliated companies, or by other
                  parties that have licensed their material to Black Letter
                  Films, its parent and/or affiliated companies. Use of the
                  Intellectual Property on this Site for any purpose other than
                  as permitted under these Terms of Use constitutes a violation
                  of the applicable Intellectual Property Rights and is
                  prohibited. Materials may not otherwise be modified, copied,
                  reproduced, published, republished, uploaded, posted,
                  transmitted, retransmitted, distributed, sold or publicly
                  exhibited, in whole or in part, in any manner. The compilation
                  of all content on this Site is the exclusive property of Black
                  Letter Films and is protected by U.S. and international
                  copyright laws. Also, to the extent permitted by applicable
                  laws, decompiling, reverse engineering, disassembling or
                  otherwise reducing the code used in any software on this Site
                  into a readable form in order to examine the construction of
                  such software and/or to copy or create other products based in
                  whole or in part on such software, is prohibited. You
                  acknowledge and agree that you do not acquire any ownership
                  rights by virtue of accessing and/or downloading any content
                  or material from the Site and that all rights not expressly
                  granted hereunder are expressly reserved to Black Letter Films
                </p>
              </div>
              <div className="mb-5">
                <h5 id="section-11">
                  Submissions, Postings and Email Communications
                </h5>
                <p>
                  Black Letter Films respects the privacy concerns of the users
                  of the Site. However, to optimize web pages for each user's
                  computer and to provide an enhanced online experience, certain
                  non-personal information concerning the visitors of the Site
                  is recorded through the standard operation of Black Letter
                  Films’ Internet servers. (Information tracked includes,
                  without limitation, the type of browser being used by the
                  visitor, the type of operating system being used by the
                  visitor, and the domain name of the visitor's Internet service
                  provider). In addition, E-mail addresses and other personally
                  identifiable data about you and/or your organization are known
                  to Black Letter Films only when voluntarily submitted as part
                  of the Account Information and Registration Information.
                </p>
                <p>
                  Subject to the terms of Black Letter Films' Privacy Policy,
                  personally identifiable information that may be collected in
                  connection with your submission of Account Information and
                  Registration Information and with your access and use of the
                  Site shall be retained by Black Letter Films but shall not be
                  sold or otherwise transferred outside of Black Letter Films
                  except as may be described in Black Letter Films’ Privacy
                  Policy. For further information please see Black Letter Films’
                  Privacy Policy{" "}
                  <a
                    href="https://blackletterfilms.com/privacy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://blackletterfilms.com/privacy
                  </a>
                  .
                </p>
                <p>
                  To the extent that you submit e-mail, postings and/or other
                  electronic communications/messaging (“User Communications”) to
                  Black Letter Films, and such User Communications contain any
                  notes, messages, postings, ideas, suggestions, concepts,
                  artwork, images or other materials (collectively,
                  "Submissions"), such Submissions shall be deemed irrevocable
                  gifts to Black Letter Films and shall become the property of
                  Black Letter Films, and Black Letter Films shall, subject to
                  any intellectual property rights or contractual obligations of
                  which you advise Black Letter Films in writing with the
                  Submission, be exclusively entitled to use and exploit the
                  Submissions (without payment to, consideration for, or
                  liability to the individual or entity responsible for the
                  Submissions) throughout the universe in perpetuity or for the
                  duration of legal protection, as applicable, in all media,
                  markets and languages, and in any manner, for any purpose, and
                  through any methods or means now known or hereafter devised.
                </p>
                <p>
                  You hereby represent and warrant that: i) you hold all the
                  necessary rights, licenses, consents and permissions required
                  to make any and all Submissions that you submit; ii) any and
                  all Submissions that you submit do not violate, breach or
                  infringe any laws, regulations or codes or otherwise infringe
                  anyone’s legal rights (including, but not limited to, those of
                  confidentiality, defamation, libel, personality, publicity,
                  privacy and intellectual property).
                </p>
              </div>
              <div className="mb-5">
                <h5 id="section-12">Publicity and Confidentiality</h5>
                <p>
                  Site users shall in no event make, cause or authorize the
                  disclosure, by any means and/or media, of any news stories,
                  public announcements, public communications, public
                  references, advertisements, claims of endorsement, or trade
                  references relating directly or indirectly to the Site except
                  as expressly permitted pursuant to these Terms of Use or any
                  Other Agreements (as defined below)
                </p>
              </div>
              <div className="mb-5">
                <h5 id="section-13">Termination</h5>
                <p>
                  Without limiting any of Black Letter Films’ other rights
                  herein to control access to and use of the Site, either you or
                  Black Letter Films may terminate these Terms of Use without
                  notice for any reason at any time. Black Letter Films may and
                  will terminate your access to, and use of, the Site
                  immediately, if, in its sole discretion, Black Letter Films
                  believes that your conduct fails to conform to these Terms of
                  Use. Black Letter Films reserves the right to investigate
                  suspected violations of these Terms of Use, including, without
                  limitation, any violation arising from any Submission, posting
                  or User Communication that you make or cause to be made by
                  others.
                </p>
              </div>
              <div className="mb-5">
                <h5 id="section-14">Warranty Disclaimer</h5>
                <p>
                  YOU ACKNOWLEDGE THAT YOU ARE A “PROFESSIONAL” AND NOT A
                  “NON-PROFESSIONAL” OR A “CONSUMER” FOR THE PURPOSES OF
                  APPLICABLE LAW. YOU USE THE SITE, THE INFORMATION (INCLUDING,
                  WITHOUT LIMITATION, THE DOWNLOAD SOFTWARE), HARDWARE, MEANS OF
                  COMMUNICATION/INTERFACE AND MATERIALS SOLELY AT YOUR OWN RISK.
                  NOTWITHSTANDING ANYTHING TO THE CONTRARY, THE SITE, THE
                  INFORMATION (INCLUDING, WITHOUT LIMITATION, THE DOWNLOAD
                  SOFTWARE), HARDWARE, MEANS OF COMMUNICATION/INTERFACE AND
                  MATERIALS ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS,
                  WITHOUT WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED INCLUDING,
                  WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY,
                  FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT OR
                  NON-MISAPPROPRIATION OF INTELLECTUAL PROPERTY RIGHTS OF A
                  THIRD PARTY, TITLE, CUSTOM, TRADE, QUIET ENJOYMENT, ACCURACY
                  OR COMPLETENESS OF INFORMATIONAL CONTENT, OR SYSTEM
                  INTEGRATION OR COMPATIBILITY. BLACK LETTER FILMS DOES NOT
                  WARRANT THAT THE SITE, ANY SOFTWARE, INFORMATION, HARDWARE,
                  MEANS OF COMMUNICATION/INTERFACE, MATERIALS OR ANY PART
                  THEREOF, WILL BE AVAILABLE, COMPATIBLE OR OPERATE IN AN
                  UNINTERRUPTED OR ERROR-FREE MANNER, THAT ERRORS OR DEFECTS IN
                  THEM WILL BE DISCOVERED OR CORRECTED, OR THAT THEY ARE FREE OF
                  VIRUSES OR OTHER HARMFUL COMPONENTS. BY MEANS OF EXAMPLE AND
                  WITHOUT LIMITATION, BLACK LETTER FILMS DOES NOT WARRANT OR
                  REPRESENT THAT THE SITE, THE SOFTWARE, HARDWARE, MEANS OF
                  COMMUNICATION/INTERFACE, MATERIALS, INFORMATION OR OTHER
                  RELATED INFORMATION, OR ANY PART THEREOF, ARE OR WILL BE
                  AVAILABLE FROM BLACK LETTER FILMS; ARE OR WILL BE AVAILABLE IN
                  ANY SPECIFIC TERRITORY; OR ARE OR WILL BE AVAILABLE AT ANY
                  GIVEN TIME OR OVER ANY GIVEN PERIOD OF TIME. BLACK LETTER
                  FILMS ALSO DOES NOT GUARANTEE THE QUALITY, ACCURACY, OR
                  TIMELINESS OF ANY THIRD PARTY SITES THAT LINK TO OR FROM THIS
                  SITE NOR DO WE ENDORSE OR ADOPT ANY OF THE VIEWS EXPRESSED OR
                  OF THE PRODUCTS AND SERVICES SOLD ON THOSE SITES. SOME
                  JURISDICTIONS DO NOT ALLOW EXCLUSION OF IMPLIED WARRANTIES, SO
                  THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.
                </p>
              </div>
              <div className="mb-5">
                <h5 id="section-15">Limitation of Liability</h5>
                <p>
                  BLACK LETTER FILMS DOES NOT ACCEPT ANY LIABILITY FOR ANY LOSS
                  OR DAMAGE (DIRECT, INDIRECT, PUNITIVE, ACTUAL, CONSEQUENTIAL,
                  INCIDENTAL, SPECIAL, EXEMPLARY OR OTHERWISE) RESULTING FROM
                  ANY USE OF, OR INABILITY TO USE, THE SITE, THE INFORMATION
                  (INCLUDING WITHOUT LIMITATION, THE DOWNLOAD SOFTWARE),
                  HARDWARE, MEANS OF COMMUNICATION/INTERFACE, THE MATERIAL,
                  RELATED INFORMATION, FACILITIES, SERVICES OR OTHER CONTENT ON
                  THE SITE, REGARDLESS OF THE BASIS UPON WHICH LIABILITY IS
                  CLAIMED AND EVEN IF BLACK LETTER FILMS HAS BEEN ADVISED OF THE
                  POSSIBILITY OF SUCH LOSS OR DAMAGE. WITHOUT LIMITATION, YOU
                  (AND NOT BLACK LETTER FILMS) ASSUME THE ENTIRE COST OF ALL
                  NECESSARY SERVICING, REPAIR OR CORRECTION IN THE EVENT OF ANY
                  SUCH LOSS OR DAMAGE. IF APPLICABLE LAW DOES NOT ALLOW ALL OR
                  ANY PART OF THE ABOVE LIMITATION OF LIABILITY TO APPLY TO YOU,
                  THE LIMITATIONS WILL APPLY TO YOU ONLY TO THE EXTENT PERMITTED
                  BY APPLICABLE LAW.
                </p>
              </div>
              <div className="mb-5">
                <h5 id="section-16">Indemnification</h5>
                <p>
                  YOU SHALL INDEMNIFY, DEFEND AND HOLD HARMLESS BLACK LETTER
                  FILMS AND ITS PARENT, SUBSIDIARIES, AFFILIATES, MEMBERS,
                  DIRECTORS, OFFICERS, SHAREHOLDERS, EMPLOYEES, REPRESENTATIVES,
                  AGENTS, ATTORNEYS, SUCCESSORS AND ASSIGNS FROM AND AGAINST ANY
                  AND ALL CLAIMS, LIABILITIES, OBLIGATIONS, JUDGMENTS, CAUSES OF
                  ACTIONS, COSTS AND EXPENSES (INCLUDING REASONABLE ATTORNEYS'
                  FEES) ARISING OUT OF YOUR USE OF THE SITE IN VIOLATION OF
                  THESE TERMS OF USE OR THE INFRINGEMENT BY YOU OF ANY
                  INTELLECTUAL PROPERTY RIGHTS OF ANY PERSON OR ENTITY.
                </p>
                <p>
                  Neither Black Letter Films, nor its affiliated or related
                  entities, nor its content providers, shall be responsible or
                  liable to any person or entity whatsoever (including, without
                  limitation, persons or entities who may use or rely on the
                  information in the Site) for any losses, costs, damages
                  (whether actual, consequential, punitive or otherwise),
                  injuries, claims, actions or liabilities of any kind or nature
                  whatsoever based upon or resulting from any use of the Site,
                  or any use of or reliance upon any information provided on the
                  Site.
                </p>
              </div>
              <div className="mb-5">
                <h5 id="section-17">Dispute Resolution</h5>
                <p>
                  You and Black Letter Films each agree that the substantive
                  laws of the State of Delaware, USA will govern with respect to
                  all matters relating to or arising out of these Terms of Use,
                  or the use (or inability to use) the Site, and that such laws
                  will apply without regard to principles of conflict of laws.
                </p>
                <p>
                  You and Black Letter Films agree that any disputes arising out
                  of these Term of Use, or the use (or inability to use) the
                  Site, shall be submitted to final, binding arbitration
                  conducted in Dover County under the Expedited Arbitration
                  Procedures Rules and Procedures of the Judicial Arbitration
                  and Mediation Services Inc. (“JAMS”) before a single, neutral
                  arbitrator who is a former or retired Delaware state or
                  federal court judge with experience in Entertainment matters
                  who shall follow Delaware law and the Federal Rules of
                  Evidence and have no authority to award punitive damages.
                  Either You or Black Letter Films may enforce a final
                  arbitration award in any court of competent jurisdiction in
                  Dover County, including an award of costs, fees and expenses
                  incurred in enforcing the award. Notwithstanding the
                  foregoing, either You or Black Letter Films shall be entitled
                  to seek injunctive relief (unless otherwise precluded by any
                  other provision of this Agreement) in the state and federal
                  courts of Delaware. Black Letter Films does not warrant or
                  represent that the Site complies, in whole or in part, with
                  any foreign laws, if and to the extent that foreign laws are
                  applicable. Those who choose to access the Site from locations
                  outside of the United States of America do so at their own
                  risk, and Black Letter Films disclaims all liability
                  associated with such access.
                </p>
              </div>
              <div className="mb-5">
                <h5 id="section-18">Severability</h5>
                <p>
                  Should any of the terms and conditions of these Terms of Use
                  be held invalid, that invalid provision shall be construed to
                  be consistent with the applicable law, and in a manner so as
                  to remain consistent with the original intent of Black Letter
                  Films' Provisions not otherwise held invalid shall remain in
                  force and effect.
                </p>
              </div>
              <div className="mb-5">
                <h5 id="section-19">Other Agreements</h5>
                <p>
                  If you are currently a party to, or become a party to, another
                  agreement with Black Letter Films that governs your access to
                  and use of any of the Materials and Information available on
                  or through the Site (“Other Agreement”), the terms of the
                  Other Agreement will supersede these Terms of Use to the
                  extent inconsistent.
                </p>
              </div>
              <div className="mb-5">
                <h5 id="section-20">Entire Agreement/Waiver</h5>
                <p>
                  Except to the extent stated in the previous paragraph with
                  respect to Other Agreements, these Terms of Use constitutes
                  the entire agreement between you and Black Letter Films with
                  respect to your use of the Site, superseding any prior
                  agreements between you and Black Letter Films relating to such
                  subject matter. The failure of Black Letter Films to enforce
                  any provision of these Terms of Use shall not prevent it from
                  enforcing such provision on any other occasion. No waiver,
                  express or implied, of any breach of these Terms of Use shall
                  be deemed a continuing waiver of such breach. Nothing
                  contained in these Terms of Use shall create any association,
                  partnership, joint venture, or a principal-agent relationship
                  between the Parties.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Terms;
