import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ArrowRight } from "react-feather";
import { fetchFilmsFront, fetchReleasesFront } from "../../../actions";
import IndividualFilmSnippet from "../films/IndividualFilmSnippet";
import IndividualReleaseSnippet from "./_releases/IndividualReleaseSnippet";
import useDocumentTitle from "../useDocumentTitle";

const MainPage = (props) => {
  const { t } = useTranslation();
  useDocumentTitle(`Black Letter Press`);

  useEffect(() => {
    props.fetchFilmsFront();
    props.fetchReleasesFront();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderReleases = () => {
    return props.releasesFeatured.map((release) => {
      return <IndividualReleaseSnippet release={release} />;
    });
  };

  const renderFilms = () => {
    if (
      props.fetchStatus.filmsFeatured !== 200 &&
      props.fetchStatus.filmsFeatured !== null
    ) {
      return (
        <section className="slice pt-5 pb-7 bg-section-secondary">
          <div className="container">
            <h4>{t("films.error.title")}</h4>
            <p>{t("films.error.description")}</p>
          </div>
        </section>
      );
    } else {
      return props.filmsFeatured.map((film) => {
        return <IndividualFilmSnippet film={film} />;
      });
    }
  };

  return (
    <>
      <section className="slice pt-0 overflow-hidden-x">
        <div className="container">
          {/* <!-- Section title --> */}
          <div className="row pt-5 mb-5 justify-content-center text-center">
            <div className="col-lg-8 col-md-10">
              <h2 className=" mt-4">{t("home.latestNews.title")}</h2>
              <div className="mt-2">
                <p className="lead lh-180">
                  {t("home.latestNews.description")}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">{renderReleases()}</div>
        </div>
        <div className="mt-5 text-center">
          <Link to="/releases/" className="text-primary text-underline--dashed">
            {t("home.latestNews.seeMoreNews")}
            <ArrowRight className="ml-2" />
          </Link>
        </div>
      </section>

      <section className="slice pt-5 pb-7 bg-section-secondary">
        <div className="container">
          {/* <!-- Section title --> */}
          <div className="row mb-5 justify-content-center text-center">
            <div className="col-lg-8 col-md-10">
              <h2 className=" mt-4">{t("home.latestProjects")}</h2>
            </div>
          </div>
          <div className="row">{renderFilms()}</div>
          <div className="mt-5 text-center">
            <Link to="/films/" className="btn btn-outline btn-primary">
              {t("home.seeAllProjects")}
              <ArrowRight className="ml-2" />
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    releasesFeatured: Object.values(state.releasesFeatured),
    filmsFeatured: Object.values(state.filmsFeatured),
    fetchStatus: state.fetchStatus,
  };
};

export default connect(mapStateToProps, {
  fetchFilmsFront,
  fetchReleasesFront,
})(MainPage);
