import React from "react";
// import { useTranslation } from "react-i18next";

const RenderError = ({ error, touched }) => {
  //   const { t } = useTranslation();
  if (touched && error) {
    return (
      <div className="alert alert-danger" role="alert">
        {error}
      </div>
    );
  }
};

export default RenderError;
