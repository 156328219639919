import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ImageComponent from "./ImageComponent";

const FestivalLaurels = ({ laurels }) => {
  const sliderSettings = {
    autoplay: true,
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  if (laurels && laurels.length > 1) {
    const renderLaurels = () => {
      return laurels.map((laurel) => {
        return (
          <div key={`image_${laurel.id}`}>
            <ImageComponent
              placeholderHeight="60px"
              src={laurel.url}
              title={laurel.festivalName}
              alt={laurel.festivalName}
              width="150px"
            />
          </div>
        );
      });
    };

    return (
      <div className="row pl-5 pb-5">
        <div className="col-12">
          <div className="text-center">
            <Slider {...sliderSettings}>{renderLaurels()}</Slider>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default FestivalLaurels;
