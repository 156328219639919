import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, reset } from "redux-form";
import Swal from "sweetalert2";

import FilmFilesElement from "./FilmFilesElement";
import TableComponent from "../_utils/TableComponent";
import RenderDropdown from "../../../_utils/RenderDropdown";
import RenderUpload from "../../../_utils/RenderUpload";
import validate from "./validate";
import { createFilmFile, deleteFilmFile } from "../../../../../actions";

const FilmFiles = ({
  epks,
  onePagers,
  pressNotes,
  submitting,
  handleSubmit,
  filmId,
  createFilmFile,
  deleteFilmFile,
}) => {
  const addFile = async (data, dispatch) => {
    let newType, newLang;

    switch (data.filmType) {
      case "profile_en":
        newType = "onePagerLinks";
        newLang = "en";
        break;
      case "profile_es":
        newType = "onePagerLinks";
        newLang = "es";
        break;
      case "epk_en":
        newType = "epkLinks";
        newLang = "en";
        break;
      case "epk_es":
        newType = "epkLinks";
        newLang = "es";
        break;
      case "notes_en":
        newType = "pressNotesLinks";
        newLang = "en";
        break;
      case "notes_es":
        newType = "pressNotesLinks";
        newLang = "es";
        break;
      default:
        console.error("ERROR WITH THE DATA TYPE");
    }

    data.filmId = filmId;
    data.type = newType;
    data.lang = newLang;

    await createFilmFile(data);
    dispatch(reset("createFilmFileForm"));
  };

  const deleteFile = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `Deleting this file is an irreversible action!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        triggerDelete(id);
      }
    });
  };

  const triggerDelete = async (id) => {
    const data = {
      id,
      filmId,
    };
    try {
      await deleteFilmFile(data);
    } catch (error) {
      console.log(error);
    }
  };

  const loadFiles = (array) => {
    if (array && array.length > 0) {
      return array.map((el) => {
        return (
          <FilmFilesElement
            el={el}
            deleteScript={deleteFile}
            key={`fileElement_${el.id}`}
          />
        );
      });
    } else {
      return (
        <>
          <tr>
            <td colSpan={2}>There are no files associated to this category!</td>
          </tr>
        </>
      );
    }
  };

  return (
    <>
      <section className="slice slice-sm">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <h5 className="mb-3">Film Files</h5>

              <form onSubmit={handleSubmit(addFile)}>
                <div className="row">
                  <div className="col-md-5 col-sm-6">
                    <Field
                      name="filmType"
                      label="Type"
                      defaultOption="Select..."
                      component={RenderDropdown}
                    >
                      <option value="profile_en">One-Pager (English)</option>
                      <option value="profile_es">One-Pager (Spanish)</option>
                      <option value="epk_en">Press Kit (English)</option>
                      <option value="epk_es">Press Kit (Spanish)</option>
                      <option value="notes_en">Press Notes (English)</option>
                      <option value="notes_es">Press Notes (Spanish)</option>
                    </Field>
                  </div>
                  {/* <!-- File Uploader --> */}
                  <div className="col-md-7 col-sm-6">
                    <Field
                      name="filmFile"
                      component={RenderUpload}
                      label="File"
                      placeholder="Select a file..."
                      customClass=""
                      accept=".pdf"
                      notes="File size limit: 25MB | Accepted format: .PDF"
                    />
                  </div>
                </div>

                <div className="mt-3">
                  <button
                    type="submit"
                    className="btn btn-sm btn-primary"
                    disabled={submitting}
                  >
                    Upload
                  </button>
                </div>
              </form>
              <br />

              <div className="col-12">
                <TableComponent>
                  <thead>
                    <tr>
                      <th>Language</th>
                      <th className="text-right">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="bg-secondary">
                      <td colSpan={2}>
                        <strong>EPKs</strong>
                      </td>
                    </tr>
                    {loadFiles(epks)}
                    <tr className="bg-secondary">
                      <td colSpan={2}>
                        <strong>One-Pagers</strong>
                      </td>
                    </tr>
                    {loadFiles(onePagers)}
                    <tr className="bg-secondary">
                      <td colSpan={2}>
                        <strong>Press Notes</strong>
                      </td>
                    </tr>
                    {loadFiles(pressNotes)}
                  </tbody>
                </TableComponent>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const wrappedComponent = reduxForm({
  form: "createFilmFileForm",
  validate,
})(FilmFiles);

export default connect(null, { createFilmFile, deleteFilmFile })(
  wrappedComponent
);
