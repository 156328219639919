import React from "react";
import { Upload } from "react-feather";

import AdminPageTitle from "../../AdminPageTitle";

const FilmEditSkeleton = () => {
  return (
    <>
      <div className="placeholder-glow">
        <AdminPageTitle>
          <span className="placeholder col-8"></span>
        </AdminPageTitle>
        <section className="slice slice-sm ">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-9">
                <div>
                  {/* <!-- Content --> */}
                  <div className="row">
                    <div className="col-lg-12">
                      <div>
                        {/* <!-- Title --> */}
                        <h5 className="mb-3">Basic Information</h5>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                Film Title (English)
                              </label>
                              <span className="form-control placeholder col-12"></span>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                Alternate Title (Spanish)
                              </label>
                              <span className="form-control placeholder col-12"></span>
                            </div>
                          </div>
                        </div>
                        <div className="row align-items-center">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-control-label">Slug</label>
                              <span className="form-control placeholder col-12"></span>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-control-label">Year</label>
                              <span className="form-control placeholder col-12"></span>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="form-control-label">
                                One-Sheet
                              </label>
                              <br />
                              <div className="row">
                                <div className="col-2">
                                  <span
                                    className="placeholder card-img-top card-img-bottom"
                                    style={{ height: "150px" }}
                                  ></span>
                                </div>
                                <div className="col-10">
                                  <span className="placeholder col-12 form-control"></span>
                                  <label id="filmOneSheetLabel">
                                    <Upload size={18} />
                                    <span>Choose a file…</span>
                                  </label>
                                  <small className="form-text text-muted mt-2">
                                    <span className="placeholder col-4"></span>
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div>
                        <h5 className="mb-3">Film Details</h5>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                Logline (English)
                              </label>
                              <span className="form-control placeholder col-12"></span>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                Logline (Spanish)
                              </label>
                              <span className="form-control placeholder col-12"></span>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                Synopsis (English)
                              </label>
                              <span className="form-control placeholder col-12"></span>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                Synopsis (Spanish)
                              </label>
                              <span className="form-control placeholder col-12"></span>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                Production Company
                              </label>
                              <span className="form-control placeholder col-12"></span>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label className="form-control-label">
                                Distributor
                              </label>
                              <span className="form-control placeholder col-12"></span>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label className="form-control-label">
                                Status
                              </label>
                              <span className="form-control placeholder col-12"></span>
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="form-group">
                              <label className="form-control-label">
                                Release Date
                              </label>
                              <span className="form-control placeholder col-12"></span>
                            </div>
                          </div>
                          <div className="col-sm-2">
                            <div className="form-group">
                              <label className="form-control-label">
                                Rating
                              </label>
                              <span className="form-control placeholder col-12"></span>
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="form-group">
                              <label className="form-control-label">
                                Country
                              </label>
                              <span className="form-control placeholder col-12"></span>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label className="form-control-label">
                                Director
                              </label>
                              <span className="form-control placeholder col-12"></span>
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label className="form-control-label">
                                Writer
                              </label>
                              <span className="form-control placeholder col-12"></span>
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label className="form-control-label">
                                Producer
                              </label>
                              <span className="form-control placeholder col-12"></span>
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <div className="form-group">
                              <label className="form-control-label">Cast</label>
                              <span className="form-control placeholder col-12"></span>
                            </div>
                          </div>
                        </div>

                        <div className="mt-3">
                          <button
                            type="button"
                            className="btn btn-sm btn-primary placeholder"
                            disabled
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default FilmEditSkeleton;
